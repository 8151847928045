"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Petrol = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.33 18.25a5.18 5.18 0 01-.58-6.67l1.09.76a3.84 3.84 0 00.43 5z" }),
            React.createElement("path", { d: "M12 22.76A8.18 8.18 0 015.3 9.85l5.65-8h2.1l5.65 8A8.18 8.18 0 0112 22.76zm-.36-19.59l-5.25 7.45a6.86 6.86 0 1011.22 0l-5.25-7.45z" }));
    }
    return null;
});
