"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CheckboxCheckmark = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.533 17.573l-4.902-4.055.848-1.025 4.436 3.67 8.557-12.395 1.094.756-8.924 12.898a.757.757 0 01-1.109.151zm.039-.913l-.002.003z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.743 2.454L4.952 7.913 3.01 6.32l-.844 1.025 2.5 2.05a.665.665 0 00.42.15l.093-.005a.676.676 0 00.453-.28l4.2-6.048z" }));
    }
    return null;
});
