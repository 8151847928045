"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.SeatAir = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M16.44 22.51H3L1.43 17.9a1.71 1.71 0 01.08-1.31 1.61 1.61 0 011-.81 18.76 18.76 0 014.17 0c1.87.08 4.13.17 5.71.06A2.86 2.86 0 0113 13.9a12.27 12.27 0 002.68-5.11 18.52 18.52 0 00.87-5.61 1.64 1.64 0 011.66-1.69h4.37v.63a34.93 34.93 0 01-1 10 25.18 25.18 0 01-5 10.18zM3.91 21.18H15.8a23.88 23.88 0 004.54-9.39 34.61 34.61 0 001-9h-3.1a.32.32 0 00-.24.09.37.37 0 00-.09.25 20 20 0 01-.92 6A13.6 13.6 0 0114 14.76c-.24.29-.35.53-.35 1.65V17l-.59.07a48.24 48.24 0 01-6.49 0 26.84 26.84 0 00-3.73 0 .25.25 0 00-.16.14.34.34 0 000 .28z" }),
            React.createElement("path", { d: "M9.47 13.7l-.73-1.11a3 3 0 001.53-2.3c0-.64-.32-1-.9-1.65A4.44 4.44 0 017.9 5.41 3.45 3.45 0 0110 2.47l.57 1.2a2.15 2.15 0 00-1.35 1.79 3.19 3.19 0 001.13 2.28 3.62 3.62 0 011.25 2.55 4.17 4.17 0 01-2.13 3.41zM4.36 13.7l-.73-1.11a2.93 2.93 0 001.53-2.3c0-.64-.32-1-.9-1.65a4.44 4.44 0 01-1.47-3.23 3.45 3.45 0 012.06-2.94l.57 1.2a2.15 2.15 0 00-1.31 1.79 3.19 3.19 0 001.13 2.28 3.62 3.62 0 011.25 2.55 4.17 4.17 0 01-2.13 3.41z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.37 11.59H1.26L.43 9.08a1.2 1.2 0 01.06-.91 1.1 1.1 0 01.68-.57 9.74 9.74 0 012.38 0c.77 0 1.62.07 2.18 0a3 3 0 01.53-.84 5.53 5.53 0 001.25-2.41 8.79 8.79 0 00.42-2.68 1.13 1.13 0 01.33-.84 1.16 1.16 0 01.83-.34h2.48v.63a17.9 17.9 0 01-.5 5 12.87 12.87 0 01-2.54 5.18zm-6.15-1.33h5.5a11.43 11.43 0 002.09-4.4 16.88 16.88 0 00.49-4h-1a10.63 10.63 0 01-.47 2.9 6.81 6.81 0 01-1.58 2.88 2.47 2.47 0 00-.43.82l-.13.38-.41.06a23.42 23.42 0 01-2.78 0c-.59 0-1.35-.05-1.74 0z" }),
            React.createElement("path", { d: "M5 7l-.84-1a1.36 1.36 0 00.62-1c0-.26-.13-.42-.38-.73a2.81 2.81 0 01-.78-1.94A2.21 2.21 0 014.79.41l.63 1.17a.87.87 0 00-.42.74 1.52 1.52 0 00.48 1.05 2.37 2.37 0 01.68 1.57A2.61 2.61 0 015 7zM1.75 7L1 6a1.36 1.36 0 00.62-1c0-.26-.12-.42-.37-.73a2.81 2.81 0 01-.83-2A2.2 2.2 0 011.58.41l.64 1.17a.87.87 0 00-.47.74 1.52 1.52 0 00.48 1.05 2.36 2.36 0 01.67 1.57A2.62 2.62 0 011.75 7z" }));
    }
    return null;
});
