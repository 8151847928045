"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Powertrain = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.065 6.745a1.463 1.463 0 001.53-1.381V3.179A1.46 1.46 0 0022.069 1.8h-5.262a1.464 1.464 0 00-1.53 1.382v2.18a1.464 1.464 0 001.53 1.382h2.01v2.681a2.264 2.264 0 00-1.945 1.91H5.082v-4.59h1.967A1.464 1.464 0 008.58 5.363V3.179A1.46 1.46 0 007.054 1.8h-5.27a1.464 1.464 0 00-1.53 1.382v2.18a1.464 1.464 0 001.53 1.382h1.968v10.51H1.785a1.463 1.463 0 00-1.531 1.381v2.182a1.463 1.463 0 001.53 1.38H7.05a1.463 1.463 0 001.531-1.38v-2.186a1.46 1.46 0 00-1.526-1.377H5.082v-4.59h11.79a2.264 2.264 0 001.944 1.91v2.68h-2.01a1.463 1.463 0 00-1.53 1.381v2.182a1.463 1.463 0 001.53 1.38h5.26a1.463 1.463 0 001.529-1.38v-2.186a1.459 1.459 0 00-1.526-1.377h-1.923v-2.704a2.362 2.362 0 001.92-2.55 2.307 2.307 0 00-1.92-2.558V6.745zM1.584 5.415l-.005-2.284H7.25l.005 2.284zm5.666 13.17l.005 2.283H1.584l-.005-2.283zm15.015 0l.005 2.283h-5.664l-.004-2.283zM20.736 12c0 .91-.388 1.298-1.3 1.298s-1.3-.388-1.3-1.298.388-1.298 1.3-1.298c.96 0 1.3.34 1.3 1.298zm1.534-6.585h-5.664l-.004-2.284h5.662" }));
    }
    return null;
});
