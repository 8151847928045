"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CloseCircle = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 22.665A10.315 10.315 0 011.335 12 10.315 10.315 0 0112 1.335 10.315 10.315 0 0122.665 12 10.315 10.315 0 0112 22.665zm0-20A9.029 9.029 0 002.665 12 9.029 9.029 0 0012 21.335 9.029 9.029 0 0021.335 12 9.029 9.029 0 0012 2.665z" }),
            React.createElement("path", { d: "M15.47 9.471l-.94-.941L12 11.059 9.47 8.53l-.94.941L11.059 12 8.53 14.53l.94.941 2.53-2.53 2.53 2.53.94-.941L12.941 12z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 48 48", width: "48", height: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M24 46C10.963 46 1.5 36.746 1.5 24 1.5 11.252 10.963 2 24 2s22.5 9.252 22.5 22c0 12.747-9.463 22-22.5 22zm0-42C12.121 4 3.5 12.412 3.5 24S12.121 44 24 44s20.5-8.412 20.5-20c0-11.589-8.62-20-20.5-20z" }),
            React.createElement("path", { d: "M31.149 18.416l-1.397-1.431L24 22.603l-5.752-5.618-1.396 1.431L22.569 24l-5.717 5.584 1.396 1.432L24 25.398l5.752 5.618 1.397-1.432L25.431 24z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 11.665A5.48 5.48 0 01.335 6 5.48 5.48 0 016 .335 5.48 5.48 0 0111.665 6 5.48 5.48 0 016 11.665zm0-10A4.147 4.147 0 001.665 6 4.147 4.147 0 006 10.335 4.147 4.147 0 0010.335 6 4.147 4.147 0 006 1.665z" }),
            React.createElement("path", { d: "M8.235 4.706l-.94-.942L6 5.059 4.705 3.764l-.94.942L5.059 6 3.765 7.294l.94.942L6 6.941l1.295 1.295.94-.942L6.941 6z" }));
    }
    return null;
});
