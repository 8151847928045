"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ChevronDown = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M1.984 6.19l9.465 9.893c.28.299.418.397.551.397.145 0 .31-.114.572-.392l9.443-9.898.962.918-9.439 9.893a2.108 2.108 0 01-1.538.81 2.083 2.083 0 01-1.517-.813l-9.46-9.889z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.698 12.641l18.957 19.817c1.085 1.16 1.482 1.34 2.732.006l18.912-19.822 1.447 1.381L26.84 33.839a3.943 3.943 0 01-2.842 1.52 3.883 3.883 0 01-2.797-1.526L2.252 14.023z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M1.216 2.805l4.746 4.962.043.046.04-.041 4.738-4.966.962.918L7.01 8.686A1.39 1.39 0 016 9.195a1.365 1.365 0 01-1.004-.514L.255 3.725z" }));
    }
    return null;
});
