"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Bin = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22 5.33h-4.48l-1-4h-9l-1 4H2v1.34h2v14.77A1.56 1.56 0 005.56 23h12.88A1.56 1.56 0 0020 21.44V6.67h2zM8.52 2.67h7l.67 2.66H7.85zm10.15 18.77a.23.23 0 01-.23.23H5.56a.23.23 0 01-.23-.23V6.67h13.34z" }),
            React.createElement("path", { d: "M8.33 10h1.33v8H8.33zM11.33 10h1.33v8h-1.33zM14.33 10h1.33v8h-1.33z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 48 48", width: "48", height: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.05 19.05h2v17.2h-2zM23.5 19.05h2v17.2h-2zM29.95 19.05h2v17.2h-2z" }),
            React.createElement("path", { d: "M44 7.15A2.15 2.15 0 0041.85 5h-8.29l-1.27-2.91A2.06 2.06 0 0030.4.85H18.6a2.06 2.06 0 00-1.89 1.24L15.44 5H7.15A2.15 2.15 0 005 7.15V13h2.3v30.82A3.27 3.27 0 0010.65 47h27.7a3.27 3.27 0 003.35-3.18V13H44zm-25.4-4.3h11.86L31.38 5H17.64zm21.1 41A1.28 1.28 0 0138.35 45h-27.7a1.28 1.28 0 01-1.35-1.18V13h30.4zM42 11H7V7.15A.15.15 0 017.15 7h34.7a.15.15 0 01.15.15z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.5 2.17H.5V3.5H2v7.63a.83.83 0 00.78.87h6.44a.83.83 0 00.78-.87V3.5h1.5zm-2.83 8.5H3.33V3.5h5.34zM4 0h4v1.33H4z" }));
    }
    return null;
});
