import React from "react";
import {
  ThemeProvider,
  styled,
} from "../../../components-core/packages/components-core/dist/theme-provider/index";
import {
  Container,
  ContainerWrap,
  ContainerPadding,
} from "../../../components-core/packages/components-core/dist/container/index";
import { IntegratorRoot } from "../../../components-core/packages/components-core/dist/integrator-root/index";
import { LazyImage } from "../../../components-core/packages/components-core/dist/image/index";
import {
  Text,
  TextAppearance,
  TextAlignment,
  TextColor,
} from "../../../components-core/packages/components-core/dist/text";
import { Breakpoints } from "../../../components-core/packages/components-core/dist/helpers";
import {
  Layout,
  LayoutConfigurations,
} from "../../../components-core/packages/components-core/dist/layout/index";

const Divider = styled.hr`
  width: auto;
  height: 2px;

  left: 0;
  margin: 0;
  background-color: ${(props) => props.color};
  margin: auto;
`;

const TopContainer = styled.div`
  width: 100vw;
`;

const PhotoContainer = styled.div`
  max-width: 80%;

  @media screen and (max-width: 560px) {
    max-width: 40%;
  }
`;

const layoutApperance4Column = [
  { name: "x", columns: 24 },
  { name: ".", columns: 3 },
  { name: "a", columns: 3 },
  { name: ".", columns: 2 },
  { name: "b", columns: 3 },
  { name: ".", columns: 2 },
  { name: "c", columns: 3 },
  { name: ".", columns: 2 },
  { name: "d", columns: 3 },
  { name: ".", columns: 3 },
];

const layoutApperance2Column = [
  { name: "x", columns: 24 },
  { name: ".", columns: 3 },
  { name: "a", columns: 8 },
  { name: ".", columns: 2 },
  { name: "b", columns: 8 },
  { name: ".", columns: 3 },
  { name: ".", columns: 3 },
  { name: "c", columns: 8 },
  { name: ".", columns: 2 },
  { name: "d", columns: 8 },
  { name: ".", columns: 3 },
];

const layoutApperance1Column = [
  { name: "x", columns: 24 },
  { name: ".", columns: 2 },
  { name: "a", columns: 20 },
  { name: ".", columns: 2 },
  { name: ".", columns: 2 },
  { name: "b", columns: 20 },
  { name: ".", columns: 2 },
  { name: ".", columns: 2 },
  { name: "c", columns: 20 },
  { name: ".", columns: 2 },
  { name: ".", columns: 2 },
  { name: "d", columns: 20 },
  { name: ".", columns: 2 },
];

const segmentedLayoutAppreances = {
  [Breakpoints.b960]: layoutApperance4Column,
  [Breakpoints.b560]: layoutApperance2Column,
  [Breakpoints.default]: layoutApperance1Column,
};

class Footer extends React.Component {
  renderSection() {
    return sections.map((section, index) => {
      const renderedTitle = (
        <Container
          padding={{
            top: ContainerPadding.dynamic0250,
            bottom: ContainerPadding.dynamic0100,
          }}
        >
          <Text appearance={TextAppearance.copy0150} bold>
            {section.title}
          </Text>
        </Container>
      );

      const renderedSubTitles = section.subTitles.map((data) => (
        <Container
          padding={{ bottom: ContainerPadding.dynamic0020 }}
          stretchContent={true}
        >
          {data.title.includes("https") ? (
            <a href={data.link} target="_blank" style={{ cursor: "pointer" }}>
              <PhotoContainer>
                <LazyImage
                  alt={"vw"}
                  src={data.title}
                  srcPlaceholder={data.title}
                />
              </PhotoContainer>
            </a>
          ) : (
            <Text appearance={TextAppearance.copy0150}>
              {section.title === "Volkswagen Ticari Araç" ||
              section.title === "Satış Sonrası Hizmetler" ? (
                <a
                  style={{ textDecoration: "none", color: "#001e50" }}
                  href={data.link}
                >
                  {data.title + " "}
                </a>
              ) : (
                <a
                  style={{ textDecoration: "none", color: "#001e50" }}
                  target="_blank"
                  href={data.link}
                >
                  {data.title + " "}
                </a>
              )}
            </Text>
          )}
        </Container>
      ));

      return (
        <Container wrap={ContainerWrap.always}>
          {renderedTitle}
          {renderedSubTitles}
        </Container>
      );
    });
  }

  renderSmallQuickLinks() {
    return smallQuickLinks.map((data) => (
      <Text appearance={TextAppearance.copy0150}>
        <a
          style={{ textDecoration: "none", color: "#001e50" }}
          target="_blank"
          href={data.link}
        >
          &nbsp; {data.title}&nbsp;|{" "}
        </a>
      </Text>
    ));
  }

  renderFooterCredits() {
    const defaultPadding = {
      [Breakpoints.default]: ContainerPadding.grid002,
      [Breakpoints.b560]: ContainerPadding.grid003,
    };

    return (
      <Container
        shrinkContent={true}
        stretchContent={true}
        padding={{
          left: defaultPadding,
          right: defaultPadding,
          top: ContainerPadding.dynamic0150,
        }}
      >
        <Layout appearance={LayoutConfigurations.half}>
          <Container>{this.renderSmallQuickLinks()}</Container>
          <Container stretchContent={true}>
            <Text
              textAlign={{ [Breakpoints.b560]: TextAlignment.right }}
              appearance={TextAppearance.copy0100}
            >
              © Volkswagen Ticari Araç
            </Text>
          </Container>
        </Layout>
      </Container>
    );
  }

  renderDivider(color) {
    const dividerPadding = {
      top: ContainerPadding.dynamic0250,
      right: ContainerPadding.grid002,
      left: ContainerPadding.grid002,
    };

    return (
      <Container stretchContent={true} padding={dividerPadding}>
        <Divider color={color} />
      </Container>
    );
  }

  renderLawText() {
    return (
      <Container
        wrap={ContainerWrap.never}
        padding={{
          left: ContainerPadding.grid002,
          right: ContainerPadding.grid002,
          top: ContainerPadding.dynamic0250,
          bottom: ContainerPadding.dynamic0250,
        }}
      >
        <Text color={TextColor.secondary} appearance={TextAppearance.copy100}>
          {footerLawText}
          <br />
        </Text>
      </Container>
    );
  }

  renderSeoText() {
    return (
      <div>
        <span style={{ fontSize: "0px" }}>
          web
          sitesinde; <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Caddy</h2>,{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2> Yeni{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Transporter</h2>,{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Caravelle</h2>,{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Yeni Crafter</h2> ve{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Amarok</h2> modellerinin{" "}
          <h3 style={{ fontSize: "0px" }}>fiyat listesi</h3>, yetkili servis ve
          satıcı ağına ulaşabilirsiniz.{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Caddy</h2>,{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Yeni Volkswagen Transporter</h2>,{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Caravelle</h2>,{" "}
          <h2 style={{ fontSize: "0px" }}>Ticari Araç</h2>{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Yeni Crafter</h2> ve{" "}
          <h2 style={{ fontSize: "0px" }}>Volkswagen Amarok</h2> son{" "}
          <h3 style={{ fontSize: "0px" }}>fiyat listesi</h3> ve özelliklerini
          keşfedebilir <h3 style={{ fontSize: "0px" }}>kampanya</h3> ve
          fırsatlara ulaşabilirsiniz.
        </span>
      </div>
    );
  }

  render() {
    return (
      <IntegratorRoot>
        <ThemeProvider>
          <TopContainer>
            <Layout appearance={segmentedLayoutAppreances}>
              {this.renderDivider("#001e50")}
              {this.renderSection()}
            </Layout>
            {this.renderFooterCredits()}
            {this.renderDivider("#c2cacf")}
            {this.renderLawText()}
            {this.renderSeoText()}
          </TopContainer>
        </ThemeProvider>
      </IntegratorRoot>
    );
  }
}

const sections = [
  {
    title: "Volkswagen Ticari Araç",
    subTitles: [
      {
        title: "Modeller",
        link: "/modeller",
      },
      {
        title: "Fiyat Listesi",
        link: "/fiyat-listesi",
      },
      {
        title: "Broşürler",
        link: "/brosur-indir",
      },
      {
        title: "Dijital Kullanım Kılavuzu",
        link: "https://binekarac.vw.com.tr/dijitalkilavuz",
      },
      {
        title: "Uygun Beyanı",
        link: "https://binekarac.vw.com.tr/tr/uygunluk-beyani.html",
      },
      {
        title: "İletişim",
        link: "/iletisim",
      },
      {
        title: "Kampanyalar ve Hizmetler",
        link: "/kampanyalar-ve-hizmetler",
      },
      {
        title: "Yetkili Satıcı ve Servisler",
        link: "/yetkili-satici-ve-servis-noktalari",
      },
    ],
  },
  {
    title: "Satış Sonrası Hizmetler",
    subTitles: [
      {
        title: "Bakım Onarım",
        link: "/bakim-onarim",
      },
      {
        title: "Mobilite Hizmetleri",
        link: "/mobilite-hizmetleri",
      },
      {
        title: "Garanti & Kasko Hizmetleri",
        link: "/garanti-ve-kasko-hizmetler",
      },
      {
        title: "Gönüllü Geri Çağırma",
        link: "/gonullu-geri-cagirma",
      },
      {
        title: "ServicePlus",
        link: "/service-plus",
      },
      {
        title: "Kalite Güvencesi",
        link: "/kalite-guvencesi",
      },
      {
        title: "Yol Yardım",
        link: "/yol-yardim",
      },
      {
        title: "Aksesuar ve Yedek Parça",
        link: "/aksesuar-ve-yedek-parca",
      },
    ],
  },
  {
    title: "Bizi takip edin",
    subTitles: [
      {
        title: "Facebook",
        link: "https://www.facebook.com/vwticariarac/",
      },
      {
        title: "Instagram",
        link: "https://www.instagram.com/volkswagenticariarac/",
      },
      {
        title: "Volkswagen Journey Türkiye",
        link: "https://www.instagram.com/vwjourney_turkiye/",
      },
      {
        title: "Youtube",
        link: "https://www.youtube.com/vwticariarac",
      },
    ],
  },
  {
    title: "Mobil uygulama",
    subTitles: [
      {
        title: "https://fs-ticariarac.vw.com.tr/vwticari_files/appstore.png",
        link:
          "https://apps.apple.com/tr/app/volkswagen-ticari-ara%C3%A7/id1134858183?l=tr",
      },
      {
        title: "https://fs-ticariarac.vw.com.tr/vwticari_files/googleplay.png",
        link:
          "https://play.google.com/store/apps/details?id=tr.com.dteknoloji.vwticari&hl=tr",
      },
      {
        title: "https://fs-ticariarac.vw.com.tr/vwticari_files/huaweilogo.png",
        link: "https://appgallery.huawei.com/#/app/C105762497",
      },
    ],
  },
];

const footerSeoText =
  "Volkswagen Ticari Araçlar web sitesinde; Volkswagen Caddy, Volkswagen Yeni Volkswagen Transporter, Volkswagen Caravelle, Volkswagen Yeni Crafter ve Volkswagen Amarok modellerinin fiyat listesi, yetkili servis ve satıcı ağına ulaşabilirsiniz. Volkswagen Caddy, Volkswagen Yeni Volkswagen Transporter, Volkswagen Caravelle, Volkswagen Yeni Crafter ve Volkswagen Amarok son fiyat listesi ve özelliklerini keşfedebilir kampanya ve fırsatlara ulaşabilirsiniz.";
const footerLawText =
  "*Sitede gösterilen tüm görseller örnektir. Satışta olan modellerden farklılıklar gösterebilir. Daha fazla bilgi için lütfen yetkili satış ve servis noktalarımız ile iletişime geçiniz.";

const smallQuickLinks = [
  {
    title: "vdf",
    link: "https://www.vdf.com.tr/",
  },
  {
    title: "DOD",
    link: "https://www.dod.com.tr",
  },
  {
    title: "Binek Araç",
    link: "https://binekarac.vw.com.tr/tr.html",
  },
  {
    title: "VW International",
    link: "https://www.volkswagen-nutzfahrzeuge.de/de.html",
  },
  {
    title: "Lastik Etiketleri",
    link: "https://ticariarac.vw.com.tr/lastik-etiketleri",
  },
  {
    title: "Çerez Politikası",
    link: "https://ticariarac.vw.com.tr/cerez-politikasi",
  },
  {
    title: "Kullanım Şartları ve Gizlilik Politikası",
    link:
      "https://ticariarac.vw.com.tr/kullanim-sartlari-ve-gizlilik-politikasi",
  },
  {
    title: "Canlı İletişim",
    link: "https://canlidestek.vw.com.tr/dim_ticari/chat.html",
  },
  {
    title: "KVKK",
    link: "https://ticariarac.vw.com.tr/ticari-iletisim-izni-aydinlatma-metni",
  },
];

export default Footer;
