"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Gte = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.85 8.51a5.58 5.58 0 012.44.67l.15.08L8 8l-.11-.1a6.09 6.09 0 00-3-.87C2 7 .45 8.79.45 12S2 17 4.73 17A6 6 0 008 16v-4.15H4.62V13l1.82.27v1.87a3.34 3.34 0 01-1.71.34c-1.8 0-2.67-1.14-2.67-3.49S3 8.51 4.85 8.51zM9.04 8.69h2.69v8.08h1.58V8.69h2.68V7.23H9.04zM18.9 15.31v-2.67h4.18v-1.45H18.9v-2.5h4.61V7.23h-6.19v9.54h6.23v-1.46z" }));
    }
    return null;
});
