"use strict";
/* eslint-disable @typescript-eslint/camelcase */
Object.defineProperty(exports, "__esModule", { value: true });
const helpers_1 = require("../helpers");
const definitions_1 = require("./definitions");
const fontSizes = {
    100: {
        [helpers_1.Breakpoints.default]: 12,
        [helpers_1.Breakpoints.desktop]: 14
    },
    150: {
        [helpers_1.Breakpoints.default]: 14,
        [helpers_1.Breakpoints.desktop]: 16
    },
    200: {
        [helpers_1.Breakpoints.default]: 16,
        [helpers_1.Breakpoints.desktop]: 20
    },
    250: {
        [helpers_1.Breakpoints.default]: 20,
        [helpers_1.Breakpoints.desktop]: 24
    },
    300: {
        [helpers_1.Breakpoints.default]: 24,
        [helpers_1.Breakpoints.desktop]: 32
    },
    350: {
        [helpers_1.Breakpoints.default]: 28,
        [helpers_1.Breakpoints.tablet]: 32,
        [helpers_1.Breakpoints.desktop]: 48
    },
    400: {
        [helpers_1.Breakpoints.default]: 32,
        [helpers_1.Breakpoints.tablet]: 48,
        [helpers_1.Breakpoints.desktop]: 60
    },
    450: {
        [helpers_1.Breakpoints.default]: 44,
        [helpers_1.Breakpoints.tablet]: 60,
        [helpers_1.Breakpoints.desktop]: 76
    },
    500: {
        [helpers_1.Breakpoints.default]: 56,
        [helpers_1.Breakpoints.tablet]: 72,
        [helpers_1.Breakpoints.desktop]: 96
    },
    550: {
        [helpers_1.Breakpoints.default]: 84,
        [helpers_1.Breakpoints.tablet]: 96,
        [helpers_1.Breakpoints.desktop]: 120
    },
    600: {
        [helpers_1.Breakpoints.default]: 100,
        [helpers_1.Breakpoints.tablet]: 116,
        [helpers_1.Breakpoints.desktop]: 148
    },
    size0100: {
        [helpers_1.Breakpoints.default]: 12,
        [helpers_1.Breakpoints.b1600]: 14
    },
    size0150: {
        [helpers_1.Breakpoints.default]: 14,
        [helpers_1.Breakpoints.b1600]: 16
    },
    size0200: {
        [helpers_1.Breakpoints.default]: 16,
        [helpers_1.Breakpoints.b1600]: 20
    },
    size0250: {
        [helpers_1.Breakpoints.default]: 20,
        [helpers_1.Breakpoints.b1600]: 24
    },
    size0300: {
        [helpers_1.Breakpoints.default]: 24,
        [helpers_1.Breakpoints.b1600]: 32
    },
    size0350: {
        [helpers_1.Breakpoints.default]: 28,
        [helpers_1.Breakpoints.b560]: 32,
        [helpers_1.Breakpoints.b1600]: 48
    },
    size0400: {
        [helpers_1.Breakpoints.default]: 32,
        [helpers_1.Breakpoints.b560]: 48,
        [helpers_1.Breakpoints.b1600]: 60
    },
    size0450: {
        [helpers_1.Breakpoints.default]: 44,
        [helpers_1.Breakpoints.b560]: 60,
        [helpers_1.Breakpoints.b1600]: 76
    },
    size0500: {
        [helpers_1.Breakpoints.default]: 56,
        [helpers_1.Breakpoints.b560]: 72,
        [helpers_1.Breakpoints.b1600]: 96
    },
    size0550: {
        [helpers_1.Breakpoints.default]: 84,
        [helpers_1.Breakpoints.b560]: 96,
        [helpers_1.Breakpoints.b1600]: 120
    },
    size0600: {
        [helpers_1.Breakpoints.default]: 100,
        [helpers_1.Breakpoints.b560]: 116,
        [helpers_1.Breakpoints.b1600]: 148
    }
};
var FontStyles;
(function (FontStyles) {
    FontStyles[FontStyles["Copy"] = 0] = "Copy";
    FontStyles[FontStyles["Headline"] = 1] = "Headline";
    FontStyles[FontStyles["Label"] = 2] = "Label";
})(FontStyles || (FontStyles = {}));
const generateBreakpoints = (fontSize, fn) => Object.values(helpers_1.Breakpoints).reduce((acc, breakpoint) => {
    if (fontSize[breakpoint]) {
        return [
            ...acc,
            {
                breakpoint: breakpoint === helpers_1.Breakpoints.default
                    ? breakpoint
                    : `${breakpoint}px`,
                value: fn(fontSize[breakpoint])
            }
        ];
    }
    return acc;
}, []);
const generateFontType = (size, style) => {
    const lineHeightFactor = style === FontStyles.Copy ? 1.5 : 1.1;
    const letterSpacingFactor = -0.35;
    const baseFontSize = 16;
    const lineHeightCalculation = (fontSize) => `${Math.ceil((fontSize * lineHeightFactor) / 4) * 4}px`;
    const letterSpacingCalculation = (fontSize) => `${((fontSize / baseFontSize - 1) * letterSpacingFactor).toFixed(2)}px`;
    // @todo: this is just an educated guess, please find the right formula
    const reducedFontSizeCalculation = (fontSize) => `${Math.max(Math.ceil(fontSize / 2 / 4) * 4, 12)}px`;
    /**
     * in css' var(...) anything before the first comma is considered the
     * variable name, everything else is used as a fallback value, regardless
     * of further commas and without quotation marks.
     */
    const fontFamily = style === FontStyles.Headline
        ? 'var(--font-family-head, vw-head, Arimo);'
        : 'var(--font-family-text, vw-text, Arimo);';
    return {
        fontFamily,
        fontSize: generateBreakpoints(fontSizes[size], e => `${e}px`),
        reducedFontSize: generateBreakpoints(fontSizes[size], reducedFontSizeCalculation),
        lineHeight: generateBreakpoints(fontSizes[size], lineHeightCalculation),
        static: {
            fontSize: `${fontSizes[size][helpers_1.Breakpoints.default]}px`,
            lineHeight: lineHeightCalculation(fontSizes[size][helpers_1.Breakpoints.default]),
            letterSpacing: letterSpacingCalculation(fontSizes[size][helpers_1.Breakpoints.default]),
            reducedFontSize: reducedFontSizeCalculation(fontSizes[size][helpers_1.Breakpoints.default])
        },
        letterSpacing: generateBreakpoints(fontSizes[size], letterSpacingCalculation)
    };
};
/**
 * This map contains values to be used with sizes and spacings. The 'spacing'
 * key is deprecated. Use 'size' instead. This map allows the same values to be
 * available for both keys.
 */
const sizes = {
    static0: '0',
    static100: '4px',
    static150: '8px',
    static200: '12px',
    static250: '16px',
    static300: '20px',
    static350: '24px',
    static370: '28px',
    static400: '32px',
    static450: '40px',
    static500: '44px',
    static510: '48px',
    static515: '52px',
    static520: '56px',
    static530: '64px',
    static535: '68px',
    static550: '72px',
    static565: '80px',
    static575: '84px',
    static600: '92px',
    static625: '96px',
    static650: '100px',
    static750: '120px',
    static800: '132px',
    static900: '156px',
    static1000: '172px',
    static1100: '184px',
    static1200: '196px',
    grid001: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(1) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(1, helpers_1.Breakpoints.b2560)
        }
    ],
    grid002: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(2) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(2, helpers_1.Breakpoints.b2560)
        }
    ],
    grid003: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(3) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(3, helpers_1.Breakpoints.b2560)
        }
    ],
    grid004: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(4) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(4, helpers_1.Breakpoints.b2560)
        }
    ],
    grid005: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(5) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(5, helpers_1.Breakpoints.b2560)
        }
    ],
    grid006: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(6) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(6, helpers_1.Breakpoints.b2560)
        }
    ],
    grid007: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(7) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(7, helpers_1.Breakpoints.b2560)
        }
    ],
    grid008: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(8) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(8, helpers_1.Breakpoints.b2560)
        }
    ],
    grid009: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(9) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(9, helpers_1.Breakpoints.b2560)
        }
    ],
    grid010: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(10) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(10, helpers_1.Breakpoints.b2560)
        }
    ],
    grid011: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(11) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(11, helpers_1.Breakpoints.b2560)
        }
    ],
    grid012: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(12) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(12, helpers_1.Breakpoints.b2560)
        }
    ],
    grid013: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(13) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(13, helpers_1.Breakpoints.b2560)
        }
    ],
    grid014: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(14) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(14, helpers_1.Breakpoints.b2560)
        }
    ],
    grid015: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(15) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(15, helpers_1.Breakpoints.b2560)
        }
    ],
    grid016: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(16) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(16, helpers_1.Breakpoints.b2560)
        }
    ],
    grid017: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(17) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(17, helpers_1.Breakpoints.b2560)
        }
    ],
    grid018: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(18) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(18, helpers_1.Breakpoints.b2560)
        }
    ],
    grid019: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(19) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(19, helpers_1.Breakpoints.b2560)
        }
    ],
    grid020: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(20) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(20, helpers_1.Breakpoints.b2560)
        }
    ],
    grid021: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(21) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(21, helpers_1.Breakpoints.b2560)
        }
    ],
    grid022: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(22) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(22, helpers_1.Breakpoints.b2560)
        }
    ],
    grid023: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(23) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(23, helpers_1.Breakpoints.b2560)
        }
    ],
    grid024: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: helpers_1.getGridColumn(24) },
        {
            breakpoint: `${helpers_1.Breakpoints.b2560}px`,
            value: helpers_1.getColumnsWidthForBreakpoint(24, helpers_1.Breakpoints.b2560)
        }
    ],
    dynamic0020: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '4px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '4px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '8px' }
    ],
    dynamic0040: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '12px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '20px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '32px' },
        { breakpoint: `${helpers_1.Breakpoints.b2560}px`, value: '48px' }
    ],
    dynamic0050: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '12px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '20px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '24px' }
    ],
    dynamic0100: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '20px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '24px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '28px' }
    ],
    dynamic0120: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '24px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '20px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '20px' }
    ],
    dynamic0130: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '24px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '40px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '52px' }
    ],
    dynamic0140: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '28px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '32px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '48px' }
    ],
    dynamic0150: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '28px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '52px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '64px' }
    ],
    dynamic0200: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '32px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '56px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '72px' }
    ],
    dynamic0250: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '44px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '68px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '84px' }
    ],
    dynamic0270: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '56px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '80px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '100px' }
    ],
    dynamic0300: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '72px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '96px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '120px' }
    ],
    dynamic0350: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '100px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '132px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '196px' }
    ],
    dynamic0450: [
        { breakpoint: `${helpers_1.Breakpoints.default}`, value: '156px' },
        { breakpoint: `${helpers_1.Breakpoints.b560}px`, value: '172px' },
        { breakpoint: `${helpers_1.Breakpoints.b1600}px`, value: '184px' }
    ]
};
/**
 * Set of design tokens to be used in the UI themes
 *
 * Only the tokens defined here can be used for component styling
 */
exports.designTokens = {
    animation: {
        duration: {
            duration300: '300ms',
            duration400: '400ms',
            duration500: '500ms',
            duration600: '600ms',
            duration2000: '2000ms'
        },
        timingFunction: {
            timing100: 'ease',
            timing200: 'linear',
            timing300: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
            timing400: 'ease-in'
        }
    },
    color: {
        'white-000': '#ffffff',
        'black-000': '#000000',
        'grey-100': '#DFE4E8',
        'grey-200': '#C2CACF',
        'grey-300': '#96A3A8',
        'grey-400': '#6A767D',
        'grey-500': '#3C484D',
        'blue-100': '#4CC7F4',
        'blue-200': '#00B0F0',
        'blue-500': '#00437A',
        'blue-600': '#001E50',
        'blue-900': '#0040C5',
        'mint-100': '#00E6E6',
        'red-100': '#FF335C',
        'red-200': '#E4002C',
        'purple-100': '#5B08A4',
        'green-100': '#ADE5A1',
        'green-200': '#029640',
        'yellow-000': '#FFD100',
        transparent: 'transparent',
        'black-000-transparent-50': 'rgba(0, 0, 0, 0.5)',
        'grey-100-transparent-40': 'rgba(223, 228, 232, 0.4)',
        'grey-200-transparent-20': 'rgba(194, 202, 207, 0.2)',
        'white-000-transparent-20': 'rgba(255, 255, 255, 0.2)',
        'white-000-transparent-40': 'rgba(255, 255, 255, 0.4)'
    },
    textAppearances: {
        // These textAppearances should be used in combination with the new breakpoints.
        copy100: generateFontType(definitions_1.DesignTokenFontSizes.size0100, FontStyles.Copy),
        copy150: generateFontType(definitions_1.DesignTokenFontSizes.size0150, FontStyles.Copy),
        copy200: generateFontType(definitions_1.DesignTokenFontSizes.size0200, FontStyles.Copy),
        copy250: generateFontType(definitions_1.DesignTokenFontSizes.size0250, FontStyles.Copy),
        copy300: generateFontType(definitions_1.DesignTokenFontSizes.size0300, FontStyles.Copy),
        headline200: generateFontType(definitions_1.DesignTokenFontSizes.size0200, FontStyles.Headline),
        headline250: generateFontType(definitions_1.DesignTokenFontSizes.size0250, FontStyles.Headline),
        headline300: generateFontType(definitions_1.DesignTokenFontSizes.size0300, FontStyles.Headline),
        headline350: generateFontType(definitions_1.DesignTokenFontSizes.size0350, FontStyles.Headline),
        headline400: generateFontType(definitions_1.DesignTokenFontSizes.size0400, FontStyles.Headline),
        headline450: generateFontType(definitions_1.DesignTokenFontSizes.size0450, FontStyles.Headline),
        headline500: generateFontType(definitions_1.DesignTokenFontSizes.size0500, FontStyles.Headline),
        headline550: generateFontType(definitions_1.DesignTokenFontSizes.size0550, FontStyles.Headline),
        headline600: generateFontType(definitions_1.DesignTokenFontSizes.size0600, FontStyles.Headline),
        label100: generateFontType(definitions_1.DesignTokenFontSizes.size0100, FontStyles.Label),
        label150: generateFontType(definitions_1.DesignTokenFontSizes.size0150, FontStyles.Label),
        label200: generateFontType(definitions_1.DesignTokenFontSizes.size0200, FontStyles.Label),
        label250: generateFontType(definitions_1.DesignTokenFontSizes.size0250, FontStyles.Label),
        label300: generateFontType(definitions_1.DesignTokenFontSizes.size0300, FontStyles.Label)
    },
    font: {
        weight: {
            regular: 'normal',
            light: '200',
            bold: 'bold'
        }
    },
    size: sizes
};
