"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Edit = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.57 3.472L6.454 11.768l3.577 4.76 10.97-8.33zM5.41 12.59a2.03 2.03 0 00-.485.687l-1.786 4.4h4.695a2.035 2.035 0 001.128-.36zM17.852 1.6l4.991 6.868-12.975 9.854a3.377 3.377 0 01-2.034.685h-6.67l2.53-6.231a3.372 3.372 0 011.103-1.431zm5.072 19.468v1.33H1.077v-1.33z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.456 2.29L3.394 6.068a.527.527 0 00-.177.23l-.594 1.464h1.556a.545.545 0 00.328-.111l5.054-3.838zM8.739.419l2.664 3.665L5.311 8.71a1.89 1.89 0 01-1.132.382H.648l1.336-3.294A1.874 1.874 0 012.6 5zM11.5 10.25v1.33H.5v-1.33z" }));
    }
    return null;
});
