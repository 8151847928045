"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ChevronUp = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.016 17.708L12.55 7.814c-.28-.298-.418-.397-.551-.397-.145 0-.31.114-.572.393l-9.443 9.897-.962-.918 9.439-9.893A2.108 2.108 0 0112 6.086a2.083 2.083 0 011.517.813l9.46 9.89z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M44.301 35.205L25.344 15.387c-1.085-1.159-1.482-1.338-2.732-.005L3.699 35.204l-1.447-1.381 18.906-19.816A3.943 3.943 0 0124 12.487a3.883 3.883 0 012.797 1.526l18.95 19.809z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.784 9.092L6.037 4.13l-.043-.046-.04.041-4.737 4.966-.962-.918 4.734-4.962A1.39 1.39 0 016 2.703a1.365 1.365 0 011.003.513l4.742 4.956z" }));
    }
    return null;
});
