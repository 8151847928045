"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.RoadsideAssistance = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.91 17.88a1 1 0 11-1 1 1 1 0 011-1zm-2.65-1.49a3.51 3.51 0 000 5l.2.19v.05a3.5 3.5 0 005-.05l5.2-5.2a8.43 8.43 0 002 .25 7.23 7.23 0 006.71-4 6.86 6.86 0 00.5-1.33h-5L17 9.09l.55-.64 1.34-1.53h5a6.86 6.86 0 00-.5-1.33 7.23 7.23 0 00-6.71-4v1.3a6 6 0 015.21 2.7h-3.62L16.63 7.5l-.8.94-.06.09a1 1 0 00.06 1.21l1.63 1.91.81.94h3.64a6 6 0 01-5.21 2.7 7.28 7.28 0 01-.93-.07 6.46 6.46 0 01-1.5-.37 5.75 5.75 0 01-3.18-3H8.83l-4.53 4.5zm.35 2.48a2.16 2.16 0 01.63-1.54L10 12.59a7.05 7.05 0 003.29 3.25L8.52 20.6a2.17 2.17 0 01-3.07 0l-.2-.19a2.16 2.16 0 01-.64-1.54zm6-13.12a4.57 4.57 0 002.06.57C14.1 6.32 15 5.6 15 4.41c0-.92-.54-1.41-1.68-1.76l-.63-.21c-.49-.15-.63-.38-.63-.69S12.29 1 13 1a3.71 3.71 0 011.42.39l.37-1A4.31 4.31 0 0013 0c-1.44 0-2.2.75-2.2 1.85a1.77 1.77 0 001.43 1.69l.65.21c.64.21.86.37.86.77s-.33.76-1 .76A3.65 3.65 0 0111 4.77zM4.38 7.52c0-1.76.91-3.16 2.91-3.16s2.93 1.4 2.93 3.16-.9 3.17-2.93 3.17-2.91-1.4-2.91-3.17zm4.54 0c0-1.19-.42-2.1-1.63-2.1s-1.62.91-1.62 2.1.43 2.11 1.62 2.11 1.63-.91 1.63-2.11zM0 14.61a4.58 4.58 0 002.06.58c1.4 0 2.26-.73 2.26-1.92 0-.92-.54-1.41-1.68-1.76L2 11.3c-.49-.15-.62-.38-.62-.69s.27-.71.94-.71a3.62 3.62 0 011.41.39l.38-1a4.31 4.31 0 00-1.79-.44c-1.45 0-2.2.75-2.2 1.85a1.77 1.77 0 001.43 1.7l.65.21c.63.21.86.38.86.77s-.33.76-1 .76a3.85 3.85 0 01-1.68-.5z" }));
    }
    return null;
});
