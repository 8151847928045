"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Service = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M15.426 15.698c-.318 0-.626-.027-.928-.067a6.475 6.475 0 01-1.504-.378A5.79 5.79 0 019.7 12.005a6.41 6.41 0 01-.393-1.488 7.039 7.039 0 01-.08-1.017c0-3.592 2.607-6.199 6.199-6.199 2.262 0 4.132 1.036 5.207 2.7h-3.641l-1.638 1.91-.804.938c-.022.027-.032.06-.052.087a.993.993 0 00.052 1.215l1.638 1.91.804.94h3.64c-1.074 1.662-2.944 2.697-5.206 2.697zM7.238 21.01c-.41.411-.957.637-1.537.637a2.16 2.16 0 01-1.54-.637l-.194-.196c-.41-.41-.637-.957-.637-1.538s.227-1.127.637-1.538l4.744-4.744A6.968 6.968 0 0012 16.246zm11.22-9.339H17.605l-1.86-2.17.553-.646 1.307-1.524h5.035A7.402 7.402 0 0022.137 6c-1.195-2.437-3.655-4-6.711-4-4.346 0-7.5 3.154-7.5 7.5 0 .747.1 1.455.275 2.122l-5.174 5.175a3.505 3.505 0 000 4.957l.196.195c.683.685 1.58 1.027 2.478 1.027s1.794-.342 2.479-1.027l5.205-5.206a8.286 8.286 0 002.04.257c3.057 0 5.517-1.564 6.712-4a7.43 7.43 0 00.502-1.33h-1.37z" }),
            React.createElement("path", { d: "M5.688 18.287a1.002 1.002 0 100 2.004 1.002 1.002 0 000-2.004" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "48", viewBox: "0 0 48 48", width: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M30.51 30.103c4.732 0 8.654-2.142 10.939-5.593h-7.591l-.155-.179-5.08-5.926a2.14 2.14 0 01-.363-2.177c.086-.218.204-.426.364-.612l5.234-6.106H41.465c-2.283-3.464-6.212-5.613-10.954-5.613-7.593 0-13.104 5.51-13.104 13.102 0 .841.079 1.652.209 2.437.13.783.327 1.533.578 2.251 1.27 3.643 4.028 6.389 7.678 7.647a13.65 13.65 0 002.26.568c.769.125 1.559.201 2.379.201zM13.128 42.079l11.255-11.255a13.979 13.979 0 01-5.821-4.46l-5.854 5.853c-.195.196-.451.293-.707.293s-.511-.097-.707-.293a1 1 0 010-1.414l6-6c.049-.048.11-.073.164-.109-.274-.49-.531-.992-.75-1.521L5.465 34.416a5.005 5.005 0 000 7.071l.592.592c.944.944 2.199 1.464 3.535 1.464s2.592-.52 3.535-1.464zM36 22.492h8.568a14.514 14.514 0 01-.892 2.018C41.199 29.091 36.406 32 30.51 32c-1.398 0-2.728-.18-3.986-.49L14.542 43.493c-1.367 1.367-3.16 2.05-4.95 2.05s-3.583-.683-4.95-2.05l-.591-.592a6.999 6.999 0 010-9.899l11.962-11.963a16.624 16.624 0 01-.503-4.04C15.51 8.309 21.819 2 30.51 2c5.906 0 10.705 2.917 13.179 7.51.344.64.635 1.317.885 2.019h-9.789l-4.628 5.4a.122.122 0 000 .162l4.628 5.401zM10 38.51a1.001 1.001 0 10-1-1c0 .551.448 1 1 1zm0-4a3 3 0 110 6 3 3 0 010-6z" }));
    }
    return null;
});
