"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Exterior = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.34 5.7c0 1.12-1.73 2-6.34 2s-6.34-.91-6.34-2 1.73-2 6.34-2 6.34.87 6.34 2zM21 4v16c0 1.66-2.92 3-9 3s-9-1.34-9-3V4c0-1.66 2.92-3 9-3s9 1.34 9 3zm-1.33 0c0-.22-1.43-1.67-7.67-1.67S4.33 3.78 4.33 4v16c0 .22 1.43 1.67 7.67 1.67s7.67-1.45 7.67-1.67zm-4.81 4.91l-2 2.89a2.49 2.49 0 00.27 3.2 2.5 2.5 0 003.81-3.2z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 1.33a7.64 7.64 0 013.58.55v8.24a7.64 7.64 0 01-3.58.55 7.64 7.64 0 01-3.58-.55V1.88A7.64 7.64 0 016 1.33M6 0C2.69 0 1.09.73 1.09 1.64v8.72C1.09 11.27 2.69 12 6 12s4.91-.73 4.91-1.64V1.64C10.91.73 9.31 0 6 0zm0 2.34a16.59 16.59 0 00-2.58.18v1.91A16.59 16.59 0 006 4.61a16.35 16.35 0 002.57-.18V2.52A16.35 16.35 0 006 2.34zm2.19 4.55L7.07 5.31 6 6.89a1.37 1.37 0 00.15 1.75 1.37 1.37 0 001.85 0 1.37 1.37 0 00.19-1.75z" }));
    }
    return null;
});
