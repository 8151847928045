"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.FleetServiceCommercial = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "9.65", cy: "17.96", r: ".82" }),
            React.createElement("path", { d: "M19.29 12.84l-.11-1.33c-.6.06-1.19.08-1.78.12l-.17-1a5.37 5.37 0 00-1-2.39l-1.38-1.83A1 1 0 0014 6H5.26a1 1 0 00-.79.38L3.21 7.93a5.47 5.47 0 00-1.16 2.61l-.17 1.11c-.59 0-1.17-.06-1.76-.11L0 12.2v.66c.56.05 1.11.07 1.67.11l-.2 1.3v8.4A1.33 1.33 0 002.78 24h2.44a1.33 1.33 0 001.33-1.34v-.39h6.19v.4A1.33 1.33 0 0014.06 24h2.45a1.33 1.33 0 001.32-1.33v-8.4L17.61 13c.56-.09 1.13-.11 1.68-.16zm-15.92-2.1a4.23 4.23 0 01.87-2l1.17-1.41h8.47L15.15 9a4.33 4.33 0 01.77 1.82l.14.87a105.94 105.94 0 01-12.85 0zm10.7 11.93v-1.73H5.22v1.73H2.8V18c1.18.15 2.39.29 3.65.38L6.54 17c-1.29 0-2.54-.19-3.74-.35v-2.28l.2-1.31c2.16.13 4.33.22 6.49.22s4.53-.1 6.79-.24l.22 1.34v2.24c-1.18.17-2.41.31-3.66.4l.09 1.33c1.22-.09 2.41-.23 3.57-.39v4.71z" }),
            React.createElement("path", { d: "M24 6.51V5.18l-1.58.12-.16-.93a5 5 0 00-1-2.25L20 .39a1 1 0 00-.75-.39H11a1 1 0 00-.75.36L9 1.83a5.14 5.14 0 00-1.06 2.45l-.06.39h1.35v-.18a3.9 3.9 0 01.82-1.83l1.07-1.33h7.93l1.18 1.58A3.93 3.93 0 0121 4.59l.13.8c-1.74.1-3.49.14-5.24.15l.07.08.93 1.25c1.49 0 3-.09 4.46-.18l.2 1.23V10l-1.12.13.12 1.32 1-.11v4.32h-2.39V17h2.43a1.25 1.25 0 001.25-1.26V7.81l-.2-1.21z" }));
    }
    return null;
});
