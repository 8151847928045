"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Energy = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.45 2.33a1.23 1.23 0 011.22 1.23v18.11H5.33V3.56a1.23 1.23 0 011.22-1.23zm0-1.33H6.55A2.56 2.56 0 004 3.56V23h16V3.56A2.56 2.56 0 0017.45 1zM9.5 12.22l2 .75-1.5 4.79a.25.25 0 00.45.22l4.31-5.81a.6.6 0 00-.28-.92l-2.13-.8 1.25-4.89a.26.26 0 00-.46-.21l-3.94 6a.61.61 0 00.3.87z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.44 1.33a1.23 1.23 0 011.23 1.23v8.11H2.33V2.56a1.23 1.23 0 011.23-1.23zm0-1.33H3.56A2.56 2.56 0 001 2.56V12h10V2.56A2.56 2.56 0 008.44 0zM4.57 6.32l1.15.42-.85 2.74a.14.14 0 00.25.13l2.47-3.32a.35.35 0 00-.16-.53l-1.22-.45.7-2.8a.14.14 0 00-.26-.11L4.4 5.8a.35.35 0 00.17.52z" }));
    }
    return null;
});
