"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Carwash = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.9 17.7c0 .5-.4.8-.9.8s-.8-.4-.8-.9c0-.4.4-.8.8-.8.5 0 .9.4.9.9zm9.1-5.8c-.6.1-1.3.2-1.9.3l.5 1.1c.5.8.7 1.8.7 2.7v5.5c0 .8-.6 1.4-1.4 1.4h-1.7c-.8 0-1.4-.7-1.4-1.4v-.4H7.1v.4c0 .8-.6 1.4-1.4 1.4H4.1c-.8 0-1.4-.7-1.4-1.4V16c0-1 .3-1.9.7-2.7l.6-1c-.7-.1-1.3-.2-2-.3l.2-1.3c.7.1 1.5.2 2.2.4l1.2-2.6C6 7.6 6.8 7.1 7.7 7h8.6c.9.1 1.7.6 2.2 1.4l1.1 2.6c.7-.1 1.5-.2 2.2-.4zm-16.1-.7c4.1.5 8.2.5 12.3 0L17.3 9c-.2-.4-.6-.6-1.1-.7H7.8c-.4.1-.8.3-1 .7zM20 16.6c-1.2.2-2.4.3-3.7.4l-.1-1.3c1.3-.1 2.5-.2 3.7-.4-.1-.4-.3-.9-.5-1.3v-.2l-.6-1.4c-2.3.3-4.5.4-6.8.4-2.2 0-4.4-.1-6.6-.4l-.6 1.4v.1l-.2.1c-.2.4-.4.8-.5 1.3 1.2.2 2.5.3 3.8.4V17c-1.3-.1-2.6-.2-3.8-.4v5h1.7v-1.8h12.4V21.7h1.7v-.1zM6.9 6.1c.5-.5.6-1.2.2-1.8L5.8 1.9 4.5 4.3c-.3.6-.2 1.3.3 1.8.6.6 1.5.6 2.1 0zM22.7 1v23H24V0H0v24h1.3V1.3m17.6 4.8c.5-.5.6-1.2.2-1.8l-1.3-2.4-1.3 2.4c-.3.6-.2 1.3.2 1.8.7.6 1.6.6 2.2 0z" }),
            React.createElement("path", { d: "M0 0h24v1.3H0z" }),
            React.createElement("path", { d: "M13.1 5.2c.5-.5.6-1.2.2-1.8L12 1l-1.3 2.4c-.3.6-.2 1.3.2 1.8.7.6 1.6.6 2.2 0z" }));
    }
    return null;
});
