"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Motability = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23 11a11.19 11.19 0 00-.21-1.33 33.91 33.91 0 01-6.95 1.49A35.36 35.36 0 0022 7.32a9.6 9.6 0 00-.65-1.18 33.74 33.74 0 01-6.08 3.78 33.83 33.83 0 004.52-5.73 12.39 12.39 0 00-1-.88 32.73 32.73 0 01-4.41 5.61A33.43 33.43 0 0016.6 2a11.38 11.38 0 00-1.26-.5 33 33 0 01-2.21 6.87 34.07 34.07 0 00-.3-7.37h-1.35a32.77 32.77 0 01.32 7.2A34 34 0 009 1.43c-.44.13-.86.27-1.28.44a32.76 32.76 0 012.81 6.64 34.39 34.39 0 00-5-5.39 8.64 8.64 0 00-1 .86A33.16 33.16 0 019.3 9.19a34.36 34.36 0 00-6.46-3.34A10.35 10.35 0 002.16 7a33.77 33.77 0 016.36 3.26 35.05 35.05 0 00-7.22-1 11.58 11.58 0 00-.3 1.36 35.75 35.75 0 017.06.92A35.19 35.19 0 001 13a11.52 11.52 0 00.21 1.33 34.86 34.86 0 017-1.51A35.42 35.42 0 002 16.68a9.6 9.6 0 00.65 1.18 33.74 33.74 0 016.08-3.78 33.83 33.83 0 00-4.52 5.73 12.39 12.39 0 001 .88 33.23 33.23 0 014.41-5.61A34 34 0 007.4 22a11.38 11.38 0 001.26.5 33 33 0 012.21-6.87 34 34 0 00.3 7.34h1.35a32.77 32.77 0 01-.32-7.2 34 34 0 002.8 6.8c.44-.13.86-.27 1.28-.44a32.76 32.76 0 01-2.81-6.64 34.39 34.39 0 005 5.39 9.6 9.6 0 001.08-.88 33.74 33.74 0 01-4.86-5.21 34.75 34.75 0 006.47 3.34 9 9 0 00.68-1.13 34.33 34.33 0 01-6.36-3.25 35.74 35.74 0 007.22 1 11.41 11.41 0 00.3-1.36 34.06 34.06 0 01-7.12-.94A35.74 35.74 0 0023 11zm-11 3.54A2.54 2.54 0 1114.55 12 2.55 2.55 0 0112 14.54z" }));
    }
    return null;
});
