"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const ReactDOM = __importStar(require("react-dom"));
const theme_provider_1 = require("../theme-provider");
const helpers_1 = require("./helpers");
const helpers_2 = require("../helpers");
const StyledChildWrapper = theme_provider_1.styled.div.withConfig({ displayName: "StyledChildWrapper", componentId: "sc-gu9w69" }) `
	flex-shrink: 0;
	max-width: 100%;

	${props => props.stretchContent && 'flex-grow: 1;'};
	${props => props.shrinkContent && 'flex-shrink: 1;'};
	${props => helpers_1.createSpacingBetweenElementsStyles(props.wrap, props.theme, props.gutter)};

	:empty {
		// if a child renders nothing, the wrapper should also not be rendered
		display: none;
	}
`;
StyledChildWrapper.displayName = 'StyledChildWrapper';
const StyledOverflowWrapper = theme_provider_1.styled.div.withConfig({ displayName: "StyledOverflowWrapper", componentId: "sc-100oj1v" }) `
	overflow: ${props => (props.wrap ? '' : 'hidden')};
`;
StyledOverflowWrapper.displayName = 'StyledOverflowWrapper';
// `flex-grow: 1;` for stretchContent is needed for cases where the content does not bring its own width and instead relies on the width
// of the container. Combined with the container being inside a different flex element.
// Example: <Layout><Container stretchContent><AspectRatioContainer /></Container></Layout>
// Also see "components-cms/mood-gallery"
//
// To use transition for some properties:
// animateChange={['padding', 'opacity']}
const StyledContainer = theme_provider_1.styled.div.withConfig({ displayName: "StyledContainer", componentId: "sc-yvb99i" }) `
	${props => props.gutter && helpers_1.createCSSVariablesForSizes(props.gutter, props.theme)}
	${props => props.padding && helpers_1.createCSSVariablesForSizes(props.padding, props.theme)}
	${props => helpers_1.createWrapStyles(props.wrap, props.theme, props.gutter)}
	align-items: ${props => props.verticalAlign};
	${props => props.horizontalAlign &&
    helpers_1.createHorizontalAlignmentStyles(props.horizontalAlign)}
	${props => props.padding &&
    helpers_1.createSpacingAroundElementsStyles(props.padding, props.theme, props.direction)};
	${props => props.animateChange && helpers_2.createElectricTransition(...props.animateChange)};
`;
StyledContainer.displayName = 'StyledContainer';
class ContainerInner extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            direction: undefined
        };
    }
    componentDidMount() {
        const direction = this.ref &&
            this.ref.current &&
            window &&
            // We need to use findDOMNode to ensure that we also get the
            // element from the reference in styled-components version v3.x.x.
            // After discontinuing the support for styled-components v3 we can remove
            // the `findDOMNode` and directly access the reference again.
            // eslint-disable-next-line react/no-find-dom-node
            window.getComputedStyle(ReactDOM.findDOMNode(this.ref.current)).direction;
        if (direction === 'ltr' || direction === 'rtl') {
            this.setState({ direction });
        }
    }
    render() {
        const { children, gutter, padding, wrap, stretchContent, shrinkContent, verticalAlign, horizontalAlign, animateChange, innerRef } = this.props;
        const { direction } = this.state;
        const childElements = React.Children.toArray(children).filter(React.isValidElement);
        if (!childElements.length) {
            return null;
        }
        return (React.createElement(StyledOverflowWrapper, { ref: innerRef, wrap: wrap },
            React.createElement(StyledContainer, { gutter: gutter, padding: padding, wrap: wrap, shrinkContent: shrinkContent, verticalAlign: verticalAlign, horizontalAlign: horizontalAlign, animateChange: animateChange, direction: direction }, childElements.map(child => {
                const key = child.key === null ? undefined : child.key;
                return (React.createElement(StyledChildWrapper, { key: key, gutter: gutter, wrap: wrap, stretchContent: stretchContent, shrinkContent: shrinkContent }, child));
            }))));
    }
}
exports.Container = React.forwardRef((props, ref) => React.createElement(ContainerInner, Object.assign({}, props, { innerRef: ref })));
exports.Container.displayName = 'Container';
