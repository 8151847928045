"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ExpressService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.46 11.67h4.178a7.43 7.43 0 01-.502 1.33c-1.195 2.436-3.655 4-6.71 4a8.294 8.294 0 01-2.042-.257L8.18 21.95a3.492 3.492 0 01-2.478 1.027 3.49 3.49 0 01-2.479-1.027l-.196-.195a3.507 3.507 0 010-4.957l4.808-4.808h1.861l.005.016a5.784 5.784 0 003.294 3.248c.47.183.973.308 1.504.378.302.04.61.067.928.067 2.261 0 4.131-1.035 5.207-2.698h-3.641l-.805-.939-1.637-1.91a.993.993 0 01-.052-1.215c.02-.028.03-.06.052-.087l.803-.938 1.64-1.91h3.64c-1.076-1.664-2.946-2.7-5.207-2.7-1.297 0-2.462.344-3.426.947V2.754A7.916 7.916 0 0115.426 2c3.055 0 5.515 1.563 6.71 4 .206.42.375.862.502 1.33h-5.035l-1.306 1.524-.553.646 1.86 2.17h.388zM7.237 21.01l4.764-4.764a6.963 6.963 0 01-3.29-3.253l-4.745 4.744c-.41.411-.637.957-.637 1.538s.226 1.128.637 1.538l.195.196a2.16 2.16 0 001.54.637c.58 0 1.126-.226 1.536-.637zM1 4.32V2.99h9v1.33zm2 3V5.99h7v1.33zm2 3V8.99h5v1.33zm.687 7.967a1.002 1.002 0 110 2.005 1.002 1.002 0 010-2.005z" }));
    }
    return null;
});
