"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ElectricCars = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "11.92", cy: "15.95", r: "1" }),
            React.createElement("path", { d: "M22.05 12.22h-1.46l.24.54v.09l.05.08a4.32 4.32 0 01.58 1.52c-1.5.21-3.06.39-4.67.51l.1 1.32c1.6-.12 3.16-.29 4.66-.5v5.7a.28.28 0 01-.28.28h-2a.28.28 0 01-.28-.28v-1.82H4.81v1.81a.3.3 0 01-.3.29h-2a.28.28 0 01-.28-.28v-5.67c1.54.21 3.13.38 4.77.5L7.11 15a70.09 70.09 0 01-4.78-.5 4.7 4.7 0 01.59-1.55l.08-.1v-.09l.73-1.66c2.09.25 4.19.4 6.28.46V10.2c-1.9 0-3.81-.16-5.72-.38L5.41 7.3a2.06 2.06 0 011.47-.88H10V5.09H6.82a3.36 3.36 0 00-2.58 1.58l-1.32 3Q1.61 9.5.31 9.27H.25l-.11.66-.06.66c.76.13 1.52.24 2.29.35l-.58 1.29a5.87 5.87 0 00-.87 3.07v6.19a1.61 1.61 0 001.61 1.61h2a1.63 1.63 0 001.63-1.62V21H17.7v.49a1.61 1.61 0 001.61 1.61h2a1.62 1.62 0 001.61-1.61v-6.2a5.87 5.87 0 00-.87-3.07z" }),
            React.createElement("path", { d: "M13.24 8.83a2.88 2.88 0 002.91 2.84h2a2.89 2.89 0 002.95-2.84v-3h.82V4.45h-2.14V1h-1.33v3.45h-2.56V1h-1.33v3.45h-2.14v1.33h.82zm6.53 0a1.55 1.55 0 01-1.58 1.51h-2a1.55 1.55 0 01-1.58-1.51v-3h5.2z" }));
    }
    return null;
});
