"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.TransmissionAuto = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "19", cy: "1.63", r: "1" }),
            React.createElement("circle", { cx: "19", cy: "8.63", r: "1" }),
            React.createElement("circle", { cx: "19", cy: "22.37", r: "1" }),
            React.createElement("path", { d: "M18.2 13h-5.54V.63h-1.33V13H5.8A1.81 1.81 0 004 14.78v2a1.81 1.81 0 001.8 1.8h5.53v4.74h1.33v-4.69h5.54a1.81 1.81 0 001.8-1.8v-2A1.81 1.81 0 0018.2 13zm.47 4.32H5.33v-3h13.34z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "9.65", cy: "1.11", r: ".79" }),
            React.createElement("path", { d: "M9.54 3.65a.76.76 0 00-.79.79.79.79 0 101.58 0 .76.76 0 00-.79-.79zM8.54 6.49h-2V0H5.17v6.49h-2A1.48 1.48 0 001.67 8v1.06a1.47 1.47 0 001.47 1.47h2V12H6.5v-1.47h2A1.47 1.47 0 0010 9.06V8a1.48 1.48 0 00-1.46-1.51zm.14 2.71H3V7.82h5.68z" }));
    }
    return null;
});
