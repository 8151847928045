"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.GasCarsService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.051 18.9a1 1 0 11-1-1 1 1 0 011 1zm14.579-7.616H24a7.43 7.43 0 01-.5 1.33 7.246 7.246 0 01-6.711 4 8.286 8.286 0 01-2.041-.257l-5.2 5.206a3.5 3.5 0 01-4.957 0l-.2-.2a3.5 3.5 0 010-4.957l5.174-5.175v-.024A5.989 5.989 0 0010.8 10.8a6.04 6.04 0 00.258.818 5.79 5.79 0 003.294 3.248 6.475 6.475 0 001.5.378 7.037 7.037 0 00.928.067 6.04 6.04 0 005.207-2.7h-3.634l-.8-.939-1.638-1.91a.993.993 0 01-.052-1.215c.02-.028.03-.06.052-.087l.8-.938 1.638-1.911h3.641a6.041 6.041 0 00-5.207-2.7 6.672 6.672 0 00-2.376.426V1.958a8.2 8.2 0 012.376-.344 7.244 7.244 0 016.711 4 7.4 7.4 0 01.5 1.33h-5.033l-1.307 1.524-.553.646 1.86 2.17zm-9.267 4.576a6.968 6.968 0 01-3.291-3.253l-4.744 4.744a2.175 2.175 0 000 3.076l.195.2a2.175 2.175 0 003.076 0zM5.2 8.83a3.345 3.345 0 01-2.379.985h-.46v-1.33h.457a2.02 2.02 0 001.438-.6l.235-.235a4.736 4.736 0 01-.977-2.865A4.789 4.789 0 018.3 0h4.119l.665.665v4.12A4.79 4.79 0 018.3 9.573 4.735 4.735 0 015.432 8.6zm.234-2.117L8.46 3.684l.941.942-3.029 3.029a3.452 3.452 0 005.379-2.866V1.335H8.3a3.452 3.452 0 00-2.869 5.378z" }));
    }
    return null;
});
