"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Chat = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.66 3.19v9.29H10a5.39 5.39 0 00-2.33.65 11 11 0 00-2.35 1.95v-2.6h-3V3.19zM18 1.86H2a1 1 0 00-1 1v10a1 1 0 001 1h2v4a.3.3 0 00.31.3.26.26 0 00.21-.16 16.78 16.78 0 013.79-3.7 4 4 0 011.69-.49h8a1 1 0 001-1v-10a1 1 0 00-1-1z" }),
            React.createElement("path", { d: "M21.67 8.16v8.33h-3v2.6a11.18 11.18 0 00-2.35-1.95 5.39 5.39 0 00-2.32-.65h-2.67v-1.71H10v2a1 1 0 001 1h3a4 4 0 011.69.49A16.69 16.69 0 0119.47 22a.3.3 0 00.54-.19v-4h2a1 1 0 001-1v-9a1 1 0 00-1-1H20v1.35z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.38 1.33v3.85H4.85a3.46 3.46 0 00-1.55.44 3.48 3.48 0 00-.36.22v-.66H1.32V1.33zM9.17 0H.53A.54.54 0 000 .54V6a.54.54 0 00.54.54h1.07v2.2a.16.16 0 00.16.16.18.18 0 00.13-.06 9.14 9.14 0 012-2 2.09 2.09 0 01.91-.27h4.36A.54.54 0 009.71 6V.54A.54.54 0 009.17 0z" }),
            React.createElement("path", { d: "M10.67 4.33v3.95H9.05v.66a3.58 3.58 0 00-1.91-.66H5.31v-1H4v1.77a.55.55 0 00.54.56h2.6a2.05 2.05 0 01.91.27 9.11 9.11 0 012 2.06.16.16 0 00.12.06.16.16 0 00.16-.17V9.61h1.08a.55.55 0 00.59-.56v-5.5a.55.55 0 00-.54-.55h-1.11v1.33z" }));
    }
    return null;
});
