"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Repeat = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.7 12.345l4 4.006-4 4.006-.942-.941 2.447-2.447H6a5 5 0 010-10h1V8.3H6a3.67 3.67 0 100 7.34h5.113l-2.354-2.355zM18 6.969h-4.987L15.4 4.584l-.941-.941-4.006 4.006 4.005 4.006.941-.94L12.984 8.3H18a3.67 3.67 0 110 7.34h-1v1.33h1a5 5 0 000-10z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.31 6.242L6.9 8.833l-2.59 2.592-.941-.941.943-.943H3.5a3.5 3.5 0 010-7v1.33a2.17 2.17 0 000 4.34h.9L3.369 7.183zm4.19-3.7h-.987l1.026-1.026L7.6.575 5.007 3.167 7.6 5.758l.942-.941-.947-.946H8.5a2.17 2.17 0 010 4.34v1.33a3.5 3.5 0 000-7z" }));
    }
    return null;
});
