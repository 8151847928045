"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Stop = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.22 4.93a.85.85 0 01.85.85v12.44a.85.85 0 01-.85.85H5.78a.85.85 0 01-.85-.85V5.78a.85.85 0 01.85-.85zm0-1.33H5.78A2.19 2.19 0 003.6 5.78v12.44a2.19 2.19 0 002.18 2.18h12.44a2.19 2.19 0 002.18-2.18V5.78a2.19 2.19 0 00-2.18-2.18z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.49 1.77a.74.74 0 01.74.74v7a.74.74 0 01-.74.74h-7a.74.74 0 01-.74-.74v-7a.74.74 0 01.74-.74zm0-1.33h-7A2.07 2.07 0 00.44 2.51v7a2.07 2.07 0 002.07 2.07h7a2.07 2.07 0 002.07-2.07v-7A2.07 2.07 0 009.49.44z" }));
    }
    return null;
});
