"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Engine = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.59 8.17v3h-1.3a.7.7 0 01-.7-.7V8a1 1 0 00-.95-1h-5.89V5h2.94V3.72H8.36v1.33h3.06v2H5.28a1 1 0 00-1 1v3.37h-2v-3H1v7.33h1.33v-3h2v2.65a1.49 1.49 0 00.52 1.13l3.93 3.42a1.48 1.48 0 001 .37h8.88a1 1 0 00.95-1v-2.55a.7.7 0 01.7-.7h1.3v3.1H23v-11zm-1.3 6.57a2 2 0 00-2 2V19H9.76a.21.21 0 01-.11 0l-3.93-3.51a.16.16 0 01-.06-.13v-7h12.6v2.14a2 2 0 002 2h1.3v2.21z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.67 4.58v1.54h-1v-2h-3V2.75h1.58V1.42h-4.5v1.33h1.58v1.33h-3v1.58h-1V4.08H0v4.5h1.33V7h1v1.44l2.35 2.14h5V8.44h1v1.64H12v-5.5zM8.34 6.12v3.13H5.2l-1.53-1.4V5.41h4.67z" }));
    }
    return null;
});
