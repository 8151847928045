"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.VehicleConversion = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.887 17.55a4.447 4.447 0 00-2.092-3.5l-.852-.508a3.6 3.6 0 01-.718-.565L17.019 9.72a2.723 2.723 0 00-1.928-.831h-1.949l.005-1.771H.584v7.859A35.209 35.209 0 00.4 16.568L.171 19.21a1.044 1.044 0 00.757 1.123l1.992.477a2.354 2.354 0 004.7.015l9.136-.029a2.354 2.354 0 004.7-.015h.166l1.5-.43A1.286 1.286 0 0024 19zm-8.8-7.331a1.392 1.392 0 01.984.434l2.823 2.867-5.765-.026.009-3.275zm-3.274-1.771v1.054l-.03 5.263H1.914V8.448zM1.722 16.681c.013-.163.036-.371.061-.586h10.008l-.01 3.387-4.452.018a2.344 2.344 0 00-4.128.013l-1.685-.4zm3.546 4.989a1.023 1.023 0 01-1-.834 1.052 1.052 0 01-.04-.2 1.036 1.036 0 111.04 1.034zm13.832 0a1.035 1.035 0 111.036-1.035 1.076 1.076 0 01-.03.15 1.024 1.024 0 01-1.006.885zm2.328-2.219h-.291a2.345 2.345 0 00-4.08.013l-3.945.013.013-4.654 7.429.033.56.334a2.878 2.878 0 01.944.944 44.139 44.139 0 00-2.639-.148l-.031 1.33c1.074.025 2.128.092 3.146.191.005.048.023.093.026.142l.105 1.446z" }));
    }
    return null;
});
