"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Logout = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.22 6.86l-.95.95c1.32 1.24 2.34 2.22 3.09 3 .21.22.41.41.57.59H7v1.32h13.9l-.54.55c-.75.74-1.77 1.72-3.09 3l.95.95C21.59 14 23 12.57 23 12s-1.41-2-4.78-5.14z" }),
            React.createElement("path", { d: "M18.6 19a9.56 9.56 0 01-6.6 2.67 9.67 9.67 0 010-19.34A9.56 9.56 0 0118.6 5c.31-.3.63-.61 1-.93A11 11 0 1012 23a10.9 10.9 0 007.58-3.06c-.35-.32-.67-.63-.98-.94z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.15 10.12a4.46 4.46 0 01-2.15.55 4.67 4.67 0 010-9.34 4.61 4.61 0 012.15.55l1-1A5.81 5.81 0 006 0a6 6 0 000 12 6 6 0 003.15-.91z" }),
            React.createElement("path", { d: "M5 6.66h4.85l-.55.54c-.37.35-.82.8-1.3 1.22l1 .95c2.06-1.94 3-2.93 3-3.37s-.94-1.43-3.08-3.37l-1 1c.51.46 1 .87 1.33 1.22l.59.57H5z" }));
    }
    return null;
});
