"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CertifiedRetailer = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.56 10.37l.76.56L12 9.76l1.68 1.17.76-.56-.59-1.95 1.63-1.23-.29-.9h-2l-.67-1.93h-.94l-.67 1.93h-2l-.29.9 1.63 1.23zm1.55-3.13a.69.69 0 00.62-.45L12 6l.27.79a.69.69 0 00.62.45h.84l-.67.5a.68.68 0 00-.23.73l.24.8-.69-.48a.66.66 0 00-.76 0l-.69.48.24-.8a.68.68 0 00-.23-.73l-.67-.5z" }),
            React.createElement("path", { d: "M18.4 12.36a7.84 7.84 0 10-12.8 0l-3.23 7.82.85.87 3.42-1.32 1.42 3.38h1.23L12 16.56l2.71 6.55h1.23l1.42-3.38 3.41 1.32.86-.87zM12 1.33a6.51 6.51 0 11-6.51 6.51A6.52 6.52 0 0112 1.33zM8.67 21.12l-1-2.5-.86-.36-2.57 1 2.38-5.76A7.79 7.79 0 0011 15.6zm8.56-2.86l-.85.36-1 2.5L13 15.6a7.82 7.82 0 004.39-2.11l2.38 5.76z" }));
    }
    return null;
});
