"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.StockLocatorCommercial = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.89 14.78a1 1 0 11-1 1 1 1 0 011-1zm8.67-2v1.49c-1.54.23-3.14.41-4.76.53l.1 1.33c1.59-.11 3.15-.29 4.66-.51v5.76a.29.29 0 01-.28.28h-3a.29.29 0 01-.28-.28v-1.84H6.78v1.82a.31.31 0 01-.3.3h-3a.29.29 0 01-.28-.28v-5.73q2.3.33 4.77.51l.09-1.34c-1.68-.12-3.3-.3-4.86-.52v-3.09l.25-1.58c2 .13 4 .19 6.09.23V8.51c-2 0-3.93-.09-5.88-.21l.24-1.54a5.41 5.41 0 011.15-2.55l1.51-1.87h3V1h-3a1.24 1.24 0 00-1 .46L4 3.36a6.74 6.74 0 00-1.39 3.18l-.26 1.67C1.64 8.16.93 8.11.22 8H.16v1.34l2 .16-.27 1.6v10.28A1.62 1.62 0 003.5 23h3a1.64 1.64 0 001.63-1.64v-.48h7.56v.5A1.62 1.62 0 0017.28 23h3a1.62 1.62 0 001.61-1.62v-8.6zm3.33-6.1h-1.33a4.73 4.73 0 01-4 4V12h-1.35v-1.33a4.71 4.71 0 01-4-4h-1.32V5.32h1.33a4.71 4.71 0 014-4V0h1.35v1.34a4.73 4.73 0 014 4h1.32zm-5.33 2.67a3.42 3.42 0 002.68-2.68h-1.35V5.34h1.35a3.42 3.42 0 00-2.68-2.69V4h-1.34V2.65a3.41 3.41 0 00-2.68 2.69h1.35v1.33h-1.35a3.42 3.42 0 002.68 2.68V8h1.34v1.34z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M44.51 26.4l-6.94 1.32h-25l-7-1.34c.2-1.67.45-3.32.77-5h13.97v-2H6.69c.14-.62.28-1.23.44-1.85l2.19-8.42c3.65-.22 7.32-.36 11-.42v-2c-3.5.06-7 .17-10.47.37l.38-1.47a1 1 0 01.92-.75l6.79-.32 2.38-.08v-2c-.82 0-1.65 0-2.47.09l-6.79.31a3 3 0 00-2.78 2.27l-3.08 12c0 .12-.06.24-.09.36H1v2.5l3.32 1A68.08 68.08 0 003 34.17V45a3 3 0 003 3h4a3 3 0 003-3v-1.23a212.79 212.79 0 0024 0V45a3 3 0 003 3h4a3 3 0 003-3V34.17a67.31 67.31 0 00-.54-8.17zm-6 3.18l6.2-1.18c.12 1.45.2 2.91.23 4.36l-7.08.38zM25 29.73a1 1 0 11-1 1 1 1 0 011-1zM5.28 28.38l6.31 1.2.65 3.56-7.19-.38c.03-1.46.11-2.92.23-4.38zM45 45a1 1 0 01-1 1h-4a1 1 0 01-1-1v-3.35l-1.06.06a210.17 210.17 0 01-25.84 0l-1.1-.06V45a1 1 0 01-1 1H6a1 1 0 01-1-1V34.76l9.63.51-1-5.54h8.54a2.78 2.78 0 00-.19 1 3 3 0 105.82-1h8.64l-1 5.54 9.56-.51zm2-34h-2.82A8.22 8.22 0 0037 3.82V1h-2v2.82A8.22 8.22 0 0027.82 11H25v2h2.82A8.22 8.22 0 0035 20.18V23h2v-2.82A8.22 8.22 0 0044.18 13H47zm-10 7.16v-2.49h-2v2.49A6.27 6.27 0 0129.84 13h2.49v-2h-2.49A6.24 6.24 0 0135 5.84v2.49h2V5.84A6.24 6.24 0 0142.16 11h-2.49v2h2.49A6.27 6.27 0 0137 18.16z" }));
    }
    return null;
});
