"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Performance = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 5.33C5.57 5.33.33 11 .33 18h1.34C1.67 11.75 6.3 6.67 12 6.67S22.33 11.75 22.33 18h1.34c0-7-5.24-12.67-11.67-12.67z" }),
            React.createElement("path", { d: "M17 12l-4.56 4.55a1.45 1.45 0 00-.44-.05 1.5 1.5 0 101.5 1.5 1.45 1.45 0 00-.08-.47L18 13z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.961 8.983l2.001-2.75 1.075.784-2 2.749z" }),
            React.createElement("path", { d: "M6 2.34A5.94 5.94 0 00.33 8.5h1.34A4.6 4.6 0 016 3.67a4.6 4.6 0 014.33 4.83h1.34A5.94 5.94 0 006 2.34z" }));
    }
    return null;
});
