"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.NewCarPrivate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.53 10.09c.77-.12 1.54-.23 2.31-.38l-.24-1.3c-.87.16-1.73.29-2.6.42l-1.32-3.17A3.32 3.32 0 0017.1 4H6.91a3.35 3.35 0 00-2.59 1.66L3 8.86c-.87-.12-1.73-.26-2.6-.42H.33L.16 9.75c.77.14 1.53.26 2.3.37l-.58 1.41A6.3 6.3 0 001 14.76v6.54A1.66 1.66 0 002.61 23h2a1.67 1.67 0 001.62-1.71v-.51h11.55v.52A1.66 1.66 0 0019.4 23h2a1.66 1.66 0 001.6-1.7v-6.54a6.38 6.38 0 00-.87-3.23zm-16-3.82A2 2 0 017 5.33h10a2 2 0 011.45.94L19.63 9A63.94 63.94 0 014.36 9zm15.88 15.4h-2a.34.34 0 01-.29-.37v-1.85H4.89v1.84a.36.36 0 01-.29.38h-2a.34.34 0 01-.28-.37v-6c1.54.22 3.13.41 4.77.53l.1-1.33c-1.65-.12-3.25-.3-4.79-.53A5.09 5.09 0 013 12.2v-.16l.73-1.74a64 64 0 008.06.52 65.78 65.78 0 008.28-.54L20.9 12v.16a5 5 0 01.61 1.71q-2.27.35-4.68.54l.1 1.32c1.6-.12 3.16-.31 4.66-.53v6.1a.35.35 0 01-.2.37z" }),
            React.createElement("ellipse", { cx: "12", cy: "15.46", rx: "1", ry: "1.06" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M47 20.18h-4.82l-3.57-6a5 5 0 00-4-2.4c-3.55-.18-7.11-.28-10.61-.28s-7 .09-10.56.28h-.13a5 5 0 00-4 2.4l-3.59 6H1v2l3 .75-1 1.68A14.29 14.29 0 001 32v11.4A2.66 2.66 0 003.67 46h4.68A2.67 2.67 0 0011 43.35v-1.64q6.5.4 13 .41t12.81-.4h.22v1.64A2.66 2.66 0 0039.72 46h4.61A2.66 2.66 0 0047 43.35V32.2a14.27 14.27 0 00-2-7.36L43.84 23l3.16-.82zm-3.1 6.94a12.12 12.12 0 011 3.28l-5.7 1.13-2.64-.72.38-1.62zm-8.65.48l-.39 1.68h-8.15a3 3 0 00-5.42 0h-8.15l-.39-1.68L5 25.28l1.43-2.37a17.86 17.86 0 004.31.54h26.57a17.41 17.41 0 004.19-.51l1.42 2.38zM25 30.54a1 1 0 11-1-1 1 1 0 011 1zm-13.93-1.35l.37 1.62-2.64.72-5.69-1.13A12.19 12.19 0 014 27.09zm-.09-14a3 3 0 012.39-1.42h.12c3.47-.19 7-.28 10.46-.28s7.06.1 10.56.28a3 3 0 012.38 1.44l3.53 5.91a15.41 15.41 0 01-3.11.32H10.69a15.22 15.22 0 01-3.23-.35zM44.33 44h-4.61a.65.65 0 01-.66-.65v-3.77l-2.13.13h-.21c-4.2.25-8.47.39-12.69.39s-8.62-.14-12.89-.41L9 39.58v3.77a.66.66 0 01-.67.65H3.67a.66.66 0 01-.67-.65V32.41l5.87 1.17 4.94-1.34-.22-1h7.51a3 3 0 005.8 0h7.51l-.22 1 4.94 1.34L45 32.41v10.94a.66.66 0 01-.67.65z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.67 4.63H12V3.24l-.91.14-.89-2A1.8 1.8 0 008.79.55H3.24a1.8 1.8 0 00-1.41.85l-.89 2c-.31 0-.62-.09-.93-.15H0V4.6l.36.05A3.08 3.08 0 000 6.05v4.53a.87.87 0 00.87.87H3a.88.88 0 00.88-.87v-.26h4.29v.26a.87.87 0 00.88.87h2.09a.88.88 0 00.88-.87V6.05a3.17 3.17 0 00-.35-1.42zM3 2.05a.53.53 0 01.31-.17h5.42a.53.53 0 01.27.17l.67 1.51a33.9 33.9 0 01-7.4 0zm6.5 8.07V9h-7v1.13H1.35V7.56c.69.09 1.41.18 2.14.23l.1-1.33c-.77 0-1.52-.14-2.24-.24v-.17a1.78 1.78 0 01.27-1V4.8A34.86 34.86 0 006 5.11a34.81 34.81 0 004.32-.28v.19l.05.08a1.79 1.79 0 01.28 1v.1c-.71.1-1.45.19-2.19.25l.1 1.33c.71-.06 1.41-.14 2.09-.24v2.58zM6.68 7.25A.67.67 0 116 6.59a.66.66 0 01.68.66z" }));
    }
    return null;
});
