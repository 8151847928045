"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.StarOutline = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.42 23.12L12 18.62l-6.38 4.5-1-.74 2.28-7.53-6.3-4.68L1 9l7.87-.16 2.51-7.39h1.26l2.58 7.43L23 9l.39 1.19-6.27 4.76 2.31 7.45zM2.93 10.27l4.74 3.6a1.25 1.25 0 01.45 1.36l-1.73 5.7 4.89-3.4a1.26 1.26 0 011.44 0l4.89 3.4-1.73-5.7a1.25 1.25 0 01.45-1.36l4.74-3.6-6-.12A1.26 1.26 0 0114 9.3l-2-5.63-2 5.63a1.26 1.26 0 01-1.16.85z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 48 48", width: "48", height: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M45.94 19.82l-1.1-3.36-13.38-.3a1.4 1.4 0 01-1.3-.95L25.67 2h-3.34l-4.5 13.23a1.41 1.41 0 01-1.3 1l-13.76.28-1 3.13 11 8.59a1.39 1.39 0 01.48 1.5l-3.82 13 3 2.15 11.21-8a.72.72 0 01.84 0l11.2 8 3-2.15-3.82-13a1.41 1.41 0 01.49-1.51zm-9.82 21.52l-.94.68-10-7.14-.4-.28a1.31 1.31 0 00-1.53 0l-.39.28L12.84 42l-.94-.68 3.54-12a2 2 0 00-.69-2.14l-10.23-8 .25-.78 12.78-.26a2 2 0 001.85-1.36l4.18-12.25h.84l4.16 12.25a2 2 0 001.85 1.36l12.44.28.3 1-9.9 7.68a2 2 0 00-.69 2.14z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9 11.51l-3-2.1-3 2.1-1-.74 1.05-3.51L.12 5l.39-1.2 3.68-.07L5.4.3h1.25l1.21 3.47 3.68.07.39 1.16L9 7.26l1.06 3.51zM5.64 8.06h.76l1.83 1.27-.64-2.13.23-.72L9.6 5.13H7.37l-.61-.45L6 2.54l-.73 2.1-.61.45H2.43l1.8 1.39.23.72-.64 2.13z" }));
    }
    return null;
});
