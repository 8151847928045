"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Logging wrapper to hide warnings on production
 */
function warn(...messages) {
    if (process.env.NODE_ENV !== 'production') {
        messages.forEach(message => console.warn(`vwa-lsg: ${message}`));
    }
}
exports.warn = warn;
function warnWithTrace(...messages) {
    if (process.env.NODE_ENV !== 'production') {
        // Only log as a group and add a stacktrace if run in the browser
        if (typeof window === 'undefined') {
            messages.forEach(message => {
                console.warn(`vwa-lsg: ${message}`);
            });
        }
        else {
            const disableComponentsCoreLogging = (window.sessionStorage &&
                window.sessionStorage.getItem('disableComponentsCoreLogging')) ||
                (window.location.search &&
                    window.location.search.includes('disableComponentsCoreLogging'));
            if (!disableComponentsCoreLogging) {
                messages.forEach(message => {
                    console.groupCollapsed(`vwa-lsg: ${message}`);
                    console.warn(`vwa-lsg: ${message}`);
                    console.trace();
                    console.groupEnd();
                });
            }
        }
    }
}
exports.warnWithTrace = warnWithTrace;
