"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.RadioButtonBorder = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.33C6.107 1.33 1.33 6.107 1.33 12S6.107 22.67 12 22.67 22.67 17.893 22.67 12 17.893 1.33 12 1.33z" }));
    }
    return null;
});
