"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.VideoChat = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.67 15.51h-9.75a6.25 6.25 0 00-2.63.77h-.12A21.82 21.82 0 006 19.08v-3.51H2.33V3.17h19.34zM2.22 1.84A1.22 1.22 0 001 3.06v12.62a1.22 1.22 0 001.22 1.22h2.45v4.89a.37.37 0 00.36.37.32.32 0 00.27-.16 20.68 20.68 0 014.63-4.52 5 5 0 012.07-.6h9.78A1.22 1.22 0 0023 15.62V3.06a1.22 1.22 0 00-1.22-1.22zm8.17 5.06a.45.45 0 00-.19 0 .38.38 0 00-.2.35v4.46a.38.38 0 00.2.35.45.45 0 00.19.05.46.46 0 00.2-.05l3.81-2.2a.37.37 0 00.19-.33.4.4 0 00-.19-.34L10.59 7a.46.46 0 00-.2-.1z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.67 1.33v6.44H5.92a4.18 4.18 0 00-1.69.49h-.12q-.41.29-.78.6V7.8h-2V1.33zM11.33 0H.67A.67.67 0 000 .67v7.8a.67.67 0 00.67.66H2v2.67a.2.2 0 00.2.2.19.19 0 00.14-.11 11.1 11.1 0 012.53-2.46A2.64 2.64 0 016 9.1h5.33a.67.67 0 00.67-.67V.67a.67.67 0 00-.67-.67zM5.21 3.28a.18.18 0 00-.1 0 .21.21 0 00-.11.21v2.44a.21.21 0 00.11.19.19.19 0 00.1 0 .2.2 0 00.11 0L7.4 4.89a.2.2 0 000-.36L5.32 3.3z" }));
    }
    return null;
});
