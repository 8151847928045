"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Breakpoints;
(function (Breakpoints) {
    Breakpoints[Breakpoints["default"] = 0] = "default";
    // These are the old breakpoints.
    // They should only be used with the old definition of textTypes (Use copy100 but not copy0100).
    Breakpoints[Breakpoints["tablet"] = 768] = "tablet";
    Breakpoints[Breakpoints["desktop"] = 1440] = "desktop";
    Breakpoints[Breakpoints["max"] = 1920] = "max";
    // These are the new breakpoints.
    // They should be used in combination with the updated textTypes (copy100 => copy0100).
    Breakpoints[Breakpoints["b560"] = 560] = "b560";
    Breakpoints[Breakpoints["b960"] = 960] = "b960";
    Breakpoints[Breakpoints["b1280"] = 1280] = "b1280";
    Breakpoints[Breakpoints["b1600"] = 1600] = "b1600";
    Breakpoints[Breakpoints["b1920"] = 1920] = "b1920";
    Breakpoints[Breakpoints["b2560"] = 2560] = "b2560";
})(Breakpoints = exports.Breakpoints || (exports.Breakpoints = {}));
const breakpoints = Object.values(Breakpoints);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBreakpointConfig(prop) {
    return (typeof prop === 'object' &&
        Object.keys(prop).every(key => breakpoints.includes(key) || breakpoints.includes(parseInt(key, 10))));
}
exports.isBreakpointConfig = isBreakpointConfig;
/**
 * Compare two breakpoints to each other
 *
 * Pass to sort the breakpoints in ascending order
 *
 * All non-default breakpoints can also be passed as strings to enable mapping over Objects with breakpoints as keys:
 * ```
 * const breakpointConfig = {
 *     [Breakpoints.default]: theme.size.static250,
 *     [Breakpoints.b560]: theme.size.static350
 * };
 *
 * Object.keys(breakpointConfig).sort(compareBreakpoints).map(breakpoint => `
 *     @media (min-with: ${breakpoint}) {
 *         padding: ${breakpointConfig[breakpoint]}
 *     }
 * `)
 * ```
 */
function compareBreakpoints(a, b) {
    return ((typeof a === 'number' ? a : parseInt(a, 10)) -
        (typeof b === 'number' ? b : parseInt(b, 10)));
}
exports.compareBreakpoints = compareBreakpoints;
function mediaQueryTemplate(breakpoint, styles) {
    if (breakpoint === '0') {
        return styles;
    }
    return `@media (min-width: ${breakpoint}px) {${styles}}`;
}
function createStylesForBreakpoints(config, fn = (x) => x) {
    return Object.keys(config)
        .sort(compareBreakpoints)
        .map(breakpoint => mediaQueryTemplate(breakpoint, fn(config[breakpoint])))
        .join('');
}
exports.createStylesForBreakpoints = createStylesForBreakpoints;
