"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Emission = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7.88 9.5a3 3 0 011.39.35l-.27.76a2.28 2.28 0 00-1.13-.26c-.83 0-1.36.82-1.36 2s.53 2 1.36 2A2.28 2.28 0 009 14.07l.26.76a3 3 0 01-1.39.35c-1.52 0-2.37-1.18-2.37-2.84S6.35 9.5 7.88 9.5zM12.21 15.18c-1.63 0-2.36-1.25-2.36-2.84s.73-2.84 2.36-2.84 2.35 1.25 2.35 2.84-.73 2.84-2.35 2.84zm0-.85c1 0 1.33-.88 1.33-2s-.36-2-1.33-2-1.34.88-1.34 2 .37 2 1.34 2zM16.87 15.2h1.46v.8h-2.81v-.74L17 14a.68.68 0 00.27-.57.45.45 0 00-.47-.49 1.15 1.15 0 00-.79.45l-.53-.64a1.77 1.77 0 011.37-.66 1.26 1.26 0 011.4 1.27 1.59 1.59 0 01-.7 1.24l-.71.59z" }),
            React.createElement("path", { d: "M17 20.67H6.5A6.16 6.16 0 012 10.34a2.94 2.94 0 01-.12-.84A3.18 3.18 0 015 6.33a8.16 8.16 0 0114.77 1.6A6.67 6.67 0 0117 20.67zm-12-13A1.83 1.83 0 003.17 9.5a1.76 1.76 0 00.16.74l.19.43-.34.33a4.76 4.76 0 00-1.51 3.5 4.83 4.83 0 004.83 4.83H17A5.33 5.33 0 0019 9l-.31-.12-.09-.31A6.83 6.83 0 005.94 7.34l-.22.42-.46-.07z" }));
    }
    return null;
});
