"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Electricity = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 5.84l-.92 3.43-.32 1.16 1.13.43 4.29 1.61c-1.58 2-3.21 4.09-4.69 6l1.15-3.74.36-1.27-1.23-.41-4-1.32zM14 1a.44.44 0 00-.36.2L6.09 11.82a.62.62 0 00-.09.48.53.53 0 00.32.35l5 1.66-2.45 8a.58.58 0 00.2.63.49.49 0 00.25.08.44.44 0 00.31-.17c4.85-6.11 7.57-9.58 7.68-9.73l.51-.64A.53.53 0 0018 12a.5.5 0 00-.31-.36l-5.31-2 2.12-7.9a.58.58 0 00-.22-.64A.39.39 0 0014 1z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.66 6.52l1.88.69-1.39 4.48a.24.24 0 00.42.21l4-5.43a.56.56 0 00-.25-.86l-2-.74L7.49.3a.24.24 0 00-.43-.19L3.39 5.68a.56.56 0 00.27.84z" }));
    }
    return null;
});
