"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Interior = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { "clip-rule": "evenodd", d: "M12.7 20.6v-5.7c1.2-.2 2-1.1 2.3-2.3h5.7c-.3 4.6-3.5 7.8-8 8m-9.3-7.9h5.7c.2 1.2 1.1 2 2.3 2.3v5.7c-4.6-.3-7.8-3.5-8-8m8.6 1c-1 0-1.7-.7-1.7-1.7s.7-1.7 1.7-1.7 1.7.7 1.7 1.7-.7 1.7-1.7 1.7m0-10.4c4.9 0 8.3 3.3 8.6 8h-5.7C14.7 9.9 13.5 9 12 9s-2.7.9-2.9 2.3H3.4c.3-4.7 3.7-8 8.6-8M12 2C6.2 2 2 6.2 2 12s4.2 10 10 10 10-4.2 10-10S17.8 2 12 2" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.7 10V8c.6-.2 1.1-.7 1.3-1.3h2c-.3 1.8-1.5 3-3.3 3.3M2 6.7h2c.2.6.7 1.1 1.3 1.3v2C3.5 9.7 2.2 8.5 2 6.7m4 0c-.5 0-.8-.2-.8-.7s.3-.7.7-.7c.5 0 .8.3.8.7s-.2.7-.7.7m0-4.8c2.2 0 3.7 1.4 4 3.4H8c-.3-.8-1-1.4-2-1.4s-1.7.6-2 1.4H2c.3-2 1.8-3.4 4-3.4M6 .6C2.9.6.6 2.9.6 6s2.3 5.4 5.4 5.4 5.4-2.3 5.4-5.4S9.1.6 6 .6" }));
    }
    if (variant === "big") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "40", height: "40", viewBox: "0 0 12 12    ", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.7 10V8c.6-.2 1.1-.7 1.3-1.3h2c-.3 1.8-1.5 3-3.3 3.3M2 6.7h2c.2.6.7 1.1 1.3 1.3v2C3.5 9.7 2.2 8.5 2 6.7m4 0c-.5 0-.8-.2-.8-.7s.3-.7.7-.7c.5 0 .8.3.8.7s-.2.7-.7.7m0-4.8c2.2 0 3.7 1.4 4 3.4H8c-.3-.8-1-1.4-2-1.4s-1.7.6-2 1.4H2c.3-2 1.8-3.4 4-3.4M6 .6C2.9.6.6 2.9.6 6s2.3 5.4 5.4 5.4 5.4-2.3 5.4-5.4S9.1.6 6 .6" }));
    }
    return null;
});
