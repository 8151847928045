"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const definitions_1 = require("./definitions");
/**
 * This map contains values to be used with sizes and spacings. The 'spacing'
 * key is deprecated. Use 'size' instead. This map allows the same values to be
 * available for both keys.
 */
const sizesAndSpacings = Object.keys(definitions_1.DesignTokenSizeEnum).reduce((acc, key) => {
    acc[key] = `size.${key}`;
    return acc;
}, {});
/**
 * Set of available UI themes
 *
 * "main" theme must be a complete UI theme, all other themes defined here can be
 * a partial definition, missing values will be inherited from "main"
 */
exports.themeSet = {
    main: {
        animation: {
            timingFunction: {
                in: 'animation.timingFunction.timing100',
                out: 'animation.timingFunction.timing100',
                linear: 'animation.timingFunction.timing200',
                cubicOut: 'animation.timingFunction.timing300',
                navigation: 'animation.timingFunction.timing400'
            },
            duration: {
                collapsible: 'animation.duration.duration600',
                color: 'animation.duration.duration300',
                layer: 'animation.duration.duration300',
                scrollIndicator: 'animation.duration.duration2000',
                nba: 'animation.duration.duration300',
                tabIndicator: 'animation.duration.duration300'
            }
        },
        container: {
            background: {
                primary: 'color.blue-100',
                secondary: 'color.red-100',
                tertiary: 'color.yellow-000'
            }
        },
        global: {
            backgroundColor: 'color.white-000',
            shimColor: 'color.black-000-transparent-50',
            eyeCatcherColor: 'color.mint-100'
        },
        navigation: {
            backgroundColor: 'color.white-000'
        },
        transparentColor: 'color.transparent',
        hoverIndicatorColor: {
            filledElement: {
                default: 'color.white-000-transparent-20',
                active: 'color.white-000-transparent-40'
            },
            outlinedElement: {
                default: 'color.grey-200-transparent-20',
                active: 'color.white-000-transparent-20'
            }
        },
        decoratorColor: 'color.blue-200',
        focusColor: 'color.blue-100',
        separatorColor: 'color.grey-100',
        shadowColor: 'color.black-000',
        backgroundGradient: {
            darkColor: 'color.blue-600',
            lightColor: 'color.blue-500'
        },
        validation: {
            success: 'color.green-100',
            error: 'color.red-100'
        },
        video: {
            background: 'color.black-000'
        },
        interaction: {
            recipe100: {
                default: 'color.black-000',
                hover: 'color.grey-400',
                active: 'color.grey-400',
                disabled: 'color.grey-100',
                checked: 'color.black-000'
            },
            recipe150: {
                default: 'color.grey-100',
                hover: 'color.grey-100',
                active: 'color.grey-100',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            },
            recipe200: {
                default: 'color.grey-400',
                hover: 'color.grey-300',
                active: 'color.grey-300',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            },
            recipe250: {
                default: 'color.blue-200',
                hover: 'color.blue-100',
                active: 'color.blue-100',
                disabled: 'color.grey-100',
                checked: 'color.blue-200'
            },
            recipe300: {
                default: 'color.blue-600',
                hover: 'color.blue-600',
                active: 'color.blue-600',
                disabled: 'color.grey-400',
                checked: 'color.blue-600'
            },
            recipe350: {
                default: 'color.green-200',
                hover: 'color.green-100',
                active: 'color.green-100',
                disabled: 'color.green-200',
                checked: 'color.green-200'
            },
            recipe360: {
                default: 'color.red-200',
                hover: 'color.red-100',
                active: 'color.red-100',
                disabled: 'color.red-200',
                checked: 'color.red-200'
            },
            recipe370: {
                default: 'color.purple-100',
                hover: 'color.purple-100',
                active: 'color.purple-100',
                disabled: 'color.grey-100',
                checked: 'color.purple-100'
            },
            recipe380: {
                default: 'color.yellow-000',
                hover: 'color.yellow-000',
                active: 'color.yellow-000',
                disabled: 'color.grey-100',
                checked: 'color.yellow-000'
            },
            recipe400: {
                default: 'color.blue-600',
                hover: 'color.blue-900',
                active: 'color.blue-900',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            },
            recipe450: {
                default: 'color.blue-600',
                hover: 'color.blue-900',
                active: 'color.blue-900',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            },
            recipe500: {
                default: 'color.blue-600',
                hover: 'color.blue-600',
                active: 'color.blue-600',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            },
            recipe550: {
                default: 'color.grey-200',
                hover: 'color.grey-100',
                active: 'color.grey-100',
                checked: 'color.grey-200'
            },
            recipe600: {
                default: 'color.grey-500',
                hover: 'color.grey-400',
                active: 'color.grey-400',
                checked: 'color.grey-500'
            },
            recipe700: {
                default: 'color.grey-100',
                hover: 'color.grey-400',
                active: 'color.grey-400',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            },
            recipe750: {
                default: 'color.white-000',
                hover: 'color.white-000',
                active: 'color.white-000',
                disabled: 'color.grey-400',
                checked: 'color.white-000'
            },
            recipe800: {
                default: 'color.white-000',
                hover: 'color.white-000',
                active: 'color.white-000',
                disabled: 'color.grey-200',
                checked: 'color.blue-600'
            },
            recipe850: {
                default: 'color.black-000',
                hover: 'color.black-000',
                active: 'color.black-000',
                disabled: 'color.white-000',
                checked: 'color.white-000'
            },
            recipe925: {
                default: 'color.blue-600',
                hover: 'color.blue-900',
                active: 'color.blue-900',
                disabled: 'color.grey-200',
                checked: 'color.blue-900'
            },
            recipe950: {
                default: 'color.white-000',
                hover: 'color.white-000',
                active: 'color.white-000',
                disabled: 'color.white-000',
                checked: 'color.white-000'
            }
        },
        size: sizesAndSpacings,
        textAppearances: {
            // These textAppearances should be used in combination with the new breakpoints.
            copy100: {
                fontFamily: 'textAppearances.copy100.fontFamily',
                fontSize: 'textAppearances.copy100.fontSize',
                reducedFontSize: 'textAppearances.copy100.reducedFontSize',
                lineHeight: 'textAppearances.copy100.lineHeight',
                letterSpacing: 'textAppearances.copy100.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy100.static.fontSize',
                    lineHeight: 'textAppearances.copy100.static.lineHeight',
                    letterSpacing: 'textAppearances.copy100.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy100.static.reducedFontSize'
                }
            },
            copy150: {
                fontFamily: 'textAppearances.copy150.fontFamily',
                fontSize: 'textAppearances.copy150.fontSize',
                reducedFontSize: 'textAppearances.copy150.reducedFontSize',
                lineHeight: 'textAppearances.copy150.lineHeight',
                letterSpacing: 'textAppearances.copy150.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy150.static.fontSize',
                    lineHeight: 'textAppearances.copy150.static.lineHeight',
                    letterSpacing: 'textAppearances.copy150.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy150.static.reducedFontSize'
                }
            },
            copy200: {
                fontFamily: 'textAppearances.copy200.fontFamily',
                fontSize: 'textAppearances.copy200.fontSize',
                reducedFontSize: 'textAppearances.copy200.reducedFontSize',
                lineHeight: 'textAppearances.copy200.lineHeight',
                letterSpacing: 'textAppearances.copy200.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy200.static.fontSize',
                    lineHeight: 'textAppearances.copy200.static.lineHeight',
                    letterSpacing: 'textAppearances.copy200.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy200.static.reducedFontSize'
                }
            },
            copy250: {
                fontFamily: 'textAppearances.copy250.fontFamily',
                fontSize: 'textAppearances.copy250.fontSize',
                reducedFontSize: 'textAppearances.copy250.reducedFontSize',
                lineHeight: 'textAppearances.copy250.lineHeight',
                letterSpacing: 'textAppearances.copy250.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy250.static.fontSize',
                    lineHeight: 'textAppearances.copy250.static.lineHeight',
                    letterSpacing: 'textAppearances.copy250.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy250.static.reducedFontSize'
                }
            },
            copy300: {
                fontFamily: 'textAppearances.copy300.fontFamily',
                fontSize: 'textAppearances.copy300.fontSize',
                reducedFontSize: 'textAppearances.copy300.reducedFontSize',
                lineHeight: 'textAppearances.copy300.lineHeight',
                letterSpacing: 'textAppearances.copy300.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy300.static.fontSize',
                    lineHeight: 'textAppearances.copy300.static.lineHeight',
                    letterSpacing: 'textAppearances.copy300.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy300.static.reducedFontSize'
                }
            },
            headline200: {
                fontFamily: 'textAppearances.headline200.fontFamily',
                fontSize: 'textAppearances.headline200.fontSize',
                reducedFontSize: 'textAppearances.headline200.reducedFontSize',
                lineHeight: 'textAppearances.headline200.lineHeight',
                letterSpacing: 'textAppearances.headline200.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline200.static.fontSize',
                    lineHeight: 'textAppearances.headline200.static.lineHeight',
                    letterSpacing: 'textAppearances.headline200.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline200.static.reducedFontSize'
                }
            },
            headline250: {
                fontFamily: 'textAppearances.headline250.fontFamily',
                fontSize: 'textAppearances.headline250.fontSize',
                reducedFontSize: 'textAppearances.headline250.reducedFontSize',
                lineHeight: 'textAppearances.headline250.lineHeight',
                letterSpacing: 'textAppearances.headline250.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline250.static.fontSize',
                    lineHeight: 'textAppearances.headline250.static.lineHeight',
                    letterSpacing: 'textAppearances.headline250.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline250.static.reducedFontSize'
                }
            },
            headline300: {
                fontFamily: 'textAppearances.headline300.fontFamily',
                fontSize: 'textAppearances.headline300.fontSize',
                reducedFontSize: 'textAppearances.headline300.reducedFontSize',
                lineHeight: 'textAppearances.headline300.lineHeight',
                letterSpacing: 'textAppearances.headline300.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline300.static.fontSize',
                    lineHeight: 'textAppearances.headline300.static.lineHeight',
                    letterSpacing: 'textAppearances.headline300.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline300.static.reducedFontSize'
                }
            },
            headline350: {
                fontFamily: 'textAppearances.headline350.fontFamily',
                fontSize: 'textAppearances.headline350.fontSize',
                reducedFontSize: 'textAppearances.headline350.reducedFontSize',
                lineHeight: 'textAppearances.headline350.lineHeight',
                letterSpacing: 'textAppearances.headline350.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline350.static.fontSize',
                    lineHeight: 'textAppearances.headline350.static.lineHeight',
                    letterSpacing: 'textAppearances.headline350.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline350.static.reducedFontSize'
                }
            },
            headline400: {
                fontFamily: 'textAppearances.headline400.fontFamily',
                fontSize: 'textAppearances.headline400.fontSize',
                reducedFontSize: 'textAppearances.headline400.reducedFontSize',
                lineHeight: 'textAppearances.headline400.lineHeight',
                letterSpacing: 'textAppearances.headline400.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline400.static.fontSize',
                    lineHeight: 'textAppearances.headline400.static.lineHeight',
                    letterSpacing: 'textAppearances.headline400.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline400.static.reducedFontSize'
                }
            },
            headline450: {
                fontFamily: 'textAppearances.headline450.fontFamily',
                fontSize: 'textAppearances.headline450.fontSize',
                reducedFontSize: 'textAppearances.headline450.reducedFontSize',
                lineHeight: 'textAppearances.headline450.lineHeight',
                letterSpacing: 'textAppearances.headline450.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline450.static.fontSize',
                    lineHeight: 'textAppearances.headline450.static.lineHeight',
                    letterSpacing: 'textAppearances.headline450.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline450.static.reducedFontSize'
                }
            },
            headline500: {
                fontFamily: 'textAppearances.headline500.fontFamily',
                fontSize: 'textAppearances.headline500.fontSize',
                reducedFontSize: 'textAppearances.headline500.reducedFontSize',
                lineHeight: 'textAppearances.headline500.lineHeight',
                letterSpacing: 'textAppearances.headline500.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline500.static.fontSize',
                    lineHeight: 'textAppearances.headline500.static.lineHeight',
                    letterSpacing: 'textAppearances.headline500.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline500.static.reducedFontSize'
                }
            },
            headline550: {
                fontFamily: 'textAppearances.headline550.fontFamily',
                fontSize: 'textAppearances.headline550.fontSize',
                reducedFontSize: 'textAppearances.headline550.reducedFontSize',
                lineHeight: 'textAppearances.headline550.lineHeight',
                letterSpacing: 'textAppearances.headline550.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline550.static.fontSize',
                    lineHeight: 'textAppearances.headline550.static.lineHeight',
                    letterSpacing: 'textAppearances.headline550.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline550.static.reducedFontSize'
                }
            },
            headline600: {
                fontFamily: 'textAppearances.headline600.fontFamily',
                fontSize: 'textAppearances.headline600.fontSize',
                reducedFontSize: 'textAppearances.headline600.reducedFontSize',
                lineHeight: 'textAppearances.headline600.lineHeight',
                letterSpacing: 'textAppearances.headline600.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline600.static.fontSize',
                    lineHeight: 'textAppearances.headline600.static.lineHeight',
                    letterSpacing: 'textAppearances.headline600.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline600.static.reducedFontSize'
                }
            },
            label100: {
                fontFamily: 'textAppearances.label100.fontFamily',
                fontSize: 'textAppearances.label100.fontSize',
                reducedFontSize: 'textAppearances.label100.reducedFontSize',
                lineHeight: 'textAppearances.label100.lineHeight',
                letterSpacing: 'textAppearances.label100.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label100.static.fontSize',
                    lineHeight: 'textAppearances.label100.static.lineHeight',
                    letterSpacing: 'textAppearances.label100.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label100.static.reducedFontSize'
                }
            },
            label150: {
                fontFamily: 'textAppearances.label150.fontFamily',
                fontSize: 'textAppearances.label150.fontSize',
                reducedFontSize: 'textAppearances.label150.reducedFontSize',
                lineHeight: 'textAppearances.label150.lineHeight',
                letterSpacing: 'textAppearances.label150.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label150.static.fontSize',
                    lineHeight: 'textAppearances.label150.static.lineHeight',
                    letterSpacing: 'textAppearances.label150.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label150.static.reducedFontSize'
                }
            },
            label200: {
                fontFamily: 'textAppearances.label200.fontFamily',
                fontSize: 'textAppearances.label200.fontSize',
                reducedFontSize: 'textAppearances.label200.reducedFontSize',
                lineHeight: 'textAppearances.label200.lineHeight',
                letterSpacing: 'textAppearances.label200.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label200.static.fontSize',
                    lineHeight: 'textAppearances.label200.static.lineHeight',
                    letterSpacing: 'textAppearances.label200.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label200.static.reducedFontSize'
                }
            },
            label250: {
                fontFamily: 'textAppearances.label250.fontFamily',
                fontSize: 'textAppearances.label250.fontSize',
                reducedFontSize: 'textAppearances.label250.reducedFontSize',
                lineHeight: 'textAppearances.label250.lineHeight',
                letterSpacing: 'textAppearances.label250.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label250.static.fontSize',
                    lineHeight: 'textAppearances.label250.static.lineHeight',
                    letterSpacing: 'textAppearances.label250.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label250.static.reducedFontSize'
                }
            },
            label300: {
                fontFamily: 'textAppearances.label300.fontFamily',
                fontSize: 'textAppearances.label300.fontSize',
                reducedFontSize: 'textAppearances.label300.reducedFontSize',
                lineHeight: 'textAppearances.label300.lineHeight',
                letterSpacing: 'textAppearances.label300.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label300.static.fontSize',
                    lineHeight: 'textAppearances.label300.static.lineHeight',
                    letterSpacing: 'textAppearances.label300.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label300.static.reducedFontSize'
                }
            },
            // Following values are just used for the switch over to the new breakpoints
            copy0100: {
                fontFamily: 'textAppearances.copy100.fontFamily',
                fontSize: 'textAppearances.copy100.fontSize',
                reducedFontSize: 'textAppearances.copy100.reducedFontSize',
                lineHeight: 'textAppearances.copy100.lineHeight',
                letterSpacing: 'textAppearances.copy100.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy100.static.fontSize',
                    lineHeight: 'textAppearances.copy100.static.lineHeight',
                    letterSpacing: 'textAppearances.copy100.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy100.static.reducedFontSize'
                }
            },
            copy0150: {
                fontFamily: 'textAppearances.copy150.fontFamily',
                fontSize: 'textAppearances.copy150.fontSize',
                reducedFontSize: 'textAppearances.copy150.reducedFontSize',
                lineHeight: 'textAppearances.copy150.lineHeight',
                letterSpacing: 'textAppearances.copy150.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy150.static.fontSize',
                    lineHeight: 'textAppearances.copy150.static.lineHeight',
                    letterSpacing: 'textAppearances.copy150.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy150.static.reducedFontSize'
                }
            },
            copy0200: {
                fontFamily: 'textAppearances.copy200.fontFamily',
                fontSize: 'textAppearances.copy200.fontSize',
                reducedFontSize: 'textAppearances.copy200.reducedFontSize',
                lineHeight: 'textAppearances.copy200.lineHeight',
                letterSpacing: 'textAppearances.copy200.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy200.static.fontSize',
                    lineHeight: 'textAppearances.copy200.static.lineHeight',
                    letterSpacing: 'textAppearances.copy200.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy200.static.reducedFontSize'
                }
            },
            copy0250: {
                fontFamily: 'textAppearances.copy250.fontFamily',
                fontSize: 'textAppearances.copy250.fontSize',
                reducedFontSize: 'textAppearances.copy250.reducedFontSize',
                lineHeight: 'textAppearances.copy250.lineHeight',
                letterSpacing: 'textAppearances.copy250.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy250.static.fontSize',
                    lineHeight: 'textAppearances.copy250.static.lineHeight',
                    letterSpacing: 'textAppearances.copy250.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy250.static.reducedFontSize'
                }
            },
            copy0300: {
                fontFamily: 'textAppearances.copy300.fontFamily',
                fontSize: 'textAppearances.copy300.fontSize',
                reducedFontSize: 'textAppearances.copy300.reducedFontSize',
                lineHeight: 'textAppearances.copy300.lineHeight',
                letterSpacing: 'textAppearances.copy300.letterSpacing',
                static: {
                    fontSize: 'textAppearances.copy300.static.fontSize',
                    lineHeight: 'textAppearances.copy300.static.lineHeight',
                    letterSpacing: 'textAppearances.copy300.static.letterSpacing',
                    reducedFontSize: 'textAppearances.copy300.static.reducedFontSize'
                }
            },
            headline0200: {
                fontFamily: 'textAppearances.headline200.fontFamily',
                fontSize: 'textAppearances.headline200.fontSize',
                reducedFontSize: 'textAppearances.headline200.reducedFontSize',
                lineHeight: 'textAppearances.headline200.lineHeight',
                letterSpacing: 'textAppearances.headline200.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline200.static.fontSize',
                    lineHeight: 'textAppearances.headline200.static.lineHeight',
                    letterSpacing: 'textAppearances.headline200.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline200.static.reducedFontSize'
                }
            },
            headline0250: {
                fontFamily: 'textAppearances.headline250.fontFamily',
                fontSize: 'textAppearances.headline250.fontSize',
                reducedFontSize: 'textAppearances.headline250.reducedFontSize',
                lineHeight: 'textAppearances.headline250.lineHeight',
                letterSpacing: 'textAppearances.headline250.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline250.static.fontSize',
                    lineHeight: 'textAppearances.headline250.static.lineHeight',
                    letterSpacing: 'textAppearances.headline250.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline250.static.reducedFontSize'
                }
            },
            headline0300: {
                fontFamily: 'textAppearances.headline300.fontFamily',
                fontSize: 'textAppearances.headline300.fontSize',
                reducedFontSize: 'textAppearances.headline300.reducedFontSize',
                lineHeight: 'textAppearances.headline300.lineHeight',
                letterSpacing: 'textAppearances.headline300.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline300.static.fontSize',
                    lineHeight: 'textAppearances.headline300.static.lineHeight',
                    letterSpacing: 'textAppearances.headline300.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline300.static.reducedFontSize'
                }
            },
            headline0350: {
                fontFamily: 'textAppearances.headline350.fontFamily',
                fontSize: 'textAppearances.headline350.fontSize',
                reducedFontSize: 'textAppearances.headline350.reducedFontSize',
                lineHeight: 'textAppearances.headline350.lineHeight',
                letterSpacing: 'textAppearances.headline350.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline350.static.fontSize',
                    lineHeight: 'textAppearances.headline350.static.lineHeight',
                    letterSpacing: 'textAppearances.headline350.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline350.static.reducedFontSize'
                }
            },
            headline0400: {
                fontFamily: 'textAppearances.headline400.fontFamily',
                fontSize: 'textAppearances.headline400.fontSize',
                reducedFontSize: 'textAppearances.headline400.reducedFontSize',
                lineHeight: 'textAppearances.headline400.lineHeight',
                letterSpacing: 'textAppearances.headline400.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline400.static.fontSize',
                    lineHeight: 'textAppearances.headline400.static.lineHeight',
                    letterSpacing: 'textAppearances.headline400.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline400.static.reducedFontSize'
                }
            },
            headline0450: {
                fontFamily: 'textAppearances.headline450.fontFamily',
                fontSize: 'textAppearances.headline450.fontSize',
                reducedFontSize: 'textAppearances.headline450.reducedFontSize',
                lineHeight: 'textAppearances.headline450.lineHeight',
                letterSpacing: 'textAppearances.headline450.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline450.static.fontSize',
                    lineHeight: 'textAppearances.headline450.static.lineHeight',
                    letterSpacing: 'textAppearances.headline450.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline450.static.reducedFontSize'
                }
            },
            headline0500: {
                fontFamily: 'textAppearances.headline500.fontFamily',
                fontSize: 'textAppearances.headline500.fontSize',
                reducedFontSize: 'textAppearances.headline500.reducedFontSize',
                lineHeight: 'textAppearances.headline500.lineHeight',
                letterSpacing: 'textAppearances.headline500.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline500.static.fontSize',
                    lineHeight: 'textAppearances.headline500.static.lineHeight',
                    letterSpacing: 'textAppearances.headline500.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline500.static.reducedFontSize'
                }
            },
            headline0550: {
                fontFamily: 'textAppearances.headline550.fontFamily',
                fontSize: 'textAppearances.headline550.fontSize',
                reducedFontSize: 'textAppearances.headline550.reducedFontSize',
                lineHeight: 'textAppearances.headline550.lineHeight',
                letterSpacing: 'textAppearances.headline550.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline550.static.fontSize',
                    lineHeight: 'textAppearances.headline550.static.lineHeight',
                    letterSpacing: 'textAppearances.headline550.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline550.static.reducedFontSize'
                }
            },
            headline0600: {
                fontFamily: 'textAppearances.headline600.fontFamily',
                fontSize: 'textAppearances.headline600.fontSize',
                reducedFontSize: 'textAppearances.headline600.reducedFontSize',
                lineHeight: 'textAppearances.headline600.lineHeight',
                letterSpacing: 'textAppearances.headline600.letterSpacing',
                static: {
                    fontSize: 'textAppearances.headline600.static.fontSize',
                    lineHeight: 'textAppearances.headline600.static.lineHeight',
                    letterSpacing: 'textAppearances.headline600.static.letterSpacing',
                    reducedFontSize: 'textAppearances.headline600.static.reducedFontSize'
                }
            },
            label0100: {
                fontFamily: 'textAppearances.label100.fontFamily',
                fontSize: 'textAppearances.label100.fontSize',
                reducedFontSize: 'textAppearances.label100.reducedFontSize',
                lineHeight: 'textAppearances.label100.lineHeight',
                letterSpacing: 'textAppearances.label100.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label100.static.fontSize',
                    lineHeight: 'textAppearances.label100.static.lineHeight',
                    letterSpacing: 'textAppearances.label100.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label100.static.reducedFontSize'
                }
            },
            label0150: {
                fontFamily: 'textAppearances.label150.fontFamily',
                fontSize: 'textAppearances.label150.fontSize',
                reducedFontSize: 'textAppearances.label150.reducedFontSize',
                lineHeight: 'textAppearances.label150.lineHeight',
                letterSpacing: 'textAppearances.label150.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label150.static.fontSize',
                    lineHeight: 'textAppearances.label150.static.lineHeight',
                    letterSpacing: 'textAppearances.label150.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label150.static.reducedFontSize'
                }
            },
            label0200: {
                fontFamily: 'textAppearances.label200.fontFamily',
                fontSize: 'textAppearances.label200.fontSize',
                reducedFontSize: 'textAppearances.label200.reducedFontSize',
                lineHeight: 'textAppearances.label200.lineHeight',
                letterSpacing: 'textAppearances.label200.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label200.static.fontSize',
                    lineHeight: 'textAppearances.label200.static.lineHeight',
                    letterSpacing: 'textAppearances.label200.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label200.static.reducedFontSize'
                }
            },
            label0250: {
                fontFamily: 'textAppearances.label250.fontFamily',
                fontSize: 'textAppearances.label250.fontSize',
                reducedFontSize: 'textAppearances.label250.reducedFontSize',
                lineHeight: 'textAppearances.label250.lineHeight',
                letterSpacing: 'textAppearances.label250.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label250.static.fontSize',
                    lineHeight: 'textAppearances.label250.static.lineHeight',
                    letterSpacing: 'textAppearances.label250.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label250.static.reducedFontSize'
                }
            },
            label0300: {
                fontFamily: 'textAppearances.label300.fontFamily',
                fontSize: 'textAppearances.label300.fontSize',
                reducedFontSize: 'textAppearances.label300.reducedFontSize',
                lineHeight: 'textAppearances.label300.lineHeight',
                letterSpacing: 'textAppearances.label300.letterSpacing',
                static: {
                    fontSize: 'textAppearances.label300.static.fontSize',
                    lineHeight: 'textAppearances.label300.static.lineHeight',
                    letterSpacing: 'textAppearances.label300.static.letterSpacing',
                    reducedFontSize: 'textAppearances.label300.static.reducedFontSize'
                }
            }
        },
        text: {
            color: {
                primary: 'color.black-000',
                primaryHover: 'color.grey-300',
                secondary: 'color.grey-400'
            },
            weight: {
                regular: 'font.weight.regular',
                light: 'font.weight.light',
                bold: 'font.weight.bold'
            },
            background: {
                primary: 'color.white-000'
            }
        },
        border: {
            color: {
                primary: 'color.blue-600',
                secondary: 'color.grey-100'
            }
        },
        disclaimerBadge: {
            backgroundColor: 'color.grey-100'
        }
    },
    inverted: {
        container: {
            background: {
                primary: 'color.red-100',
                secondary: 'color.blue-100',
                tertiary: 'color.green-100'
            }
        },
        global: {
            backgroundColor: 'color.blue-600',
            shimColor: 'color.black-000-transparent-50'
        },
        shadowColor: 'color.white-000',
        hoverIndicatorColor: {
            filledElement: {
                default: 'color.grey-200-transparent-20',
                active: 'color.white-000-transparent-20'
            }
        },
        interaction: {
            recipe100: {
                default: 'color.white-000',
                hover: 'color.grey-200',
                active: 'color.grey-200',
                disabled: 'color.grey-400',
                checked: 'color.white-000'
            },
            recipe150: {
                default: 'color.white-000',
                hover: 'color.white-000',
                active: 'color.white-000',
                disabled: 'color.grey-400',
                checked: 'color.blue-200'
            },
            recipe200: {
                default: 'color.grey-200',
                hover: 'color.grey-100',
                active: 'color.grey-100',
                disabled: 'color.grey-400',
                checked: 'color.blue-200'
            },
            recipe250: {
                default: 'color.blue-200',
                hover: 'color.blue-100',
                active: 'color.blue-100',
                disabled: 'color.grey-100',
                checked: 'color.blue-200'
            },
            recipe300: {
                default: 'color.blue-600',
                hover: 'color.blue-600',
                active: 'color.blue-600',
                disabled: 'color.grey-400',
                checked: 'color.blue-600'
            },
            recipe400: {
                default: 'color.blue-200',
                hover: 'color.blue-100',
                active: 'color.blue-100',
                disabled: 'color.grey-400',
                checked: 'color.blue-200'
            },
            recipe450: {
                default: 'color.white-000',
                hover: 'color.blue-100',
                active: 'color.blue-100',
                disabled: 'color.grey-400',
                checked: 'color.white-000'
            },
            recipe500: {
                default: 'color.white-000',
                hover: 'color.white-000',
                active: 'color.white-000',
                disabled: 'color.grey-400',
                checked: 'color.white-000'
            },
            recipe550: {
                default: 'color.grey-200',
                hover: 'color.grey-100',
                active: 'color.grey-100',
                checked: 'color.grey-200'
            },
            recipe600: {
                default: 'color.grey-500',
                hover: 'color.grey-400',
                active: 'color.grey-400',
                checked: 'color.grey-500'
            },
            recipe750: {
                default: 'color.blue-600',
                hover: 'color.blue-600',
                active: 'color.blue-600',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            }
        },
        text: {
            color: { primary: 'color.white-000', secondary: 'color.blue-100' },
            background: {
                primary: 'color.black-000'
            }
        },
        navigation: {
            backgroundColor: 'color.blue-600'
        }
    },
    image: {
        interaction: {
            recipe100: {
                default: 'color.white-000',
                hover: 'color.blue-100',
                active: 'color.blue-100',
                disabled: 'color.grey-400',
                checked: 'color.white-000'
            },
            recipe400: {
                default: 'color.white-000',
                hover: 'color.blue-100',
                active: 'color.blue-100',
                disabled: 'color.grey-400',
                checked: 'color.white-000'
            },
            recipe450: {
                default: 'color.white-000',
                hover: 'color.blue-100',
                active: 'color.blue-100',
                disabled: 'color.grey-400',
                checked: 'color.white-000'
            },
            recipe750: {
                default: 'color.blue-600',
                hover: 'color.blue-600',
                active: 'color.blue-600',
                disabled: 'color.grey-100',
                checked: 'color.blue-600'
            }
        },
        text: {
            color: { primary: 'color.white-000', secondary: 'color.blue-100' },
            background: {
                primary: 'color.black-000'
            }
        },
        navigation: {
            backgroundColor: 'color.transparent'
        },
        focusColor: 'color.white-000',
        disclaimerBadge: {
            backgroundColor: 'color.grey-100-transparent-40'
        }
    }
};
