"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Bluetooth = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.91 1C5.25 1 3 4.52 3 12a17.77 17.77 0 00.46 4.7c1 3.91 3.68 6.3 8.45 6.3C18.59 23 21 19.38 21 12S18.59 1 11.91 1zm0 20.67c-3.91 0-6.25-1.73-7.16-5.3A16 16 0 014.33 12c0-7.14 2-9.67 7.58-9.67S19.67 5 19.67 12s-2.1 9.67-7.76 9.67zm4.86-12.79a1.14 1.14 0 00-.54-1L12 5.19a1.16 1.16 0 00-1.79 1v4.12L7.86 8.83 7.14 10l3 1.9v.27l-3 1.83.72 1.13 2.3-1.46v4.12a1.18 1.18 0 00.6 1 1.26 1.26 0 00.57.14 1.17 1.17 0 00.62-.18l4.28-2.71a1.14 1.14 0 00.54-1 1.17 1.17 0 00-.54-1L12.86 12l3.37-2.13a1.17 1.17 0 00.54-.99zm-5.28 8.65v-4.66l.13-.08 3.68 2.33zm1.37-7.11l-1.24.79-.13-.08V6.47l3.81 2.41z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.92 3.37a1.09 1.09 0 00-.51-.92L5.81.17A1.1 1.1 0 004.7.14a1.07 1.07 0 00-.56 1v3.23L2.36 3.24l-.72 1.13 2.5 1.57v.11l-2.5 1.58.72 1.13 1.78-1.13v3.28a1.07 1.07 0 00.56.95 1.1 1.1 0 00.52.14 1.12 1.12 0 00.59-.17l3.61-2.28a1.11 1.11 0 00.5-.92 1.07 1.07 0 00-.51-.92L6.71 6l2.7-1.71a1.07 1.07 0 00.51-.92zm-4.45 7.1V6.79l.67.42.32.21 1.92 1.21zm1-5.89l-1 .63V1.53l2.91 1.84z" }));
    }
    return null;
});
