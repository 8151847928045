"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.PaintShop = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.365 12.815h4v1.33h-4zm15.018 3.944l1.301 1.301.941-.94-1.301-1.301zm2.241-6.988l-.941-.94-1.301 1.301.94.941zm.581 4.34v-1.33h-1.84v1.33zm.761-7.072a.75.75 0 10-.514 1.408.75.75 0 00.514-1.408zm0 11.473a.75.75 0 10-.514 1.408.75.75 0 00.514-1.408zm1.284-5.816a.75.75 0 100 1.5.75.75 0 000-1.5zm-9.315-2.444l3.735 1.836v3l-3.757 1.685a.941.941 0 01-.857.557h-2.712v2.924h-1.33V17.33H7.718a.478.478 0 00-.464.362l-1.198 4.793A2.001 2.001 0 014.116 24H2.381a1.33 1.33 0 01-1.29-1.653l1.171-4.683A.27.27 0 002 17.33c-.018 0-.036-.005-.054-.005H1.93v-.002A1.997 1.997 0 010 15.33v-3.7a2 2 0 012-2h3.369a2.61 2.61 0 01-1.521-1.646L2.111 2.371 9.752 0l1.736 5.614a2.615 2.615 0 01-1.37 3.13l.288.886h2.65c.407 0 .747.261.879.622zM4.608 5.943h5.59L8.875 1.665l-5.1 1.582zM12.67 16v-5.04H2a.671.671 0 00-.67.67v3.7c0 .36.286.649.643.664h2.715v1.33H3.571a1.58 1.58 0 01-.02.663l-1.17 4.683h1.735a.67.67 0 00.65-.508l1.198-4.793A1.805 1.805 0 017.718 16h1.296v-.002h1.33V16zm3.67-3.084L14 11.766v3.51l2.34-1.049z" }));
    }
    return null;
});
