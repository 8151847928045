"use strict";
/* eslint-disable @typescript-eslint/camelcase */
Object.defineProperty(exports, "__esModule", { value: true });
var DesignTokenSizeEnum;
(function (DesignTokenSizeEnum) {
    DesignTokenSizeEnum["static0"] = "static0";
    DesignTokenSizeEnum["static100"] = "static100";
    DesignTokenSizeEnum["static150"] = "static150";
    DesignTokenSizeEnum["static200"] = "static200";
    DesignTokenSizeEnum["static250"] = "static250";
    DesignTokenSizeEnum["static300"] = "static300";
    DesignTokenSizeEnum["static350"] = "static350";
    DesignTokenSizeEnum["static370"] = "static370";
    DesignTokenSizeEnum["static400"] = "static400";
    DesignTokenSizeEnum["static450"] = "static450";
    DesignTokenSizeEnum["static500"] = "static500";
    DesignTokenSizeEnum["static510"] = "static510";
    DesignTokenSizeEnum["static515"] = "static515";
    DesignTokenSizeEnum["static520"] = "static520";
    DesignTokenSizeEnum["static530"] = "static530";
    DesignTokenSizeEnum["static535"] = "static535";
    DesignTokenSizeEnum["static550"] = "static550";
    DesignTokenSizeEnum["static565"] = "static565";
    DesignTokenSizeEnum["static575"] = "static575";
    DesignTokenSizeEnum["static600"] = "static600";
    DesignTokenSizeEnum["static625"] = "static625";
    DesignTokenSizeEnum["static650"] = "static650";
    DesignTokenSizeEnum["static700"] = "static700";
    DesignTokenSizeEnum["static750"] = "static750";
    DesignTokenSizeEnum["static800"] = "static800";
    DesignTokenSizeEnum["static900"] = "static900";
    DesignTokenSizeEnum["static1000"] = "static1000";
    DesignTokenSizeEnum["static1100"] = "static1100";
    DesignTokenSizeEnum["static1200"] = "static1200";
    DesignTokenSizeEnum["grid001"] = "grid001";
    DesignTokenSizeEnum["grid002"] = "grid002";
    DesignTokenSizeEnum["grid003"] = "grid003";
    DesignTokenSizeEnum["grid004"] = "grid004";
    DesignTokenSizeEnum["grid005"] = "grid005";
    DesignTokenSizeEnum["grid006"] = "grid006";
    DesignTokenSizeEnum["grid007"] = "grid007";
    DesignTokenSizeEnum["grid008"] = "grid008";
    DesignTokenSizeEnum["grid009"] = "grid009";
    DesignTokenSizeEnum["grid010"] = "grid010";
    DesignTokenSizeEnum["grid011"] = "grid011";
    DesignTokenSizeEnum["grid012"] = "grid012";
    DesignTokenSizeEnum["grid013"] = "grid013";
    DesignTokenSizeEnum["grid014"] = "grid014";
    DesignTokenSizeEnum["grid015"] = "grid015";
    DesignTokenSizeEnum["grid016"] = "grid016";
    DesignTokenSizeEnum["grid017"] = "grid017";
    DesignTokenSizeEnum["grid018"] = "grid018";
    DesignTokenSizeEnum["grid019"] = "grid019";
    DesignTokenSizeEnum["grid020"] = "grid020";
    DesignTokenSizeEnum["grid021"] = "grid021";
    DesignTokenSizeEnum["grid022"] = "grid022";
    DesignTokenSizeEnum["grid023"] = "grid023";
    DesignTokenSizeEnum["grid024"] = "grid024";
    DesignTokenSizeEnum["dynamic0020"] = "dynamic0020";
    DesignTokenSizeEnum["dynamic0040"] = "dynamic0040";
    DesignTokenSizeEnum["dynamic0050"] = "dynamic0050";
    DesignTokenSizeEnum["dynamic0100"] = "dynamic0100";
    DesignTokenSizeEnum["dynamic0120"] = "dynamic0120";
    DesignTokenSizeEnum["dynamic0130"] = "dynamic0130";
    DesignTokenSizeEnum["dynamic0140"] = "dynamic0140";
    DesignTokenSizeEnum["dynamic0150"] = "dynamic0150";
    DesignTokenSizeEnum["dynamic0200"] = "dynamic0200";
    DesignTokenSizeEnum["dynamic0250"] = "dynamic0250";
    DesignTokenSizeEnum["dynamic0270"] = "dynamic0270";
    DesignTokenSizeEnum["dynamic0300"] = "dynamic0300";
    DesignTokenSizeEnum["dynamic0350"] = "dynamic0350";
    DesignTokenSizeEnum["dynamic0450"] = "dynamic0450";
})(DesignTokenSizeEnum = exports.DesignTokenSizeEnum || (exports.DesignTokenSizeEnum = {}));
var DesignTokenFontSizes;
(function (DesignTokenFontSizes) {
    DesignTokenFontSizes[DesignTokenFontSizes["size100"] = 100] = "size100";
    DesignTokenFontSizes[DesignTokenFontSizes["size150"] = 150] = "size150";
    DesignTokenFontSizes[DesignTokenFontSizes["size200"] = 200] = "size200";
    DesignTokenFontSizes[DesignTokenFontSizes["size250"] = 250] = "size250";
    DesignTokenFontSizes[DesignTokenFontSizes["size300"] = 300] = "size300";
    DesignTokenFontSizes[DesignTokenFontSizes["size350"] = 350] = "size350";
    DesignTokenFontSizes[DesignTokenFontSizes["size400"] = 400] = "size400";
    DesignTokenFontSizes[DesignTokenFontSizes["size450"] = 450] = "size450";
    DesignTokenFontSizes[DesignTokenFontSizes["size500"] = 500] = "size500";
    DesignTokenFontSizes[DesignTokenFontSizes["size550"] = 550] = "size550";
    DesignTokenFontSizes[DesignTokenFontSizes["size600"] = 600] = "size600";
    DesignTokenFontSizes["size0100"] = "size0100";
    DesignTokenFontSizes["size0150"] = "size0150";
    DesignTokenFontSizes["size0200"] = "size0200";
    DesignTokenFontSizes["size0250"] = "size0250";
    DesignTokenFontSizes["size0300"] = "size0300";
    DesignTokenFontSizes["size0350"] = "size0350";
    DesignTokenFontSizes["size0400"] = "size0400";
    DesignTokenFontSizes["size0450"] = "size0450";
    DesignTokenFontSizes["size0500"] = "size0500";
    DesignTokenFontSizes["size0550"] = "size0550";
    DesignTokenFontSizes["size0600"] = "size0600";
})(DesignTokenFontSizes = exports.DesignTokenFontSizes || (exports.DesignTokenFontSizes = {}));
