import React from "react";

//COMPONENTS-CORE
import styled from "styled-components";

//LAYOUT
import {
  Container,
  ContainerGutter,
  ContainerWrap,
  ContainerPadding,
  ContainerHorizontalAlignment,
  ContainerVerticalAlignment,
} from "../../../../components-core/packages/components-core/dist/container/index";
import { Layout } from "../../../../components-core/packages/components-core/dist/layout/index";
import { Breakpoints } from "../../../../components-core/packages/components-core/dist/helpers";

//STYLE
import {
  Text,
  TextAppearance,
  TextTag,
} from "../../../../components-core/packages/components-core/dist/text";
import {
  Link,
  LinkButton,
} from "../../../../components-core/packages/components-core/dist/link/index";

//ICONS CORE
import { Close } from "../../../../components-core/packages/icons-core/dist/Close/index";
import newAmarok from "./yeni-amarok.jpg"
import { FleetServicePrivate } from "../../../../components-core/packages/icons-core/dist/FleetServicePrivate";
import SubscribeText from "../SubscribeText";

const TopContainer = styled.div`
  height: 100vh;
  width: ${(props) => (!props.isOpen && !props.isLoaded ? "0vw" : "100vw")};

  z-index: 20;
  position: fixed;

  transition: width 0.3s ease;
  background: rgb(0, 30, 80);
  background: linear-gradient(
    300deg,
    rgba(0, 30, 80, 1) 0%,
    rgba(0, 67, 122, 1) 100%
  );

  overflow-y: auto;
  overflow-x: hidden;
`;

const IconAnimationContainer = styled.div`
  transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
  transition: transform 0.4s ease;
`;

const FooterContainer = styled.div`
  width: 100vw;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition: opacity 0.4s ease;
  @media (min-width: 960px) {
    position: absolute;
    bottom: 0;
  }
`;

const InnerContainer = styled.div`
  width: 100vw;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition: opacity 0.4s ease;
`;

const Line = styled.div`
  height: 100%;
  width: 2px;
  background-color: #96a3a8;

  @media (max-width: 960px) {
    display: none;
  }
`;

const ClickDiv = styled.div`
  cursor: pointer;
`;

class SideBarLevelOne extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoaded: true });
  }

  onClick() {
    document.body.style.overflowY = "auto";

    this.props.onCloseClick();
  }

  renderHeader() {
    const {
      isOpen,
      isSecondLevelOpen,
      isThreeLevelOpen,
      isFourLevelOpen,
    } = this.props;

    if (isThreeLevelOpen) {
      return (
        <Layout appearance={headerLayoutAppearanceTwo(isThreeLevelOpen)}>
          <Container
            horizontalAlign={
              isThreeLevelOpen
                ? ContainerHorizontalAlignment.center
                : ContainerHorizontalAlignment.flexStart
            }
            padding={{ bottom: ContainerPadding.dynamic0300 }}
            wrap={ContainerWrap.never}
          >
            {isThreeLevelOpen
              ? ""
              : [
                <ClickDiv>
                  <LinkButton onClick={() => this.onClick()}>
                    <Container
                      wrap={ContainerWrap.never}
                      gutter={ContainerGutter.static150}
                      verticalAlign={ContainerVerticalAlignment.center}
                    >
                      <IconAnimationContainer isOpen={isOpen}>
                        <Close />
                      </IconAnimationContainer>
                      {isThreeLevelOpen ? "" : <Text>Kapat</Text>}
                    </Container>
                  </LinkButton>
                </ClickDiv>,
              ]}
          </Container>
        </Layout>
      );
    }
    if (isFourLevelOpen) {
      return (
        <Layout appearance={headerLayoutAppearanceTwo(isFourLevelOpen)}>
          <Container
            horizontalAlign={
              isFourLevelOpen
                ? ContainerHorizontalAlignment.center
                : ContainerHorizontalAlignment.flexStart
            }
            padding={{ bottom: ContainerPadding.dynamic0300 }}
            wrap={ContainerWrap.never}
          >
            {isFourLevelOpen
              ? ""
              : [
                <ClickDiv>
                  <LinkButton onClick={() => this.onClick()}>
                    <Container
                      wrap={ContainerWrap.never}
                      gutter={ContainerGutter.static150}
                      verticalAlign={ContainerVerticalAlignment.center}
                    >
                      <IconAnimationContainer isOpen={isOpen}>
                        <Close />
                      </IconAnimationContainer>
                      {isFourLevelOpen ? "" : <Text>Kapat</Text>}
                    </Container>
                  </LinkButton>
                </ClickDiv>,
              ]}
          </Container>
        </Layout>
      );
    }
    return (
      <Layout appearance={headerLayoutAppearance(isSecondLevelOpen)}>
        <Container
          horizontalAlign={
            isSecondLevelOpen
              ? ContainerHorizontalAlignment.center
              : ContainerHorizontalAlignment.flexStart
          }
          padding={{ bottom: ContainerPadding.dynamic0300 }}
          wrap={ContainerWrap.never}
        >
          {isSecondLevelOpen
            ? ""
            : [
              <ClickDiv>
                <LinkButton onClick={() => this.onClick()}>
                  <Container
                    wrap={ContainerWrap.never}
                    gutter={ContainerGutter.static150}
                    verticalAlign={ContainerVerticalAlignment.center}
                  >
                    <IconAnimationContainer isOpen={isOpen}>
                      <Close />
                    </IconAnimationContainer>
                    {isSecondLevelOpen ? "" : <Text>Kapat</Text>}
                  </Container>
                </LinkButton>
              </ClickDiv>,
            ]}
        </Container>
      </Layout>
    );
  }

  renderLeft() {
    const buttons = leftList.map((element) => (
      <ClickDiv>
        <Text tag={TextTag.span} appearance={TextAppearance.headline0300} bold>
          <Link
            onClick={() =>
              this.props.onTextClick(element.link, element.title, element.name)
            }
          >
            {element.name}
          </Link>
        </Text>
      </ClickDiv>
    ));

    return (
      <Container
        padding={leftSectionPadding}
        stretchContent={true}
        wrap={ContainerWrap.always}
        gutter={ContainerGutter.dynamic0100}
      >
        {buttons}
      </Container>
    );
  }

  renderRight() {
    if (this.props.isSecondLevelOpen) {
      return <div />;
    } else if (this.props.isThreeLevelOpen) {
      return <div />;
    } else if (this.props.isFourLevelOpen) {
      return <div />;
    }

    return (
      <Container wrap={ContainerWrap.never}>
        <Line />
        <Container
          wrap={ContainerWrap.always}
          padding={rightSectionPadding}
          gutter={ContainerGutter.dynamic0250}
        >
          <Container
            gutter={ContainerGutter.dynamic0020}
            wrap={ContainerWrap.always}
          >
            <SubscribeText link="https://ticariarac.vw.com.tr/uye-islemleri" title="Üye İşlemleri" target={0} />
            <Text appearance={TextAppearance.copy0150}>
              <Link
                href={"https://canlidestek.vw.com.tr/dim_ticari/chat.html"}
                target={"_blank"}
              >
                Canlı Destek
              </Link>
            </Text>
            {/* <Text appearance={TextAppearance.copy0150}>
              <Link href={"https://wa.me/904448924"} target={"_blank"}>
                VW Whatsapp
              </Link>
            </Text> */}
            <Text appearance={TextAppearance.copy0150}>
              <Link
                href={"https://ticariarac.vw.com.tr/goruntulu-gorusme"}
                target={"_blank"}
              >
                Volkswagen Live!
              </Link>
            </Text>
            <a
              href={"/sikca-sorulan-sorular"}
              style={{
                marginTop: '5px',
                marginLeft: "6px",
                color: "#FFFFFF",
                textDecoration: "none",
              }}
            >
              <span>{"Sıkça Sorulan Sorular"}</span>
            </a>
            <a
              href={"/arac-bulma-asistani"}
              style={{
                marginTop: '5px',
                marginLeft: "6px",
                color: "#FFFFFF",
                textDecoration: "none",
              }}
            >
              <span>{"Stok Bulucu"}</span>
            </a>
          </Container>
          <Container
            gutter={ContainerGutter.dynamic0020}
            wrap={ContainerWrap.always}
          >
            <a href="/amarok">
              <img
                src={newAmarok}
                width="300px"
                height="200px"
                alt={"caddy-style"}
              />
            </a>
            <Text appearance={TextAppearance.headline0250} bold>
              <Link
                href={"/amarok"}
                target={"_blank"}
              >
                Amarok
              </Link>
            </Text>
          </Container>
        </Container>
      </Container>
    );
  }

  renderFooter() {
    const renderedCredits = smallQuickLinks.map((data) => (
      <Text appearance={TextAppearance.copy0150}>
        <Link href={data.link && data.link}>&nbsp; {data.title} &nbsp;| </Link>
      </Text>
    ));

    return (
      <FooterContainer isOpen={this.props.isOpen}>
        <Container
          padding={footerPadding}
          verticalAlign={ContainerVerticalAlignment.center}
        >
          {renderedCredits}
        </Container>
      </FooterContainer>
    );
  }

  renderContent() {
    return (
      <Container stretchContent={true}>
        <Layout appearance={contentLayoutAppearance}>
          {this.renderLeft()}
          {this.renderRight()}
        </Layout>
      </Container>
    );
  }

  render() {
    const { isOpen, isLoaded } = this.props;

    return (
      <TopContainer isOpen={isOpen} isLoaded={isLoaded}>
        <Container
          wrap={ContainerWrap.always}
          padding={{
            top: ContainerPadding.dynamic0100,
            bottom: ContainerPadding.dynamic0100,
          }}
        >
          <InnerContainer isOpen={isOpen}>
            {this.renderHeader()}
            {this.renderContent()}
          </InnerContainer>
        </Container>
        {this.renderFooter()}
      </TopContainer>
    );
  }
}

const headerLayoutAppearance = (isSecondLevelOpen) => ({
  [Breakpoints.default]: !isSecondLevelOpen
    ? [
      { name: ".", columns: 1 },
      { name: "a", columns: 6 },
      { name: ".", columns: 17 },
    ]
    : [
      { name: "a", columns: 3 },
      { name: ".", columns: 21 },
    ],

  [Breakpoints.tablet]: !isSecondLevelOpen
    ? [
      { name: ".", columns: 1 },
      { name: "a", columns: 3 },
      { name: ".", columns: 20 },
    ]
    : [
      { name: "a", columns: 2 },
      { name: ".", columns: 22 },
    ],

  [Breakpoints.desktop]: !isSecondLevelOpen
    ? [
      { name: ".", columns: 1 },
      { name: "a", columns: 3 },
      { name: ".", columns: 20 },
    ]
    : [
      { name: "a", columns: 1 },
      { name: ".", columns: 23 },
    ],
});

const headerLayoutAppearanceTwo = (isThreeLevelOpen) => ({
  [Breakpoints.default]: !isThreeLevelOpen
    ? [
      { name: ".", columns: 1 },
      { name: "a", columns: 6 },
      { name: ".", columns: 17 },
    ]
    : [
      { name: "a", columns: 3 },
      { name: ".", columns: 21 },
    ],

  [Breakpoints.tablet]: !isThreeLevelOpen
    ? [
      { name: ".", columns: 1 },
      { name: "a", columns: 3 },
      { name: ".", columns: 20 },
    ]
    : [
      { name: "a", columns: 2 },
      { name: ".", columns: 22 },
    ],

  [Breakpoints.desktop]: !isThreeLevelOpen
    ? [
      { name: ".", columns: 1 },
      { name: "a", columns: 3 },
      { name: ".", columns: 20 },
    ]
    : [
      { name: "a", columns: 1 },
      { name: ".", columns: 23 },
    ],
});

const contentLayoutAppearance = {
  [Breakpoints.default]: [
    { name: "a", columns: 24 },
    { name: "b", columns: 24 },
  ],
  [Breakpoints.b960]: [
    { name: "a", columns: 17 },
    { name: "b", columns: 7 },
  ],
};

const leftSectionPadding = {
  left: {
    [Breakpoints.default]: ContainerPadding.grid003,
    [Breakpoints.b960]: ContainerPadding.grid002,
  },
};

const rightSectionPadding = {
  right: {
    [Breakpoints.default]: ContainerPadding.grid003,
    [Breakpoints.b960]: ContainerPadding.grid002,
  },
  left: {
    [Breakpoints.default]: ContainerPadding.grid003,
    [Breakpoints.b960]: ContainerPadding.grid001,
  },
  top: {
    [Breakpoints.default]: ContainerPadding.dynamic0250,
    [Breakpoints.b960]: null,
  },
};

const footerPadding = {
  left: {
    [Breakpoints.default]: ContainerPadding.grid003,
    [Breakpoints.b960]: ContainerPadding.grid002,
  },
  right: {
    [Breakpoints.default]: ContainerPadding.grid003,
    [Breakpoints.b960]: ContainerPadding.grid002,
  },
  top: ContainerPadding.dynamic0120,
  bottom: ContainerPadding.dynamic0120,
};

const smallQuickLinks = [
  {
    title: "vdf",
    link: "https://www.vdf.com.tr/tr/ana-sayfa.aspx",
  },
  {
    title: "DOD",
    link: "https://www.dod.com.tr",
  },
  {
    title: "Binek Araç",
    link: "https://binekarac.vw.com.tr/tr.html",
  },
  {
    title: "VW International",
    link: "https://www.volkswagen-nutzfahrzeuge.de/de.html",
  },
  {
    title: "Lastik Etiketleri",
    link: "https://ticariarac.vw.com.tr/lastik-etiketleri",
  },
  {
    title: "Çerez Politikası",
    link: "https://ticariarac.vw.com.tr/cerez-politikasi",
  },
  {
    title: "Kullanım Şartları ve Gizlilik Politikası",
    link: "https://ticariarac.vw.com.tr/ticari-iletisim-izni-aydinlatma-metni",
  },
  {
    title: "Mobil Uygulama",
    link: "https://ticariarac.vw.com.tr/mobil-uygulama",
  },
];

const leftList = [
  {
    name: "Ana Sayfa",
    link: "/ana-sayfa",
  },
  {
    name: "Ticari Araç Fiyat Listesi",
    link: "/fiyat-listesi",
  },
  {
    name: "Kampanyalar & Özel Hizmetler",
    link: "/kampanyalar-ve-hizmetler",
  },
  {
    name: "Araç Karşılaştırma",
    link: "",
  },
  {
    name: "Ticari Araç Modelleri",
    link: "",
  },
  {
    name: "Ticari Araç Servis Hizmetleri",
    link: "",
  },
  {
    name: "Yol Yardım Hizmeti",
    link: "/yol-yardim",
  },
  {
    name: "Aksesuar ve Yedek Parça",
    link: "/aksesuar-ve-yedek-parca",
  },
  {
    name: "Yetkili Satıcı & Servisler",
    link: "/yetkili-satici-ve-servis-noktalari",
  },
  // {
  //   name: "Filo Çözümleri",
  //   link: "/filo",
  // },
  {
    name: "Filo Çözümleri",
    link: "/filo",
  },
  {
    name: "Blog",
    link: "/blog",
  },
];

export default SideBarLevelOne;
