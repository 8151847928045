"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ArrowLeft = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.003 11.032H4.125A88.136 88.136 0 018.89 6.45l-.888-.99c-.06.054-6 5.384-6 6.237 0 .877 5.94 6.237 6 6.29l.888-.99a80.595 80.595 0 01-4.782-4.635h17.895z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.013 5.328H3.136a48.545 48.545 0 011.991-1.885l-.888-.99C.968 5.383.968 5.801.968 5.98c0 .515 1.78 2.217 3.271 3.555l.888-.99a44.17 44.17 0 01-1.979-1.886h7.865z" }));
    }
    return null;
});
