"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Save = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.167 9.161a6.296 6.296 0 00-12.205-2.18H9.1V5.648a1.99 1.99 0 00-1.99-1.991H2.981a1.99 1.99 0 00-1.99 1.99l.01 13.357a1.99 1.99 0 001.99 1.991h16.023a1.99 1.99 0 001.99-1.99v-5.1a6.28 6.28 0 002.163-4.744zM2.32 5.647a.66.66 0 01.66-.66H7.11a.629.629 0 01.66.662V6.98H2.321zm17.353 13.358a.66.66 0 01-.66.66H2.991a.665.665 0 01-.66-.661L2.329 8.311s8.301.017 8.301 0a6.287 6.287 0 009.044 6.484zM16.866 4.191a4.97 4.97 0 010 9.94 5.033 5.033 0 01-4.97-4.97 5.025 5.025 0 014.97-4.97z" }),
            React.createElement("path", { d: "M19.23 8.857c-.76.847-1.303 1.443-1.699 1.87V5.682h-1.33v5.045c-.396-.428-.939-1.024-1.698-1.87l-.99.888c2.91 3.246 2.977 3.246 3.353 3.246s.444 0 3.355-3.246z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.16 6.73c2.42 2.7 2.47 2.7 2.84 2.7s.42 0 2.84-2.7l-1-.89-1.18 1.31V.7H5.34v6.45L4.15 5.84z" }),
            React.createElement("path", { d: "M10.11.25H8.47v1.33h1.64a.36.36 0 01.34.37v8.17a.35.35 0 01-.34.36H1.89a.35.35 0 01-.34-.36V2a.36.36 0 01.34-.37h1.68V.25H1.89A1.69 1.69 0 00.22 2v8.17a1.68 1.68 0 001.67 1.69h8.22a1.68 1.68 0 001.67-1.69V2A1.69 1.69 0 0010.11.25z" }));
    }
    return null;
});
