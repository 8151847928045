"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Info = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 6.493c.442 0 .774.295.774.811s-.332.811-.774.811-.774-.295-.774-.811.332-.811.774-.811zM12.783 15.656v-5.649H9.414v1.33h2.039v4.319H8.419v1.33l6.967-.001v-1.33z" }),
            React.createElement("path", { d: "M12 2.33c5.603 0 9.67 4.067 9.67 9.67s-4.067 9.67-9.67 9.67S2.33 17.603 2.33 12 6.397 2.33 12 2.33M12 1C5.626 1 1 5.626 1 12s4.626 11 11 11 11-4.626 11-11S18.374 1 12 1z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M5.33 6h1.33v3H5.33z" }),
            React.createElement("path", { d: "M6 0a6 6 0 106 6 6 6 0 00-6-6zm0 10.65A4.65 4.65 0 1110.65 6 4.66 4.66 0 016 10.65z" }),
            React.createElement("circle", { cx: "6", cy: "4", r: ".75" }));
    }
    return null;
});
