"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Calc = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7 10.33h2v1.33H7zM11 10.33h2v1.33h-2zM7 14.33h2v1.33H7zM11 14.33h2v1.33h-2zM15 10.33h2v1.33h-2zM7 18.33h2v1.33H7zM11 18.33h2v1.33h-2zM15.33 14.5h1.33V20h-1.33z" }),
            React.createElement("path", { d: "M19.44 1.33a.23.23 0 01.23.23v20.88a.23.23 0 01-.23.23H4.56a.23.23 0 01-.23-.23V1.56a.23.23 0 01.23-.23zm0-1.33H4.56A1.56 1.56 0 003 1.56v20.88A1.56 1.56 0 004.56 24h14.88A1.56 1.56 0 0021 22.44V1.56A1.56 1.56 0 0019.44 0z" }),
            React.createElement("path", { d: "M16.67 4.33v2.34H7.33V4.33zM18 3H6v5h12z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 48 48", width: "48", height: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M41 3v42H7V3zm0-2H7a2 2 0 00-2 2v42a2 2 0 002 2h34a2 2 0 002-2V3a2 2 0 00-2-2z" }),
            React.createElement("path", { d: "M36 8v5H12V8zm2-2H10v9h28zM36 21v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM28 21v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM14 21v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM28 29v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM14 29v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM28 37v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM21 21v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM21 29v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM21 37v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM14 37v2h-2v-2zm1.25-2h-4.5a.76.76 0 00-.75.75v4.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-4.5a.76.76 0 00-.75-.75zM36 29v10h-2V29zm1.25-2h-4.5a.76.76 0 00-.75.75v12.5a.76.76 0 00.75.75h4.5a.76.76 0 00.75-.75v-12.5a.76.76 0 00-.75-.75z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.5 2.84h5v1.33h-5zM3.83 6h1.33v1H3.83zM3.83 8h1.33v1H3.83zM6.83 6h1.33v1H6.83zM6.83 8h1.33v1H6.83z" }),
            React.createElement("path", { d: "M9.67 1.33v9.34H2.33V1.33zM11 0H1v12h10z" }));
    }
    return null;
});
