"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ElectricCarsService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.681 4.782h.819v-1.33h-2.141V0h-1.33v3.452H6.472V0h-1.33v3.452H3v1.33h.82v3.051a2.875 2.875 0 002.91 2.832h2.041a2.876 2.876 0 002.91-2.832zm-6.531 0h5.2v3.051a1.545 1.545 0 01-1.58 1.5H6.73a1.545 1.545 0 01-1.58-1.5zm5.77 6.327a6.425 6.425 0 01-2 .71l-4.53 4.53-.036.037a3.509 3.509 0 00.036 4.963l.2.19.051.051a3.5 3.5 0 004.949-.051l5.2-5.2a8.428 8.428 0 002 .25 7.23 7.23 0 006.71-4 6.643 6.643 0 00.5-1.33h-5l-1.89-2.17.55-.64L19 6.919h5a6.88 6.88 0 00-.5-1.33 7.241 7.241 0 00-6.71-4 7.8 7.8 0 00-3.42.77 5.959 5.959 0 01.54 1.2 6.436 6.436 0 012.88-.67 6.064 6.064 0 015.21 2.7h-3.64L16.72 7.5l-.8.94a.486.486 0 00-.06.09 1 1 0 00.06 1.21l1.63 1.91.81.94H22a6 6 0 01-5.21 2.7 7.06 7.06 0 01-.93-.07 6.571 6.571 0 01-1.5-.37 5.81 5.81 0 01-3.29-3.25c-.07-.151-.07-.3-.15-.491zm-5.59 6.22l4.75-4.74a7 7 0 003.29 3.25L8.61 20.6l-.011.012A2.17 2.17 0 015.53 20.6l-.2-.19a2.2 2.2 0 010-3.08zM8 18.879a1 1 0 10-1 1 1 1 0 001-1z" }));
    }
    return null;
});
