"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Phone = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.5 23.1c-.9 0-1.7-.2-2.4-.5-1-.5-2.8-1.5-3.2-2.9-.2-.8.6-2.9 1.1-4.1l.1-.1c1-1.3 2.8-1.7 5.2-1l2.6-5.3C11.4 7.8 10 6 10.8 4.3c.4-.7 1.6-2.8 2.6-3.2 1.3-.4 2.8-.2 4 .6 1.1.6 2.1 1.6 2.5 2.9.4 1.4.1 2.7-.6 3.9L13.7 20c-.7 1.7-2.2 2.9-4.1 3.1h-.1zm-3.3-6.9c-.6 1.4-1.1 2.8-1 3.2.2.6 1.1 1.4 2.4 2 .6.3 1.3.4 2 .4 1.3-.2 2.4-1 2.9-2.3l5.7-11.7c.5-.9.7-1.9.5-2.8-.3-.9-1-1.6-1.9-2.1-.9-.6-1.9-.7-2.9-.5-.3.2-1.2 1.3-1.9 2.5-.3.8.5 2.1 2.2 3.6l.4.3L11 16l-.5-.2c-1.3-.3-3.3-.8-4.3.4z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.421 11.496a3.334 3.334 0 01-1.614-1.504A2.222 2.222 0 011.9 8.279l.373-.71a1.726 1.726 0 012.224-.61l1.27-2.587a2.05 2.05 0 01-.723-2.198l.259-.612A2.132 2.132 0 016.548.357L6.55.356A2.787 2.787 0 018.77.639a3.02 3.02 0 011.434 1.611 2.656 2.656 0 01-.277 2.104l-2.793 5.719a2.799 2.799 0 01-2.285 1.699 2.837 2.837 0 01-1.428-.276zm.198-3.383c-.111 0-.147.053-.207.14l-.323.624a.889.889 0 00-.028.672 2.23 2.23 0 00.923.741 1.376 1.376 0 00.792.155c.588-.013.998-.618 1.164-.956l2.788-5.71a1.452 1.452 0 00.222-1.087 1.714 1.714 0 00-.796-.875 1.425 1.425 0 00-1.15-.21.803.803 0 00-.477.477l-.238.554c-.07.227.013.466.725 1.055l.404.334-2.233 4.552-.867-.28a2.82 2.82 0 00-.699-.186z" }));
    }
    return null;
});
