"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ArrowRight = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M15.99 5.377l-.887.99a80.64 80.64 0 014.782 4.635H1.991v1.33h17.877a88.117 88.117 0 01-4.764 4.583l.886.99c.06-.054 6-5.386 6-6.239 0-.876-5.94-6.235-6-6.289z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7.783 2.418l-.888.99c.802.718 1.472 1.37 1.98 1.885H1.01v1.33h7.878a48.711 48.711 0 01-1.992 1.886l.887.99c3.272-2.93 3.272-3.349 3.272-3.528 0-.515-1.78-2.217-3.272-3.553z" }));
    }
    return null;
});
