"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.FindCar = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.635 21.581l-3.855-3.628a10.113 10.113 0 10-.933.949l3.876 3.648zM2.461 11.458a8.586 8.586 0 018.678-8.678 8.586 8.586 0 018.678 8.678 8.586 8.586 0 01-8.678 8.678 8.586 8.586 0 01-8.678-8.678z" }),
            React.createElement("path", { d: "M8.162 12.913a.773.773 0 01-.77.78.78.78 0 010-1.56.773.773 0 01.77.78z" }),
            React.createElement("path", { d: "M14.832 9.133s1.09 2.912 1.09 3.27v4.8a1.25 1.25 0 01-1.25 1.25h-1.54a1.25 1.25 0 01-1.25-1.25v-.38h-5.79a7.52 7.52 0 01-1.12-1.33h8.24v1.63h1.38v-4.16c-1.07.15-2.18.27-3.31.35l-.09-1.32c1.09-.08 2.15-.2 3.19-.34-.2-.59-.53-1.42-.91-2.35a53.071 53.071 0 01-6.17.37c-1.09 0-2.19-.04-3.28-.11a7.317 7.317 0 01.48-1.31 51.487 51.487 0 008.42-.23c-.24-.55-.48-1.11-.73-1.64-.18-.38-.38-.57-1.01-.57H6.4a7.269 7.269 0 012.35-1.33h2.43a2.209 2.209 0 012.22 1.35s.41.9.9 2.03c.69-.09 1.38-.19 2.07-.31l.23 1.31c-.588.1-1.768.27-1.768.27z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M46.238 44.2l-8.021-7.547A20.866 20.866 0 0022.554 2.038 20.692 20.692 0 001.723 22.869a20.862 20.862 0 0035.061 15.254l8.045 7.571zM3.777 22.869A18.577 18.577 0 0122.554 4.092a18.577 18.577 0 0118.777 18.777 18.577 18.577 0 01-18.777 18.777A18.577 18.577 0 013.777 22.869z" }),
            React.createElement("path", { d: "M15.272 26.4a1.705 1.705 0 11-1.7-1.72 1.707 1.707 0 011.7 1.72z" }),
            React.createElement("path", { d: "M29.342 17.853s2.5 6.479 2.5 7.44v10.34a2.722 2.722 0 01-2.68 2.69h-3.31a2.689 2.689 0 01-2.68-2.69v-.82h-11.68a17.365 17.365 0 01-1.83-2h15.51v2.82a.689.689 0 00.68.69h3.31a.7.7 0 00.68-.69v-9.67c-2.57.37-5.23.67-7.96.88l-.15-2c2.67-.2 5.28-.49 7.8-.85-.43-1.36-1.26-3.5-2.24-5.87a113.673 113.673 0 01-13.91.87q-3.285 0-6.56-.2a16.376 16.376 0 01.65-1.98 110.824 110.824 0 0019.01-.61c-.6-1.41-1.24-2.85-1.86-4.21a2.766 2.766 0 00-2.93-1.73h-9.38a16 16 0 013.17-2h6.21a4.737 4.737 0 014.75 2.9s.98 2.15 2.11 4.79c1.44-.2 2.88-.41 4.31-.67l.35 1.97c-1.29.23-3.87.6-3.87.6z" }));
    }
    return null;
});
