"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.AirConditioning = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M20.36 15.5l-1.26-.41 2.48-7.69A19 19 0 0012 4.54a19.12 19.12 0 00-9.61 2.87l2 7.72-1.29.33L.85 6.79l.42-.26A20.69 20.69 0 0112 3.21a20.69 20.69 0 0110.73 3.32l.44.27z" }),
            React.createElement("path", { d: "M14.28 20.68l-.73-1.11a2.93 2.93 0 001.53-2.3c0-.64-.33-1-.9-1.65a4.45 4.45 0 01-1.48-3.23 3.48 3.48 0 012.06-2.94l.58 1.2A2.13 2.13 0 0014 12.44a3.11 3.11 0 001.13 2.28 3.65 3.65 0 011.25 2.55 4.19 4.19 0 01-2.1 3.41zM9.17 20.68l-.73-1.11a2.93 2.93 0 001.56-2.3c0-.64-.33-1-.9-1.65a4.45 4.45 0 01-1.48-3.23 3.48 3.48 0 012.03-2.94l.58 1.2a2.13 2.13 0 00-1.31 1.79 3.11 3.11 0 001.13 2.28 3.65 3.65 0 011.25 2.55 4.19 4.19 0 01-2.13 3.41z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.43 7.68l-1.26-.41 1.13-3.52a8.21 8.21 0 00-8.63 0l.92 3.55-1.29.34L.13 3.13l.42-.25a9.68 9.68 0 0110.9 0l.45.27z" }),
            React.createElement("path", { d: "M7.31 10.69l-.74-1.1a1.15 1.15 0 00.6-.85c0-.19-.09-.3-.35-.58A2.53 2.53 0 016 6.34a2 2 0 011.2-1.75l.58 1.2a.72.72 0 00-.46.6c0 .34.17.53.47.87a2.08 2.08 0 01.7 1.48 2.35 2.35 0 01-1.18 1.95zM4.8 10.69l-.74-1.1a1.15 1.15 0 00.6-.85c0-.19-.09-.3-.35-.58a2.53 2.53 0 01-.81-1.82 2 2 0 011.2-1.75l.57 1.2a.73.73 0 00-.45.6c0 .34.16.53.47.87A2.08 2.08 0 016 8.74a2.35 2.35 0 01-1.2 1.95z" }));
    }
    return null;
});
