import React from "react";
import { navigate } from "gatsby";

import { ThemeProvider } from "../../../components-core/packages/components-core/dist/theme-provider";

//CUSTOM COMPONENTS
import SideBarLevelOne from "./level-one/SideBarLevelOne";
import SideBarLevelTwo from "./level-two/SideBarLevelTwo";
import SideBarLevelThree from "./level-three/SideBarLevelThree";
import SideBarLevelFour from "./level-four/SideBarLevelFour";

class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      isSecondLevelOpen: false,
      currentTitle: " ",
      isThreeLevelOpen: false,
      isFourLevelOpen: false,
    };
  }

  onCloseClick = () => {
    if (this.state.isSecondLevelOpen) {
      this.setState({ isSecondLevelOpen: !this.state.isSecondLevelOpen });
    }
    if (this.state.isThreeLevelOpen) {
      this.setState({ isThreeLevelOpen: !this.state.isThreeLevelOpen });
    }
    if (this.state.isFourLevelOpen) {
      this.setState({ isFourLevelOpen: !this.state.isFourLevelOpen });
    }
  };

  render() {
    return (
      <ThemeProvider theme={"image"}>
        <SideBarLevelOne
          isSecondLevelOpen={this.state.isSecondLevelOpen}
          isThreeLevelOpen={this.state.isThreeLevelOpen}
          isFourLevelOpen={this.state.isFourLevelOpen}
          onCloseClick={() => {
            this.props.onCloseClick();
          }}
          isOpen={this.props.isOpen}
          onTextClick={(link, title, name) => {
            if (link) {
              document.body.style.overflowY = "auto";
              this.props.onCloseClick();
              navigate(link);
            } else if (name === "Ticari Araç Servis Hizmetleri") {
              this.setState({
                isThreeLevelOpen: !this.state.isThreeLevelOpen,
                isSecondLevelOpen: this.state.isSecondLevelOpen,
                isFourLevelOpen: this.state.isThreeLevelOpen,
                currentTitle: title,
              });
            } else if (name === "Ticari Araç Modelleri") {
              this.setState({
                isSecondLevelOpen: !this.state.isSecondLevelOpen,
                isThreeLevelOpen: this.state.isThreeLevelOpen,
                isFourLevelOpen: this.state.isThreeLevelOpen,
                currentTitle: title,
              });
            } else if (name === "Araç Karşılaştırma") {
              this.setState({
                isSecondLevelOpen: this.state.isSecondLevelOpen,
                isThreeLevelOpen: this.state.isThreeLevelOpen,
                isFourLevelOpen: !this.state.isThreeLevelOpen,
                currentTitle: title,
              });
            }
          }}
        />
        <SideBarLevelTwo
          isOpen={this.state.isSecondLevelOpen}
          onCloseClick={() => this.onCloseClick()}
          onQuitClick={() => {
            this.props.onCloseClick();
            this.onCloseClick();
          }}
          currentTitle={this.state.currentTitle}
        />
        <SideBarLevelThree
          isOpen={this.state.isThreeLevelOpen}
          onCloseClick={() => this.onCloseClick()}
          onQuitClick={() => {
            this.props.onCloseClick();
            this.onCloseClick();
          }}
          currentTitle={this.state.currentTitle}
        />
        <SideBarLevelFour
          isOpen={this.state.isFourLevelOpen}
          onCloseClick={() => this.onCloseClick()}
          onQuitClick={() => {
            this.props.onCloseClick();
            this.onCloseClick();
          }}
          currentTitle={this.state.currentTitle}
        />
      </ThemeProvider>
    );
  }
}

export default SideBar;
