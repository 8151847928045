"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.UsedCarPrivate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.92 4.73l-3.65-3.65-3.66 3.65.94.94 2.05-2V6a2.84 2.84 0 11-5.67 0 2.87 2.87 0 01.92-2.09l.46-.45-.94-.94-.44.43a4.17 4.17 0 107 3.05V3.63l2.05 2z" }),
            React.createElement("circle", { cx: "11.92", cy: "15.86", r: "1" }),
            React.createElement("path", { d: "M22 12.12h-1.41l.24.55v.08l.05.08a4.47 4.47 0 01.58 1.52c-1.5.22-3.06.39-4.67.51l.1 1.33c1.6-.12 3.16-.3 4.66-.51v5.71a.29.29 0 01-.28.28h-2a.29.29 0 01-.28-.28v-1.82H4.81v1.81a.3.3 0 01-.3.29h-2a.29.29 0 01-.28-.28v-5.68c1.54.21 3.13.39 4.77.5l.09-1.32c-1.64-.12-3.24-.29-4.78-.51a4.65 4.65 0 01.59-1.55l.1-.08v-.08L3.74 11c2.09.24 4.19.4 6.28.46V10.1Q7.17 10 4.3 9.73l1.11-2.52a2 2 0 011.47-.88H10V5H6.82a3.33 3.33 0 00-2.58 1.58l-1.32 3c-.87-.12-1.74-.24-2.61-.39H.25l-.11.65-.06.67c.76.13 1.52.24 2.29.34l-.58 1.3a5.82 5.82 0 00-.87 3.05v6.19A1.61 1.61 0 002.53 23h2a1.63 1.63 0 001.63-1.62v-.48H17.7v.49A1.61 1.61 0 0019.31 23h2a1.62 1.62 0 001.61-1.61V15.2a5.82 5.82 0 00-.87-3.07z" }));
    }
    return null;
});
