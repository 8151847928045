"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Checkmark = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.037 22.392a.667.667 0 01-.422-.151l-7.49-6.142.844-1.028 6.933 5.685 13.1-18.861 1.092.759L9.583 22.106a.669.669 0 01-.546.286z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.39 11.39a.81.81 0 01-.51-.18L0 8.11l.88-1 3.39 2.67L10.86.39l1.14.76L4.88 11.3l-.37.08z" }));
    }
    return null;
});
