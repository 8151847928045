"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const logger_1 = require("../helpers/logger");
const NO_NAME = 'Component';
// Actually the `defaultProps` should also be excluded for the hoisting of static
// properties on components. But there are components that access the `defaultProps`
// from other components to acquire an own default setting. This is not a good
// practice. But this is a deprecation component, so you shouldn't refactor other
// components to deprecate them, therefore `defaultProps` gets hoisted as well
// for compatibility reasons.
const REACT_STATICS = {
    childContextTypes: true,
    contextTypes: true,
    displayName: true,
    getDefaultProps: true,
    getDerivedStateFromProps: true,
    mixins: true,
    propTypes: true,
    type: true
};
const KNOWN_STATICS = {
    name: true,
    length: true,
    prototype: true,
    caller: true,
    callee: true,
    arguments: true,
    arity: true
};
const getPrototypeOf = Object.getPrototypeOf;
const objectPrototype = getPrototypeOf && getPrototypeOf(Object);
/**
 * Hoist statics excluding known (react) statics and built-ins.
 *
 * This method compared to https://github.com/mridgway/hoist-non-react-statics ignores
 * symbols and `defaultProps` for compatibility reasons with our LSG.
 */
function hoistNonReactStatics(targetComponent, sourceComponent) {
    if (objectPrototype) {
        const inheritedComponent = getPrototypeOf(sourceComponent);
        if (inheritedComponent && inheritedComponent !== objectPrototype) {
            hoistNonReactStatics(targetComponent, inheritedComponent);
        }
    }
    const keys = Object.getOwnPropertyNames(sourceComponent);
    keys.forEach((key) => {
        if (!REACT_STATICS[key] && !KNOWN_STATICS[key]) {
            const descriptor = Object.getOwnPropertyDescriptor(sourceComponent, key);
            if (descriptor) {
                // Avoid failures from read-only properties
                try {
                    Object.defineProperty(targetComponent, key, descriptor);
                }
                catch (e) {
                    // Ignore errors
                }
            }
        }
    });
    return targetComponent;
}
/**
 * Extract the display name of the component
 */
function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || NO_NAME;
}
/**
 * Create the deprecation message for component deprecation
 */
function createComponentMessage(component, value) {
    return [
        typeof value !== 'string'
            ? `The component "${getDisplayName(component)}" is deprecated.`
            : value
    ];
}
/**
 * Determine the basic prop deprecation message
 */
function getBaseMessage(prop, name) {
    return `The prop "${prop}" on component "${name || NO_NAME}" is deprecated.`;
}
/**
 * Create the deprecation message for component props deprecation
 */
function createComponentPropsMessage(component, value, props) {
    return value.reduce((result, descriptor) => {
        const [prop, message = ''] = (Array.isArray(descriptor)
            ? descriptor
            : [descriptor]);
        const deprecationMessage = message || getBaseMessage(prop, component.name);
        if (typeof prop === 'string' &&
            Object.prototype.hasOwnProperty.call(props, prop)) {
            return [...result, deprecationMessage];
        }
        return result;
    }, []);
}
/**
 * Wrap a function to create a deprecation wrapper to show warnings on function call
 */
function createDeprecatedFunction(sfc, config) {
    const getMessageFactory = (props) => Array.isArray(config)
        ? createComponentPropsMessage(sfc, config, props)
        : createComponentMessage(sfc, config);
    const wrapper = (props, context) => {
        logger_1.warnWithTrace(...getMessageFactory(props));
        return sfc.call(null, props, context);
    };
    const propertyDescriptor = Object.getOwnPropertyDescriptor(wrapper, 'name');
    if (propertyDescriptor && propertyDescriptor.configurable) {
        Object.defineProperty(wrapper, 'name', { writable: true, value: sfc.name });
    }
    return wrapper;
}
/**
 * Create a method decorator descriptor for deprecated component methods.
 */
function createDeprecatedMethodDescriptor(target, args, value) {
    const [name, descriptor] = args;
    const original = descriptor.value;
    if (typeof original === 'function') {
        descriptor.value = (...vargs) => {
            logger_1.warnWithTrace(value ||
                `The method "${name}" called on component "${target.constructor
                    .name || NO_NAME}" is deprecated.`);
            return original.apply(target, vargs);
        };
    }
    return descriptor;
}
/**
 * Create deprecation warnings for a component or properties defined on that component.
 */
function createDeprecatedComponent(WrappedComponent, config) {
    const getMessageFactory = (props) => Array.isArray(config)
        ? createComponentPropsMessage(WrappedComponent, config, props)
        : createComponentMessage(WrappedComponent, config);
    class Deprecated extends React.Component {
        componentDidMount() {
            logger_1.warnWithTrace(...getMessageFactory(this.props));
        }
        componentDidUpdate() {
            logger_1.warnWithTrace(...getMessageFactory(this.props));
        }
        render() {
            return React.createElement(WrappedComponent, Object.assign({}, this.props));
        }
    }
    Deprecated.displayName = `Deprecated(${getDisplayName(WrappedComponent)})`;
    hoistNonReactStatics(Deprecated, WrappedComponent);
    const propertyDescriptor = Object.getOwnPropertyDescriptor(Deprecated, 'name');
    if (propertyDescriptor && propertyDescriptor.configurable) {
        Object.defineProperty(Deprecated, 'name', {
            value: getDisplayName(WrappedComponent)
        });
    }
    return Deprecated;
}
function deprecated(value, config) {
    // Direct function wrapper
    if (typeof value === 'function') {
        return createDeprecatedFunction(value, config);
    }
    // A class or method decorator
    return (target, name, descriptor) => {
        if (name && descriptor) {
            if (value && typeof value !== 'string') {
                throw new TypeError(`A method decorator can only take a string as optional error message! Type ${typeof value} given.`);
            }
            return createDeprecatedMethodDescriptor(target, [name, descriptor], value);
        }
        return createDeprecatedComponent(target, value);
    };
}
exports.deprecated = deprecated;
