"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const styled_components_1 = require("styled-components");
const theme_provider_1 = require("../theme-provider");
const helpers_1 = require("../helpers");
const deprecated_1 = require("../deprecated");
const linkBaseStyles = styled_components_1.css `
	position: relative;
	color: ${props => props.disabled
    ? props.theme.interaction.recipe400.disabled
    : props.theme.interaction.recipe400.default};
	text-decoration: none;

	${props => props.disabled
    ? styled_components_1.css `
					pointer-events: none;
			  `
    : styled_components_1.css `
					:hover,
					:focus {
						border-bottom-color: ${props.theme.interaction.recipe400.hover};
						color: ${props.theme.interaction.recipe400.hover};
					}
					:focus {
						${helpers_1.createA11yOutlineStyles(props)}
					}
			  `}

	${props => props.isInline &&
    styled_components_1.css `
			padding-bottom: 2px;
			border-bottom: 1px solid currentColor;
		`};
`;
const StyledLink = theme_provider_1.styled.a.withConfig({ displayName: "StyledLink", componentId: "sc-aw07ae" }) `
	${linkBaseStyles}
`;
const StyledLinkButton = theme_provider_1.styled.button.withConfig({ displayName: "StyledLinkButton", componentId: "sc-2gwcsx" }) `
	cursor: pointer;
	background: none;
	border: none;
	padding: inherit;
	appearance: none;
	font-weight: inherit;
	${linkBaseStyles}
`;
const __Link = props => {
    const { children, href, onClick, onMouseOut, onMouseOver, rel, target, innerRef, isInline, title, property, typeOf, disabled, itemProp } = props;
    return (React.createElement(StyledLink, { ref: innerRef, isInline: isInline, href: (!disabled && href) || undefined, onClick: (!disabled && onClick) || undefined, onMouseOver: onMouseOver, onMouseOut: onMouseOut, target: target, rel: rel, title: title, property: property, typeOf: typeOf, disabled: disabled, itemProp: itemProp }, children));
};
exports.Link = deprecated_1.deprecated(__Link, 'The Link component is deprecated. Please use the new CTA component instead.');
const __LinkButton = props => {
    const { children, onClick, innerRef, isInline, disabled } = props;
    return (React.createElement(StyledLinkButton, { ref: innerRef, isInline: isInline, onClick: (!disabled && onClick) || undefined, disabled: disabled }, children));
};
exports.LinkButton = deprecated_1.deprecated(__LinkButton, 'The LinkButton component is deprecated. Please use the new CTA component instead.');
