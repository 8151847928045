"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
const breakpoint_1 = require("../helpers/breakpoint");
const create_css_var_name_1 = require("../theme-provider/create-css-var-name");
const definitions_1 = require("./definitions");
const directions = [
    'top',
    'right',
    'bottom',
    'left',
    'inlineStart',
    'inlineEnd'
];
function isDirectionalConfig(prop) {
    return (typeof prop === 'object' &&
        Object.keys(prop).every(key => directions.includes(key)));
}
// create CSS variables for all dynamic sizes for a given configuration (e.g. container gutter or padding)
function createCSSVariablesForSizes(configOrSize, theme) {
    if (!configOrSize) {
        return '';
    }
    if (isDirectionalConfig(configOrSize)) {
        return directions
            .map(key => {
            const directionalConfigOrSize = configOrSize[key];
            return createCSSVariablesForSizes(directionalConfigOrSize, theme);
        })
            .join('');
    }
    if (breakpoint_1.isBreakpointConfig(configOrSize)) {
        const cssVar = create_css_var_name_1.createCSSVarName(configOrSize, 'container');
        return breakpoint_1.createStylesForBreakpoints(configOrSize, (size) => {
            const value = theme.size[size];
            return `${cssVar}: ${value};`;
        });
    }
    return '';
}
exports.createCSSVariablesForSizes = createCSSVariablesForSizes;
function createSpacing(gutter, theme) {
    if (breakpoint_1.isBreakpointConfig(gutter)) {
        const cssVar = create_css_var_name_1.createCSSVarName(gutter, 'container');
        return `var(${cssVar})`;
    }
    return theme.size[gutter];
}
function createHorizontalAlignmentStyles(horizontalAlign) {
    if (!breakpoint_1.isBreakpointConfig(horizontalAlign)) {
        return `justify-content: ${horizontalAlign};`;
    }
    return breakpoint_1.createStylesForBreakpoints(horizontalAlign, breakpointHorizontalAlign => `justify-content: ${breakpointHorizontalAlign};`);
}
exports.createHorizontalAlignmentStyles = createHorizontalAlignmentStyles;
function wrapStyles(wrap, theme, gutter) {
    const spacing = (gutter && createSpacing(gutter, theme)) || '0px';
    switch (wrap) {
        case definitions_1.ContainerWrap.never:
            return `
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
				margin: 0 calc(-0.5 * ${spacing});
			`;
        case definitions_1.ContainerWrap.always:
            return `
				display: block;
				flex-wrap: unset;
				flex-direction: unset;
				margin: 0 0 calc(-1 * ${spacing});
			`;
        default: {
            return `
				display: flex;
				flex-wrap: wrap;
				margin: 0 calc(-0.5 * ${spacing}) calc(-1 * ${spacing});
			`;
        }
    }
}
function createWrapStyles(wrap = definitions_1.ContainerWrap.auto, theme, gutter) {
    if (!breakpoint_1.isBreakpointConfig(wrap)) {
        const x = wrapStyles(wrap, theme, gutter);
        return x;
    }
    return styled_components_1.css `
		${breakpoint_1.createStylesForBreakpoints(wrap, (breakpointWrap) => wrapStyles(breakpointWrap, theme, gutter))}
	`;
}
exports.createWrapStyles = createWrapStyles;
function spacingBetweenElementsStyles(wrap, theme, gutter) {
    const spacing = (gutter && createSpacing(gutter, theme)) || '0px';
    switch (wrap) {
        case definitions_1.ContainerWrap.never:
            return `
				margin: 0 calc(${spacing} / 2);
			`;
        case definitions_1.ContainerWrap.always:
            return `
				margin: 0 0 ${spacing};
			`;
        default:
            return `
				margin: 0 calc(${spacing} / 2) ${spacing};
			`;
    }
}
function createSpacingBetweenElementsStyles(wrap = definitions_1.ContainerWrap.auto, theme, gutter) {
    if (!breakpoint_1.isBreakpointConfig(wrap)) {
        return spacingBetweenElementsStyles(wrap, theme, gutter);
    }
    return styled_components_1.css `
		${breakpoint_1.createStylesForBreakpoints(wrap, (breakpointWrap) => spacingBetweenElementsStyles(breakpointWrap, theme, gutter))}
	`;
}
exports.createSpacingBetweenElementsStyles = createSpacingBetweenElementsStyles;
function createSpacingAroundElementsStyles(padding, theme, direction) {
    if (typeof padding === 'string' && theme) {
        return styled_components_1.css `
			padding: ${theme.size[padding]};
		`;
    }
    if (isDirectionalConfig(padding)) {
        return styled_components_1.css `
			padding: 0;
			${directions.reduce((acc, key) => {
            const directionalPadding = padding[key];
            const cssKey = key === 'inlineStart'
                ? 'inline-start'
                : key === 'inlineEnd'
                    ? 'inline-end'
                    : key;
            /**
             * TODO: We need to transform 'inline-start' and 'inline-end' to 'left' and 'right' for older
             * browsers. Once all browsers in owr browser matrix support 'inline-start' and 'inline-end' we can
             * remove the code for this transformation as well as the direction state in the container.
             */
            if (breakpoint_1.isBreakpointConfig(directionalPadding)) {
                const cssVar = create_css_var_name_1.createCSSVarName(directionalPadding, 'container');
                return `${acc}
						${key === 'inlineStart'
                    ? direction === 'rtl'
                        ? `padding-right: var(${cssVar});`
                        : `padding-left: var(${cssVar});`
                    : ''}
						${key === 'inlineEnd'
                    ? direction === 'rtl'
                        ? `padding-left: var(${cssVar});`
                        : `padding-right: var(${cssVar});`
                    : ''}
						padding-${cssKey}: var(${cssVar});
						`;
            }
            if (typeof directionalPadding === 'string' && theme) {
                return `${acc}
						${key === 'inlineStart'
                    ? direction === 'rtl'
                        ? `padding-right: ${theme.size[directionalPadding]};`
                        : `padding-left: ${theme.size[directionalPadding]};`
                    : ''}
						${key === 'inlineEnd'
                    ? direction === 'rtl'
                        ? `padding-left: ${theme.size[directionalPadding]};`
                        : `padding-right: ${theme.size[directionalPadding]};`
                    : ''}
						padding-${cssKey}: ${theme.size[directionalPadding]};
						`;
            }
            return acc;
        }, '')}
		`;
    }
    if (breakpoint_1.isBreakpointConfig(padding)) {
        const cssVar = create_css_var_name_1.createCSSVarName(padding, 'container');
        return styled_components_1.css `
			padding: var(${cssVar});
		`;
    }
    return styled_components_1.css `
		padding: 0;
	`;
}
exports.createSpacingAroundElementsStyles = createSpacingAroundElementsStyles;
