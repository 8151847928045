"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Direction = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 3.33a.39.39 0 01.38.26l7.2 16.52a.38.38 0 010 .37.44.44 0 01-.33.19h-.12l-5.93-3.4a2.84 2.84 0 00-2.33 0L4.9 20.65h-.12a.44.44 0 01-.33-.19.38.38 0 010-.35l7.18-16.54a.38.38 0 01.37-.24M12 2a1.7 1.7 0 00-1.59 1.06L3.19 19.59A1.74 1.74 0 004.78 22a1.76 1.76 0 00.7-.15l5.89-3.38a1.56 1.56 0 01.63-.14 1.52 1.52 0 01.63.14l5.89 3.38a1.76 1.76 0 00.7.15 1.74 1.74 0 001.59-2.41L13.6 3.06A1.71 1.71 0 0012 2z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 2l3.48 8L6.9 8.53a2 2 0 00-.9-.19 2.11 2.11 0 00-.9.19L2.52 10zM6 .21a1 1 0 00-.92.61L.9 10.4a1 1 0 00.92 1.39 1 1 0 00.4-.08l3.42-2A.9.9 0 016 9.67a1 1 0 01.37.08l3.41 2a1 1 0 00.4.08 1 1 0 00.92-1.39L6.93.82A1 1 0 006 .21z" }));
    }
    return null;
});
