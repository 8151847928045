"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.HeartFilled = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.042 20.913c-3.337 0-9.51-7.707-10.81-10.11a5.349 5.349 0 012.301-7.106 5.805 5.805 0 016.707 1.101L12.042 6.6l1.718-1.802a5.805 5.805 0 016.707-1.1 5.349 5.349 0 012.302 7.106c-1.301 2.402-7.403 10.11-10.727 10.11z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.02 10.413c-1.652 0-4.707-3.816-5.352-5.005a2.648 2.648 0 011.14-3.519 2.874 2.874 0 013.32.545l.893.892.85-.892a2.874 2.874 0 013.32-.545 2.648 2.648 0 011.14 3.519c-.644 1.19-3.664 5.005-5.31 5.005z" }));
    }
    return null;
});
