"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Internet = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.15 1.07A10.59 10.59 0 001.2 12a10.59 10.59 0 0011 10.93A10.58 10.58 0 0023.1 12 10.58 10.58 0 0012.15 1.07zm9.59 10.26h-4.87a21.46 21.46 0 00-3.45-8.85 9.18 9.18 0 018.32 8.85zm-13 0A21 21 0 0112.16 3a20.49 20.49 0 013.37 8.36zm6.8 1.34c-.09 2.4-1.24 5.23-3.43 8.43-2.19-3.19-3.34-6-3.42-8.43zM10.9 2.48a21.6 21.6 0 00-3.45 8.85H2.57a9.17 9.17 0 018.33-8.85zM2.57 12.67H7.4c.07 2.59 1.22 5.56 3.43 8.84a9.17 9.17 0 01-8.26-8.84zm10.91 8.84c2.19-3.29 3.34-6.26 3.43-8.84h4.83a9.17 9.17 0 01-8.26 8.84z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M5.94 0A5.74 5.74 0 000 5.93a5.74 5.74 0 005.94 5.92 5.73 5.73 0 005.93-5.92A5.73 5.73 0 005.94 0zm4.54 5.26H8.64a10.94 10.94 0 00-1.41-3.75 4.26 4.26 0 013.25 3.75zm-5.88 0A10.12 10.12 0 015.94 2a10.08 10.08 0 011.33 3.26zm2.74 1.33A8.45 8.45 0 015.93 10a8.45 8.45 0 01-1.41-3.41zm-2.7-5.08a10.74 10.74 0 00-1.41 3.75H1.39a4.24 4.24 0 013.25-3.75zM1.39 6.59h1.8a8.85 8.85 0 001.38 3.73 4.25 4.25 0 01-3.18-3.73zm5.91 3.74a8.94 8.94 0 001.38-3.74h1.8a4.28 4.28 0 01-3.18 3.74z" }));
    }
    return null;
});
