"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Reload = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M15.714 5.2l-.522 1.224a7.2 7.2 0 014.335 6.901A7.28 7.28 0 0112 20.852a7.28 7.28 0 01-7.527-7.527 7.234 7.234 0 016.464-7.455 69.224 69.224 0 01-1.973 2.637l1.049.818c3.1-3.977 3.028-4.319 2.971-4.592-.057-.277-.128-.62-4.6-3.066l-.638 1.166a66.588 66.588 0 012.994 1.721 8.512 8.512 0 00-7.597 8.771A8.567 8.567 0 0012 22.183a8.568 8.568 0 008.857-8.858A8.602 8.602 0 0015.714 5.2z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.86 2.744l-.737 1.108a3.566 3.566 0 011.535 3.05A3.5 3.5 0 016 10.56a3.5 3.5 0 01-3.658-3.658 3.464 3.464 0 012.92-3.592 22.27 22.27 0 01-.96 1.035l.947.934C5.907 4.613 7.432 3 7.381 2.442c-.04-.456-.958-1.23-2.733-2.303L3.96 1.278c.46.277.822.515 1.12.72a4.785 4.785 0 00-4.068 4.904A4.824 4.824 0 006 11.89a4.824 4.824 0 004.988-4.988 4.875 4.875 0 00-2.129-4.158z" }));
    }
    return null;
});
