"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Profile = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 2.791a3.99 3.99 0 014.17 4.17 3.953 3.953 0 01-4.17 4.17 3.99 3.99 0 01-4.17-4.17A3.99 3.99 0 0112 2.791m0-1.33a5.323 5.323 0 00-5.5 5.5 5.33 5.33 0 005.5 5.5 5.292 5.292 0 005.5-5.5 5.31 5.31 0 00-5.5-5.5z" }),
            React.createElement("path", { d: "M11.996 22.539c-4.919 0-9.373-.395-9.418-.399a1.313 1.313 0 01-1.187-1.565l.909-5.283a2.427 2.427 0 011.255-1.696l3.525-1.691.575 1.2-3.503 1.68a1.086 1.086 0 00-.544.748l-.91 5.284c.043.003 4.44.392 9.298.392 4.229 0 9.262-.39 9.313-.394l-.921-5.299a1.087 1.087 0 00-.564-.742l-3.481-1.67.576-1.2 3.503 1.682a2.432 2.432 0 011.273 1.689l.915 5.317a1.304 1.304 0 01-.25 1.04 1.318 1.318 0 01-.957.51c-.041.003-5.125.397-9.407.397z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 1.542a1.374 1.374 0 011.462 1.463 1.36 1.36 0 01-1.463 1.462 1.387 1.387 0 01-1.462-1.462 1.374 1.374 0 011.462-1.463m0-1.33a2.703 2.703 0 00-2.792 2.793 2.706 2.706 0 002.792 2.792 2.687 2.687 0 002.793-2.792A2.696 2.696 0 005.999.212z" }),
            React.createElement("path", { d: "M6 11.788a59.68 59.68 0 01-4.814-.204.983.983 0 01-.707-.384.995.995 0 01-.187-.8l.46-2.674a1.565 1.565 0 01.806-1.096L3 5.737l.699 1.131-1.48.916a.27.27 0 00-.16.184l-.399 2.32c.737.056 2.472.17 4.34.17 1.655 0 3.557-.12 4.338-.173L9.936 7.95a.225.225 0 00-.116-.142l-1.522-.934.696-1.133 1.484.911a1.529 1.529 0 01.766 1.056l.466 2.709a.985.985 0 01-.19.783.995.995 0 01-.725.386c-.096.007-2.609.202-4.796.202zm4.4-1.146l.002.016zm-8.798-.012l-.003.012zm-.314-.372l.015.002zm9.413 0l-.008.002z" }));
    }
    return null;
});
