"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Efficiency = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M15.84 6.68h-4.5V5.35h4.5zm2 2.66h-6.5v1.33h6.5zm2 4h-8.5v1.33h8.5zm2 4h-10.5v1.33h10.5zM2.55 12.52l2 .75-1.48 4.79a.26.26 0 00.45.23l4.31-5.82a.6.6 0 00-.27-.92l-2.14-.8 1.23-4.89a.25.25 0 00-.46-.2l-3.93 6a.61.61 0 00.29.86z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M.07 5.76l2.69-4.08a.17.17 0 01.32.13l-.84 3.36 1.46.54a.42.42 0 01.19.64l-3 4a.17.17 0 01-.3-.15l1-3.29-1.32-.53a.41.41 0 01-.2-.62zm8.18-4.25H6v1.33h2.25zM9.5 4.17H6V5.5h3.5zm1.25 2.49H6V8h4.75zM12 9.16H6v1.33h6z" }));
    }
    return null;
});
