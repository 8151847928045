import React from "react";
import { navigate } from "gatsby";
import { TopBar } from "../../../components-core/packages/components-core/dist/top-bar";
import {
  ThemeProvider,
  styled,
} from "../../../components-core/packages/components-core/dist/theme-provider";
import { MenuButton } from "../../../components-core/packages/components-core/dist/menu-button";
import SideBar from "../../components/side-bar/SideBar";
import {
  Container,
  ContainerWrap,
  ContainerGutter,
  ContainerHorizontalAlignment,
  ContainerVerticalAlignment,
} from "../../../components-core/packages/components-core/dist/container";
import { Link } from "../../../components-core/packages/components-core/dist/link/index";
import { Profile } from "../../../components-core/packages/icons-core/dist/Profile/index";

const StickyHeaderWrapper = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2;
`;

let pageYOffset = 0;

class Hero extends React.Component {
  constructor() {
    super();
    this.state = {
      hideLogo: false,
      theme: "image",

      isSideBarOpen: false,
      isLoading: true,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > pageYOffset && !this.state.hideLogo) {
      this.setState({ hideLogo: true });
    } else if (window.pageYOffset < pageYOffset && this.state.hideLogo) {
      this.setState({ hideLogo: false });
    }

    pageYOffset = window.pageYOffset;

    if (pageYOffset > 920) {
      this.setState({ theme: "main" });
    } else if (this.state.theme !== "image") {
      this.setState({ theme: "image" });
    }
  }

  render() {
    const isSideBarOpen = this.state.isSideBarOpen;
    const { theme } = this.state;

    let finalTheme = this.props.theme ? this.props.theme : theme;

    if (this.state.isLoading) {
      return <div />;
    }

    const logo = (
      <div
        style={{ cursor: "pointer", zIndex: 999 }}
        onClick={() => navigate("/ana-sayfa")}
      >
        <img
          width={"100px"}
          height={"76px"}
          src={finalTheme === "main" ? logoDark : logoWhite}
        />
      </div>
    );

    return (
      <ThemeProvider theme={finalTheme}>
        <SideBar
          isOpen={isSideBarOpen}
          onCloseClick={() => this.setState({ isSideBarOpen: !isSideBarOpen })}
        />
        <StickyHeaderWrapper>
          <TopBar
            logo={logo}
            hideLogo={this.state.hideLogo}
            onClick={() => navigate("/ana-sayfa")}
          >
            <MenuButton
              label={"Menüyü aç"}
              onClick={() => {
                document.body.style.overflow = "hidden";
                this.setState({ isSideBarOpen: !isSideBarOpen });
              }}
            />
            <Container
              wrap={ContainerWrap.never}
              gutter={ContainerGutter.dynamic0100}
              horizontalAlign={ContainerHorizontalAlignment.flexEnd}
              verticalAlign={ContainerVerticalAlignment.center}
            >
              <Link href={"/ana-sayfa"}>
                <img
                  width={"24px"}
                  height={"24px"}
                  src={finalTheme === "main" ? profileDark : profileWhite}
                />
              </Link>
              <Link href={"/uye-islemleri"}>
                <Profile />
              </Link>
            </Container>
          </TopBar>
        </StickyHeaderWrapper>
      </ThemeProvider>
    );
  }
}

const logoWhite = require("../../images/logo/whiteThree.png");
const logoDark = require("../../images/logo/darkThree.png");
const profileWhite = require("./homeWhite.png");
const profileDark = require("./home.png");

export default Hero;
