"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.TaxiDealer = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M1.58 6.41L.39 3.54 1.62 3 2.8 5.9zm6.13-1.73l-.5-3-1.31.22.5 3zm5-3.39h-1.37v3h1.33zm5.45.61l-1.31-.22-.5 3 1.31.22zm5.5 1.64L22.39 3l-1.18 2.9 1.23.51zM22 19.61v1.77h2v1.33H0v-1.33h2v-1.77H0v-1.33h.24L1.85 9.4a3.34 3.34 0 013.41-2.54h13.48a3.34 3.34 0 013.41 2.54l1.61 8.88H24v1.33zM1.59 18.28h20.82l-1.56-8.65a2 2 0 00-2.11-1.44H5.26a2 2 0 00-2.11 1.44zm19.09 1.33H3.34v1.77h17.34zM8.13 10.69H4.47V12H5.6v3.77h1.33V12h1.2zm10 5.1h1.33v-5.11h-1.32zm-.65-5.11l-1.66 2.57 1.64 2.55h-1.57L15 14.47l-.86 1.33h-2.55l-.24-.66-.15-.38H9.73l-.13.34-.25.66H7.93l1.91-5.11h1.24l1.78 4.7 1.39-2.14-1.65-2.53h1.58L15 12l.86-1.34zm-7 2.14l-.25.65h.49z" }));
    }
    return null;
});
