"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.QualifiedWorkshop = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.34 12.68l-1.86-2.17 1.86-2.17h5A6.86 6.86 0 0022.86 7a7.23 7.23 0 00-6.7-4 7.91 7.91 0 00-3.42.75v1.5a6.33 6.33 0 013.42-.95 6 6 0 015.2 2.7h-3.63l-2.44 2.86a1 1 0 000 1.3L17.73 14h3.63a6 6 0 01-5.2 2.69 7.14 7.14 0 01-.93-.07 6.72 6.72 0 01-1.5-.37A5.81 5.81 0 0110.44 13H8.58l-4.8 4.8a3.49 3.49 0 000 4.95l.19.19a3.49 3.49 0 004.95 0l5.2-5.2a8 8 0 002 .26 7.23 7.23 0 006.7-4 6.86 6.86 0 00.5-1.33zM8 22a2.15 2.15 0 01-1.53.64A2.17 2.17 0 014.91 22l-.19-.19a2.17 2.17 0 01-.64-1.54 2.15 2.15 0 01.64-1.53L9.45 14a7.05 7.05 0 003.29 3.25z" }),
            React.createElement("path", { d: "M6.44 19.29a1 1 0 101 1 1 1 0 00-1-1zM7 1.33a1.73 1.73 0 011.86 1.83A1.72 1.72 0 017 5a1.73 1.73 0 01-1.8-1.84A1.73 1.73 0 017 1.33M7 0a3.05 3.05 0 00-3.13 3.16A3.06 3.06 0 007 6.32a3 3 0 003.16-3.16A3.05 3.05 0 007 0z" }),
            React.createElement("path", { d: "M12.87 7.61A1.67 1.67 0 0012 6.45l-1.05-.51a4.27 4.27 0 01-1 1l1.44.69a.37.37 0 01.18.23L12 10.6c-.82.05-3.07.2-5 .2-2.2 0-4.24-.14-5-.2l.47-2.72a.36.36 0 01.16-.24l1.47-.7a4.43 4.43 0 01-1-1L2 6.46a1.67 1.67 0 00-.86 1.17l-.52 3a1 1 0 00.19.84 1.06 1.06 0 00.74.4s2.6.23 5.43.23c2.47 0 5.41-.23 5.43-.23a1 1 0 00.75-.41 1 1 0 00.2-.81z" }));
    }
    return null;
});
