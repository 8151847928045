"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.View360 = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.427 8.32h.89c.607 0 .83-.284.83-.684 0-.399-.253-.66-.83-.66a3.522 3.522 0 00-1.26.261l-.306-.83a3.935 3.935 0 011.566-.345c1.29 0 1.944.553 1.944 1.482a1.18 1.18 0 01-.784 1.129v.015a1.322 1.322 0 01.922 1.306c0 .921-.723 1.52-2.082 1.52a3.261 3.261 0 01-1.689-.391l.284-.845a2.856 2.856 0 001.405.323c.553 0 .968-.246.968-.684 0-.476-.253-.706-.968-.706h-.89zM12.888 6.254L11.682 8.02l.008.016a1.523 1.523 0 01.576-.1 1.643 1.643 0 011.636 1.766 1.808 1.808 0 01-1.997 1.812 1.8 1.8 0 01-2.012-1.812 2.295 2.295 0 01.369-1.267l1.444-2.258h1.182zm-.983 2.596a.787.787 0 00-.899.852.82.82 0 00.899.899.812.812 0 00.883-.899.778.778 0 00-.883-.852zM16.494 11.514c-1.443 0-2.027-1.051-2.027-2.726 0-1.674.584-2.726 2.027-2.726s2.013 1.052 2.013 2.726-.569 2.726-2.013 2.726zm0-.913c.676 0 .899-.592.899-1.813 0-1.22-.223-1.812-.899-1.812-.683 0-.914.591-.914 1.812s.231 1.813.914 1.813zM20.265 5.359a1.232 1.232 0 011.352 1.282 1.246 1.246 0 01-1.352 1.306 1.252 1.252 0 01-1.36-1.306 1.243 1.243 0 011.36-1.282zm0 1.88a.554.554 0 00.614-.598.563.563 0 00-.614-.591.573.573 0 00-.622.59.563.563 0 00.622.6zM20.037 9.377l-.555 1.209c1.544.708 2.294 1.561 2.294 2.607 0 1.469-1.85 2.814-4.829 3.509l.303 1.295c3.722-.87 5.856-2.62 5.856-4.804 0-1.598-1.032-2.882-3.069-3.816z" }),
            React.createElement("path", { d: "M11.723 15.722c.562.624.976 1.093 1.292 1.457-.34.013-.668.041-1.015.041-5.574 0-9.777-1.731-9.777-4.027 0-1.317 1.43-2.216 2.281-2.635l-.586-1.193c-1.95.958-3.025 2.317-3.025 3.828 0 3.154 4.567 5.358 11.107 5.358.35 0 .683-.03 1.026-.042-.316.366-.733.838-1.303 1.47l.988.89c2.36-2.618 2.36-2.719 2.36-3.019s0-.401-2.36-3.018z" }));
    }
    return null;
});
