"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Route = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.5 1A5.4 5.4 0 001 6.619a17.322 17.322 0 002.26 7.846C4.452 16.714 5.765 18.5 6.5 18.5s2.061-1.8 3.252-4.051A17.285 17.285 0 0012 6.619 5.394 5.394 0 006.5 1zm2.076 12.827A14.509 14.509 0 016.5 16.975a14.556 14.556 0 01-2.065-3.132A15.985 15.985 0 012.33 6.619 4.048 4.048 0 016.5 2.33a4.048 4.048 0 014.17 4.289 15.96 15.96 0 01-2.094 7.208zm.3-7.261A2.374 2.374 0 116.5 4.18a2.38 2.38 0 012.374 2.386zM1 21h2v2H1zm4 0h2v2H5zm4 0h2v2H9zm4 0h2v2h-2zm4 0h2v2h-2zm6 0v2h-2v-2z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3 0a2.859 2.859 0 00-3 2.89 8.529 8.529 0 001.233 4.035C1.883 8.082 2.6 9 3 9s1.124-.925 1.774-2.083A8.518 8.518 0 006 2.89 2.858 2.858 0 003 0zm.614 6.266A10.489 10.489 0 013 7.243a10.373 10.373 0 01-.608-.969A7.207 7.207 0 011.33 2.89 1.531 1.531 0 013 1.33a1.531 1.531 0 011.67 1.56 7.2 7.2 0 01-1.056 3.376zm.051-3.394A.665.665 0 113 2.2a.667.667 0 01.665.672zM.5 10h1v1h-1zm2 0h1v1h-1zm2 0h1v1h-1zm2 0h1v1h-1zm2 0h1v1h-1zm3 0v1h-1v-1z" }));
    }
    return null;
});
