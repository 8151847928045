"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const helpers_1 = require("../helpers");
const theme_provider_1 = require("../theme-provider");
exports.LayoutRowGap = theme_provider_1.DesignTokenSizeEnum;
exports.LayoutConfigurations = {
    full: {
        [helpers_1.Breakpoints.default]: [{ name: 'a', columns: 24 }]
    },
    half: {
        [helpers_1.Breakpoints.default]: [{ name: 'a', columns: 24 }, { name: 'b', columns: 24 }],
        [helpers_1.Breakpoints.tablet]: [{ name: 'a', columns: 12 }, { name: 'b', columns: 12 }]
    }
};
const areaConfigIsPlaceholder = (areaConfig) => areaConfig.name !== '.';
// increase the number of areas in a configuration so it has at least [length] areas
exports.createFullLayoutConfiguration = (config, length) => {
    const configWithoutEmptySpaces = config.filter(areaConfigIsPlaceholder);
    return Array.from({
        length: Math.ceil(length / configWithoutEmptySpaces.length) * config.length
    }, (_ignore, index) => {
        const name = config[index % config.length].name;
        const columns = config[index % config.length].columns;
        if (name === '.') {
            return {
                name,
                columns
            };
        }
        return {
            name: `${name}${Math.floor(index / config.length)}`,
            columns
        };
    });
};
// distribute configured areas into lines and resolve column numbers
exports.createLayoutConfigurationLines = (config) => config.reduce((acc, area) => {
    const newAreas = new Array(area.columns).fill(area.name);
    if (!acc.length || acc[acc.length - 1].length + area.columns > 24) {
        acc.push(newAreas);
    }
    else {
        acc[acc.length - 1].push(...newAreas);
    }
    return acc;
}, []);
const StyledContainer = theme_provider_1.styled.div.withConfig({ displayName: "StyledContainer", componentId: "sc-1f4aay8" }) `
	display: grid;
	max-width: ${helpers_1.Breakpoints.b2560}px;
	margin: auto;

	${props => Object.keys(props.appearance).map(breakpoint => {
    const config = props.appearance[breakpoint];
    const fullConfig = exports.createFullLayoutConfiguration(config, props.length);
    const configLines = exports.createLayoutConfigurationLines(fullConfig);
    const rule = `
					grid-template-columns: repeat(24, 1fr);
					grid-template-areas: ${configLines
        .map(line => `"${line.join(' ')}"`)
        .join(' ')};
				`;
    if (parseInt(breakpoint, 10) === helpers_1.Breakpoints.default) {
        return rule;
    }
    return `
				@media (min-width: ${breakpoint}px) {
					${rule}
				}
			`;
})}

	row-gap: ${props => props.rowGap && props.theme.size[props.rowGap]};
`;
StyledContainer.displayName = 'StyledContainer';
const getGridAreaForConfigAndChild = (config, index) => Object.keys(config).map(breakpoint => {
    const configForBreakpoint = config[breakpoint].filter(areaConfigIsPlaceholder);
    const configForDefaultBreakpoint = config[helpers_1.Breakpoints.default].filter(areaConfigIsPlaceholder);
    const rule = `grid-area: ${configForDefaultBreakpoint[index % configForBreakpoint.length].name}${Math.floor(index / configForBreakpoint.length)};`;
    if (parseInt(breakpoint, 10) === helpers_1.Breakpoints.default) {
        return rule;
    }
    return `
			@media (min-width: ${breakpoint}px) {
				${rule}
			}
		`;
});
const StyledChildWrapper = theme_provider_1.styled.div.withConfig({ displayName: "StyledChildWrapper", componentId: "sc-1mij48m" }) `
	${props => {
    const { appearance, index = 0 } = props;
    return getGridAreaForConfigAndChild(appearance, index);
}};
	overflow: ${props => (props.allowOverflowingContent ? 'visible' : 'hidden')};
`;
StyledChildWrapper.displayName = 'StyledChildWrapper';
exports.Layout = props => {
    const { children, rowGap, allowOverflowingContent, appearance = exports.LayoutConfigurations.full } = props;
    const filteredChildren = React.Children.toArray(children).filter(React.isValidElement);
    if (filteredChildren.length === 0) {
        return null;
    }
    const wrappedChildren = filteredChildren.map((child, index) => {
        const key = child.key === null ? undefined : child.key;
        return (React.createElement(StyledChildWrapper, { appearance: appearance, key: key, index: index, allowOverflowingContent: allowOverflowingContent }, child));
    });
    return (React.createElement(StyledContainer, { appearance: appearance, rowGap: rowGap, length: filteredChildren.length }, wrappedChildren));
};
exports.Layout.displayName = 'Layout';
