"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.StockLocatorPrivate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.92 16a1 1 0 11-1-1 1 1 0 011 1zM22 12.44h-1.41l.24.55v.08l.05.08a4.47 4.47 0 01.58 1.52c-1.5.22-3.06.39-4.67.51l.1 1.33c1.6-.12 3.16-.3 4.66-.51v5.71a.29.29 0 01-.28.28h-2a.29.29 0 01-.28-.28v-1.82H4.81v1.81a.3.3 0 01-.3.29h-2a.29.29 0 01-.28-.28V16c1.54.21 3.13.39 4.77.5l.09-1.32c-1.64-.12-3.24-.29-4.78-.51a4.65 4.65 0 01.59-1.55l.1-.05V13l.73-1.66c2.09.24 4.19.4 6.28.46v-1.38q-2.85-.06-5.72-.37l1.12-2.52a2 2 0 011.47-.88H10V5.32H6.82A3.33 3.33 0 004.24 6.9l-1.32 3c-.87-.13-1.74-.25-2.61-.4H.25l-.11.65-.06.67c.76.13 1.52.24 2.29.34l-.58 1.3a5.82 5.82 0 00-.87 3.07v6.19a1.61 1.61 0 001.61 1.61h2a1.63 1.63 0 001.61-1.63v-.48H17.7v.49a1.61 1.61 0 001.61 1.61h2a1.62 1.62 0 001.61-1.61v-6.19a5.82 5.82 0 00-.87-3.07zm1.88-5.76h-1.29a4.73 4.73 0 01-4 4V12h-1.35v-1.33a4.71 4.71 0 01-4-4h-1.32V5.32h1.33a4.71 4.71 0 014-4V0h1.35v1.34a4.73 4.73 0 014 4h1.32zm-5.29 2.67a3.42 3.42 0 002.68-2.68h-1.35V5.34h1.35a3.42 3.42 0 00-2.68-2.69V4h-1.34V2.65a3.41 3.41 0 00-2.68 2.69h1.35v1.33h-1.35a3.42 3.42 0 002.68 2.68V8h1.34v1.34z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M44.9 26.73l-2 .59-7.65 2.28-.39 1.68h-8.15a3 3 0 00-5.42 0h-8.15l-.39-1.68L5 27.28l1.38-2.38a17.38 17.38 0 004.31.55h7.46v-2h-7.46a15.22 15.22 0 01-3.23-.35L11 17.21a3 3 0 012.39-1.42h.12c1.55-.08 3.11-.14 4.66-.19v-2c-1.59.05-3.18.11-4.76.19h-.13a5 5 0 00-4 2.4l-3.59 6H1v2l3 .75-1 1.67A14.29 14.29 0 001 34v11.4A2.66 2.66 0 003.67 48h4.68A2.67 2.67 0 0011 45.35v-1.64q6.5.4 13 .41t12.81-.4h.22v1.64A2.66 2.66 0 0039.72 48h4.61A2.66 2.66 0 0047 45.35V34.2a14.27 14.27 0 00-2-7.36zm-8 4.46l7-2.07a12.12 12.12 0 011 3.28l-5.7 1.13-2.64-.72zm-12.9.35a1 1 0 11-1 1 1 1 0 011-1zM4 29.09l7.07 2.1.37 1.62-2.64.72-5.69-1.14a12.22 12.22 0 01.89-3.3zm41 16.26a.66.66 0 01-.67.65h-4.61a.65.65 0 01-.66-.65v-3.77l-2.13.13h-.21c-4.2.25-8.47.39-12.69.39s-8.62-.14-12.89-.41L9 41.58v3.77a.66.66 0 01-.67.65H3.67a.66.66 0 01-.67-.65V34.41l5.87 1.17 4.94-1.34-.22-1h7.51a3 3 0 005.8 0h7.51l-.22 1 4.94 1.34L45 34.41zM46 12h-2.82A8.22 8.22 0 0036 4.82V2h-2v2.82A8.22 8.22 0 0026.82 12H24v2h2.82A8.22 8.22 0 0034 21.18V24h2v-2.82A8.22 8.22 0 0043.18 14H46zm-10 7.16v-2.49h-2v2.49A6.25 6.25 0 0128.84 14h2.49v-2h-2.49A6.25 6.25 0 0134 6.84v2.49h2V6.84A6.25 6.25 0 0141.16 12h-2.49v2h2.49A6.25 6.25 0 0136 19.16z" }));
    }
    return null;
});
