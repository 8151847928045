"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.OpenSaturday = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.57 7.83L15.7 5.46l.84-1.03 1.94 1.6 2.53-2.52.94.94zM3.64 18.51a4 4 0 001.79.54c.72 0 1.07-.27 1.07-.82s-.24-.59-.91-.82l-.7-.22a1.89 1.89 0 01-1.53-1.8c0-1.18.81-2 2.35-2a4.75 4.75 0 011.91.46l-.4 1.06a3.94 3.94 0 00-1.51-.41c-.72 0-1 .3-1 .75s.14.58.67.74l.67.22c1.22.38 1.8.91 1.8 1.89 0 1.27-.92 2.05-2.42 2.05a4.9 4.9 0 01-2.2-.62zM14.41 20H13l-.42-1.33H10L9.56 20H8.11l2.38-6.47H12zm-3.17-5.1l-.89 2.72h1.8l-.88-2.72zM19 13.55v1.1h-1.82V20h-1.32v-5.35H14v-1.1z" }),
            React.createElement("path", { d: "M24 5.26A5.24 5.24 0 0014 3H5.34V1H4v2H0v17a3 3 0 003 3h16a3 3 0 003-3V9.36a5.22 5.22 0 002-4.1zm-5.24-3.81A3.82 3.82 0 1115 5.26a3.82 3.82 0 013.76-3.81zM20.68 20A1.67 1.67 0 0119 21.68H3A1.67 1.67 0 011.34 20V10h13A3.42 3.42 0 0113 8.68H1.34V4.34H4V7h1.34V4.34h8.27a4.71 4.71 0 00-.1.92 5.24 5.24 0 007.17 4.88z" }));
    }
    return null;
});
