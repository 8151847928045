"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Download = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.67 18.35V21H4.33v-2.65H3v2.82a1.16 1.16 0 001.17 1.16h15.66A1.16 1.16 0 0021 21.17v-2.82z" }),
            React.createElement("path", { d: "M12 19c.41 0 .49 0 4.47-4.21l-1-.91c-.73.77-2 2.08-2.85 3V2.33h-1.28v14.52c-.76-.77-1.85-1.91-2.86-3l-1 .91C11.52 19 11.57 19 12 19z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.49 9.15v1.52h-9V9.15H.18V11a1 1 0 001 1h9.68a1 1 0 001-1V9.15zM6 8.94c.37 0 .41 0 3-2.68l-1-.91c-.32.33-.84.89-1.32 1.38V0H5.34v6.74L4 5.35l-1 .92c2.59 2.67 2.62 2.67 3 2.67z" }));
    }
    return null;
});
