"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Load = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.167 9.161a6.296 6.296 0 00-12.205-2.18H9.1V5.648a1.99 1.99 0 00-1.99-1.991H2.981a1.99 1.99 0 00-1.99 1.99l.01 13.357a1.99 1.99 0 001.99 1.991h16.023a1.99 1.99 0 001.99-1.99v-5.1a6.28 6.28 0 002.163-4.744zM2.32 5.647a.66.66 0 01.66-.66H7.11a.629.629 0 01.66.662V6.98H2.321zm17.353 13.358a.66.66 0 01-.66.66H2.991a.665.665 0 01-.66-.661L2.329 8.311s8.301.017 8.301 0a6.287 6.287 0 009.044 6.484zM16.866 4.191a4.97 4.97 0 010 9.94 5.033 5.033 0 01-4.97-4.97 5.025 5.025 0 014.97-4.97z" }),
            React.createElement("path", { d: "M14.503 9.816c.76-.847 1.303-1.443 1.7-1.87v5.045h1.33V7.946c.395.428.938 1.024 1.697 1.87l.99-.888c-2.91-3.246-2.977-3.246-3.353-3.246s-.444 0-3.355 3.246z" }));
    }
    return null;
});
