"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Diesel = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.8 9.8l-5.7-8h-2l-5.7 8c-2.6 3.7-1.7 8.8 2 11.4s8.8 1.7 11.4-2c2-2.7 2-6.5 0-9.4zM8.3 18.2c-1.8-1.8-2-4.6-.6-6.7l1.1.8c-1.1 1.5-.9 3.7.4 5z" }));
    }
    return null;
});
