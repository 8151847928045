"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ShareIOS = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.31 22.49H5.88A2.81 2.81 0 013 19.68V8.38h3.2v1.33H4.37v10a1.45 1.45 0 001.43 1.48h12.51a1.36 1.36 0 001.34-1.39V9.71H18V8.38h3v11.39a2.7 2.7 0 01-2.69 2.72z" }),
            React.createElement("path", { d: "M16.75 6C12.68 1.68 12.51 1.51 12 1.51s-.64.17-4.71 4.49l1 .92c1.26-1.33 2.36-2.49 3.1-3.24V16.5h1.33V3.64c.74.75 1.83 1.91 3.1 3.24z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.28 11.78H2.82A1.79 1.79 0 011 10V3.8h2.28v1.33h-.93V10a.44.44 0 00.43.45h6.43a.42.42 0 00.45-.45V5.13h-.84V3.8H11V10a1.73 1.73 0 01-1.72 1.78z" }),
            React.createElement("path", { d: "M8.7 2.64C6.45.27 6.41.22 6 .22s-.43.05-2.68 2.42l1 .92 1.03-1.12v5.9h1.33v-5.9l1.06 1.12z" }));
    }
    return null;
});
