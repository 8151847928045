"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Add = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 2.33A9.353 9.353 0 0121.67 12 9.353 9.353 0 0112 21.67 9.353 9.353 0 012.33 12 9.353 9.353 0 0112 2.33M12 1A10.653 10.653 0 001 12a10.653 10.653 0 0011 11 10.653 10.653 0 0011-11A10.653 10.653 0 0012 1z" }),
            React.createElement("path", { d: "M16 11.34h-3.34V7.88h-1.32v3.46H8v1.33h3.34v3.44h1.32v-3.44H16z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.665 3.5h-1.33v1.835H3.5v1.33h1.835V8.5h1.33V6.665H8.5v-1.33H6.665z" }),
            React.createElement("path", { d: "M6 0a5.811 5.811 0 00-6 6 5.811 5.811 0 006 6 5.811 5.811 0 006-6 5.811 5.811 0 00-6-6zm0 10.67A4.468 4.468 0 011.33 6 4.468 4.468 0 016 1.33 4.468 4.468 0 0110.67 6 4.468 4.468 0 016 10.67z" }));
    }
    return null;
});
