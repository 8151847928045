"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Media = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 2.33A9.353 9.353 0 0121.67 12 9.353 9.353 0 0112 21.67 9.353 9.353 0 012.33 12 9.353 9.353 0 0112 2.33M12 1A10.653 10.653 0 001 12a10.653 10.653 0 0011 11 10.653 10.653 0 0011-11A10.653 10.653 0 0012 1zM9.967 8.77L15.451 12l-5.484 3.23zM9.383 7a.736.736 0 00-.367.1.747.747 0 00-.379.658v8.486a.748.748 0 00.379.658.73.73 0 00.741 0l7.234-4.26a.731.731 0 000-1.274L9.757 7.1a.735.735 0 00-.374-.1z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 1.33A4.468 4.468 0 0110.67 6 4.468 4.468 0 016 10.67 4.468 4.468 0 011.33 6 4.468 4.468 0 016 1.33M6 0a5.811 5.811 0 00-6 6 5.811 5.811 0 006 6 5.811 5.811 0 006-6 5.811 5.811 0 00-6-6zM4.842 3.708a.33.33 0 00-.168.046.341.341 0 00-.174.3v3.89a.342.342 0 00.174.3.33.33 0 00.168.046.339.339 0 00.171-.047l3.316-1.951a.335.335 0 000-.584L5.013 3.755a.339.339 0 00-.171-.047z" }));
    }
    return null;
});
