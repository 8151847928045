"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.VirtualReality = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.012 11.706l-1.005-.091c-.725-3.268-3.382-4.488-6.521-4.901l-1.362-3.067H9.948l-1.43 3.066c-3.156.415-5.825 1.646-6.534 4.952l-.99.114-.006 3.397.947.086c.704 4.074 3.976 4.872 7.485 5.07l.351.019.215-.28A2.335 2.335 0 0112 19.049a2.256 2.256 0 011.957 1.02l.216.284.355-.023c4.36-.278 6.875-2 7.513-5.116l.966-.111zM10.077 6.562l.725-1.585h2.45l.691 1.585h-.088c-.613-.033-1.235-.046-1.855-.046s-1.242.013-1.855.046zm4.697 12.417A3.811 3.811 0 0012 17.719a3.976 3.976 0 00-2.833 1.264c-3.612-.254-5.34-1.258-5.865-3.597l.687.063v-4.015l-.602.07c.791-2.6 3.407-3.658 8.613-3.658 5.197 0 7.813 1.052 8.609 3.642l-.598-.054v4.015l.598-.07c-.645 2.154-2.529 3.325-5.835 3.6z" }),
            React.createElement("path", { d: "M9.387 14.479l-1.115-3.441-.025-.08H6.988v.186l1.718 4.816h1.349l1.71-4.777.007-.225h-1.258zM15.778 13.961a1.387 1.387 0 00.974-1.449c0-.709-.308-1.554-1.771-1.554h-2.13v5.002h1.206v-1.887h.434l1.278 1.887h1.243v-.264zm-1.721-1.959h.875c.369 0 .572.18.572.51 0 .333-.203.517-.572.517h-.875z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7.708 2.653l-.661-1.517H4.992L4.3 2.653C1.727 2.89 0 3.817 0 6.568c0 2.9 1.592 3.706 4.401 3.864.205-.496.926-.658 1.599-.658.673 0 1.372.135 1.563.657C9.528 10.306 12 9.684 12 6.568c0-2.748-1.724-3.675-4.292-3.915zm.417 6.388A3.369 3.369 0 006 8.444a3.418 3.418 0 00-2.158.61C1.728 8.836 1.33 8.161 1.33 6.568c0-1.505.344-2.656 4.67-2.656s4.67 1.15 4.67 2.656c0 1.278-.452 2.195-2.545 2.473z" }),
            React.createElement("path", { d: "M4.505 6.733L3.987 5.13h-.722v.115l.864 2.466h.746l.858-2.392.007-.189h-.722zM8.16 6.695a.746.746 0 00.447-.742c0-.53-.331-.823-.93-.823H6.568v2.581h.71v-.93h.125l.629.93h.702v-.208zm-.285-.742c0 .131-.075.197-.223.197H7.28V5.76h.372c.223 0 .223.145.223.192z" }));
    }
    return null;
});
