"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ExitFullscreen = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("g", { fill: "none", stroke: "#000", "stroke-width": "1.33" },
                React.createElement("path", { d: "M15 2v6.9c0 .1 0 .1.1.1H22M9 22v-6.9c0-.1 0-.1-.1-.1H2M9 2v6.9C9 9 9 9 8.9 9H2M15 22v-6.9c0-.1 0-.1.1-.1H22" })));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11 4.7H8.1c-.5 0-.8-.3-.8-.8V1h1.4v2.3H11zM4.7 11H3.3V8.7H1V7.4h2.9c.5 0 .8.3.8.8zM3.9 4.7H1V3.4h2.3V1h1.3v2.9c.1.5-.2.8-.7.8zM8.7 11H7.4V8.1c0-.5.3-.8.8-.8H11v1.3H8.7z" }));
    }
    return null;
});
