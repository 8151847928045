"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Size = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18.73 7.77l-.27-.25-.91 1 .26.25c1.11 1 2.07 2 2.72 2.6H3.5c.56-.56 1.47-1.45 3-2.85l-.91-1c-4.2 4-4.2 4.11-4.2 4.48s0 .51 4.2 4.48l.91-1c-1.5-1.36-2.44-2.25-3-2.81h17c-.77.76-1.89 1.85-2.95 2.85l.91 1c4.2-4 4.2-4.14 4.2-4.48 0-.53-.19-.73-3.93-4.27z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.68 3.09L8.69 4l1.2 1.35H2.11L3.31 4l-1-.89C.06 5.61.06 5.71.06 6s0 .4 2.26 2.91l1-.89-1.21-1.35h7.78L8.69 8l1 .89C11.94 6.4 11.94 6.3 11.94 6s0-.39-2.26-2.91z" }));
    }
    return null;
});
