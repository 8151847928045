"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CustomerCenter = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.61 21.71l-.91-5.32a2.42 2.42 0 00-1.28-1.68l-2.21-1.06a8.26 8.26 0 001.53-2.77 2.8 2.8 0 00-.08-5.12 8.09 8.09 0 00-15.73 2.4h1.33a6.77 6.77 0 118.83 6.67.69.69 0 00-.58-.33h-2a.7.7 0 00-.7.7v.91a.7.7 0 00.7.7h2a.69.69 0 00.68-.62 8.19 8.19 0 003-1.59l2.62 1.25a1.12 1.12 0 01.56.75l.92 5.3s-5.08.39-9.31.39c-4.86 0-9.26-.39-9.3-.39l.91-5.29a1.07 1.07 0 01.54-.74l3.44-1.65a7.15 7.15 0 01-1.1-.95l-2.92 1.45a2.42 2.42 0 00-1.25 1.69l-.91 5.28a1.33 1.33 0 00.25 1.06 1.29 1.29 0 00.94.51s4.5.4 9.42.4c4.28 0 9.36-.39 9.4-.4a1.31 1.31 0 001.21-1.55z" }),
            React.createElement("path", { d: "M12 13.65a5.09 5.09 0 005.29-5.29A5.11 5.11 0 0012 3.08a5.12 5.12 0 00-5.28 5.28A5.12 5.12 0 0012 13.65zm0-9.24a3.79 3.79 0 014 3.95 3.76 3.76 0 01-4 4 3.79 3.79 0 01-3.95-4A3.79 3.79 0 0112 4.41z" }));
    }
    return null;
});
