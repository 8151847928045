"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Menu = require("../../../volkswagen-onehub/icons-core/dist");

const helpers_1 = require("../helpers");
const theme_provider_1 = require("../theme-provider");
const StyledButton = theme_provider_1.styled.button.withConfig({ displayName: "StyledButton", componentId: "sc-kkikgl" }) `
	box-sizing: border-box;
	border: 0;
	margin: 0;
	background: none;

	width: ${props => props.theme.size.static450};
	height: ${props => props.theme.size.static450};
	padding: ${props => props.theme.size.static150};
	color: ${props => props.theme.interaction.recipe400.default};
	outline: none;
	&:hover,
	&:focus {
		color: ${props => props.theme.interaction.recipe400.hover};
	}
	&:focus {
		${props => helpers_1.createA11yOutlineStyles(props)}
	}
`;
function MenuButton(props) {
    const { onClick, label } = props;
    return (React.createElement(StyledButton, { onClick: onClick, "aria-label": label },
        React.createElement(Menu.Menu, null)));
}
exports.MenuButton = MenuButton;
