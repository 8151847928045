"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.FleetServicePrivate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.15 17.66a.84.84 0 101.68 0 .84.84 0 10-1.68 0zM22.05 5.23c.64-.09 1.28-.19 1.92-.31l-.24-1.31c-.73.14-1.47.25-2.21.36l-1.07-2.56A2.78 2.78 0 0018.28 0H9.7a2.81 2.81 0 00-2.18 1.4L6.44 4c-.73-.11-1.47-.22-2.2-.35L4 5c.64.12 1.28.22 1.92.31l-.46 1.03c-.07.12-.11.26-.17.39l.3-.05h1.18l.51-1.24a54.34 54.34 0 006.62.41 55 55 0 006.78-.43l.6 1.43v.09l.05.08a4.17 4.17 0 01.46 1.25c-1.21.18-2.45.33-3.72.43l.1 1.32c1.27-.1 2.5-.24 3.71-.42v5.1h-1.76a6.7 6.7 0 01.36 1.3h1.41a1.4 1.4 0 001.36-1.43v-5.5a5.4 5.4 0 00-.74-2.73zM7.82 4.16L8.71 2a1.47 1.47 0 011-.68h8.45a1.47 1.47 0 011.11.68l.88 2.12a53.52 53.52 0 01-12.33.04z" }),
            React.createElement("path", { d: "M19.73 11.61c-.73.14-1.47.26-2.21.36l-1.07-2.56A2.78 2.78 0 0014.28 8H5.7a2.81 2.81 0 00-2.18 1.4L2.44 12c-.73-.11-1.47-.22-2.2-.35L0 13c.64.12 1.28.22 1.92.31l-.46 1.08a5.39 5.39 0 00-.73 2.73v5.5A1.4 1.4 0 002.09 24h1.66a1.41 1.41 0 001.37-1.44v-.43h9.74v.44A1.39 1.39 0 0016.21 24h1.68a1.4 1.4 0 001.36-1.43v-5.5a5.4 5.4 0 00-.74-2.73l-.46-1.11c.64-.09 1.28-.19 1.92-.31zM4.71 10a1.47 1.47 0 011-.68h8.45a1.47 1.47 0 011.06.68l.88 2.12a53.52 53.52 0 01-12.33 0zm12.57 4.83v.09l.05.08a4.17 4.17 0 01.46 1.25c-1.21.18-2.45.33-3.72.43l.1 1.32c1.27-.1 2.5-.24 3.71-.42v5.1h-1.65V20.8H3.79v1.76a.16.16 0 010 .11H2.1v-5.04c1.23.17 2.5.32 3.79.41L6 16.72c-1.3-.1-2.57-.25-3.81-.42a4.1 4.1 0 01.42-1.3l.05-.08v-.09l.59-1.41a54.34 54.34 0 006.62.41 55 55 0 006.78-.43z" }));
    }
    return null;
});
