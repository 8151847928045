"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Seat = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M16.44 22.51H3L1.43 17.9a1.71 1.71 0 01.08-1.31 1.61 1.61 0 011-.81 18.73 18.73 0 014.17 0c1.87.08 4.13.17 5.71.06A2.86 2.86 0 0113 13.9a12.27 12.27 0 002.68-5.11 18.52 18.52 0 00.87-5.61 1.64 1.64 0 011.66-1.69h4.37v.63a34.57 34.57 0 01-1 10 25.11 25.11 0 01-5 10.18zM3.91 21.18H15.8a23.72 23.72 0 004.53-9.39 34.22 34.22 0 001-9h-3.1a.32.32 0 00-.24.09.35.35 0 00-.09.25 20 20 0 01-.92 6A13.6 13.6 0 0114 14.76c-.24.29-.35.53-.35 1.65V17l-.59.07a50 50 0 01-6.49 0 26.84 26.84 0 00-3.73 0 .25.25 0 00-.16.14.34.34 0 000 .28z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.37 11.55H1.26L.43 9a1.18 1.18 0 01.06-.9 1.13 1.13 0 01.68-.57 9.26 9.26 0 012.39 0c.76 0 1.61.06 2.17 0a3.1 3.1 0 01.53-.84 5.53 5.53 0 001.25-2.38 8.8 8.8 0 00.42-2.69 1.12 1.12 0 01.33-.83 1.16 1.16 0 01.83-.34h2.48v.63a17.9 17.9 0 01-.5 5 12.87 12.87 0 01-2.5 5.22zm-6.15-1.33h5.5a11.43 11.43 0 002.09-4.4 16.88 16.88 0 00.49-4h-1a10.63 10.63 0 01-.47 2.9A6.81 6.81 0 017.25 7.6a2.51 2.51 0 00-.43.81l-.13.39h-.41a21 21 0 01-2.77 0H1.76z" }));
    }
    return null;
});
