"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Heart = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.121 4.416A4.468 4.468 0 019.3 5.74l1.802 1.801.963.963.94-.985 1.697-1.78a4.473 4.473 0 015.17-.851 3.925 3.925 0 011.94 2.346 3.803 3.803 0 01-.212 2.937c-1.592 2.94-7.254 9.413-9.557 9.413-2.383 0-8.213-6.776-9.641-9.413a3.803 3.803 0 01-.213-2.937 3.925 3.925 0 011.94-2.346 4.48 4.48 0 011.993-.47m11.758-1.33a5.813 5.813 0 00-4.119 1.711L12.042 6.6 10.24 4.798a5.803 5.803 0 00-6.707-1.1 5.349 5.349 0 00-2.302 7.106c1.301 2.402 7.474 10.11 10.81 10.11 3.325 0 9.427-7.708 10.728-10.11a5.349 5.349 0 00-2.302-7.107 5.782 5.782 0 00-2.588-.61z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.91 2.917a1.542 1.542 0 01.687.162 1.27 1.27 0 01.62.763 1.228 1.228 0 01-.055.932c-.987 1.823-3.51 4.224-4.124 4.31-.825-.105-3.424-2.877-4.2-4.31a1.228 1.228 0 01-.055-.932 1.27 1.27 0 01.62-.763 1.543 1.543 0 011.785.296l.892.892.963.963.94-.986.829-.87a1.545 1.545 0 011.099-.457m0-1.33a2.878 2.878 0 00-2.04.847l-.85.892-.892-.892a2.873 2.873 0 00-3.32-.545 2.648 2.648 0 00-1.14 3.519c.644 1.19 3.7 5.005 5.352 5.005 1.646 0 4.666-3.816 5.31-5.005a2.648 2.648 0 00-1.14-3.519 2.863 2.863 0 00-1.28-.302z" }));
    }
    return null;
});
