"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.BusinessCustomersCommercial = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M13.03 16.116a1 1 0 11-2 .001 1 1 0 012 0zm7.67-1.51l1.33-.206v7.316a1.62 1.62 0 01-1.61 1.62h-3a1.62 1.62 0 01-1.59-1.62v-.5H8.27v.48a1.64 1.64 0 01-1.63 1.64h-3a1.62 1.62 0 01-1.61-1.62v-10.28l.27-1.6-2-.16v-1.34h.06c.71.11 1.42.16 2.13.21l.26-1.67a6.745 6.745 0 011.39-3.18l1.56-1.9a1.24 1.24 0 011-.46h2.203v1.34H6.7l-1.51 1.87a5.403 5.403 0 00-1.15 2.55l-.24 1.54c1.694.104 3.38.175 5.103.195v1.344a121.873 121.873 0 01-5.312-.209l-.25 1.58v3.09c1.559.22 3.179.4 4.859.52l-.09 1.34a68.805 68.805 0 01-4.77-.51v5.73a.289.289 0 00.28.28h3a.31.31 0 00.3-.3v-1.82h10.22v1.84a.289.289 0 00.28.28h3a.289.289 0 00.28-.28v-5.76c-1.51.22-3.07.4-4.66.51l-.1-1.33c1.62-.12 3.22-.3 4.76-.53zm1.614-2.6v-4.41l-4.093.511c-.024.003-.05.001-.074.004v.247a1 1 0 01-2 0v-.247c-.024-.003-.05 0-.074-.004l-4.093-.512v4.411zM11.98 4.666v1.59l4.257.531a7.438 7.438 0 001.82 0l4.257-.532V4.666zm4.67-2.436c-.705 0-1.27.491-1.316 1.106h3.634c-.047-.615-.611-1.106-1.317-1.106zm5.993 1.106a1 1 0 011 1v8a1 1 0 01-1 1H11.65a1 1 0 01-1-1v-8a1 1 0 011-1h2.344c.027-.83.476-1.56 1.16-2A2.758 2.758 0 0116.65.9h1c.555 0 1.07.161 1.497.436.684.44 1.133 1.17 1.16 2z" }));
    }
    return null;
});
