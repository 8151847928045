"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.NewCarCommercial = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "12", cy: "15.61", r: "1" }),
            React.createElement("path", { d: "M23.78 9.21l-.12-1.33-2.16.18-.23-1.4A6.78 6.78 0 0020 3.74L18.36 1.5a1.25 1.25 0 00-1-.5H6.64a1.25 1.25 0 00-1 .46l-1.51 1.9a6.74 6.74 0 00-1.41 3.18l-.24 1.54-2.13-.16H.29l-.07.66v.67l2.05.16L2 11.1v10.28A1.62 1.62 0 003.61 23h3a1.64 1.64 0 001.62-1.64v-.48h7.56v.5A1.63 1.63 0 0017.4 23h3a1.62 1.62 0 001.6-1.62V11.1l-.28-1.73zM4 6.75A5.37 5.37 0 015.17 4.2l1.51-1.87h10.64L19 4.53a5.47 5.47 0 011 2.35l.21 1.27a133.29 133.29 0 01-16.36 0zm16.64 4.46v2.89c-1.54.22-3.13.4-4.76.52l.1 1.32c1.59-.12 3.15-.29 4.66-.5v5.94a.29.29 0 01-.28.29h-3a.29.29 0 01-.29-.29v-1.83H6.89v1.81a.31.31 0 01-.29.31h-3a.29.29 0 01-.28-.29v-5.92a64 64 0 004.77.5l.09-1.32c-1.67-.12-3.3-.3-4.86-.52v-2.91l.28-1.72q4.1.26 8.21.26c2.86 0 5.72-.1 8.57-.28z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M48 17.43h-4.11c0-.12 0-.24-.08-.36l-3.09-12A3 3 0 0038 2.86l-6.79-.31c-4.75-.22-9.57-.22-14.32 0l-6.79.31a3 3 0 00-2.82 2.25l-3.09 12c0 .12-.05.24-.08.36H0v2.5l3.32 1A68.16 68.16 0 002 34.17V45a3 3 0 003 3h4a3 3 0 003-3v-1.23a212.79 212.79 0 0024 0V45a3 3 0 003 3h4a3 3 0 003-3V34.17A68.16 68.16 0 0044.68 21L48 20zm-4 15.33l-7.08.38.65-3.56 6.2-1.18c.07 1.45.15 2.91.23 4.36zm-7.38-5h-25l-7-1.34c.2-1.67.45-3.32.77-5h37.35c.32 1.65.58 3.32.77 5zM25 30.73a1 1 0 11-1-1 1 1 0 011 1zM4.28 28.38l6.31 1.2.65 3.56-7.19-.38c.03-1.46.11-2.92.23-4.38zm5.86-23.52l6.79-.32c4.69-.21 9.45-.21 14.14 0l6.79.32a1 1 0 01.92.75l.38 1.47a264.38 264.38 0 00-30.32 0l.38-1.47a1 1 0 01.92-.75zM8.32 9.11a264.6 264.6 0 0131.36 0l2.19 8.46c.16.62.3 1.23.44 1.85H5.69c.14-.62.28-1.23.44-1.85zM43 46h-4a1 1 0 01-1-1v-3.35l-1.06.06a210.17 210.17 0 01-25.84 0l-1.1-.06V45a1 1 0 01-1 1H5a1 1 0 01-1-1V34.76l9.64.51-1-5.54h8.53a3 3 0 105.64 0h8.64l-1 5.54 9.55-.51V45a1 1 0 01-1 1z" }));
    }
    return null;
});
