"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Logo = require("../../../volkswagen-onehub/icons-core/dist");

const helpers_1 = require("../helpers");
const theme_provider_1 = require("../theme-provider");
const StyledWrapper = theme_provider_1.styled.span.withConfig({ displayName: "StyledWrapper", componentId: "sc-1r6xu3a" }) `
	display: grid;
	align-items: center;
	width: 100%;

	/* the logo size is defined explicitly for each of the following breakpoints */
	/* the margin size is defined to be 1/4 of the logo size */
	--logo-size: ${props => props.theme.size.static400};
	--margin-size: ${props => parseInt(props.theme.size.static400, 10) / 4}px;

	@media (min-width: ${helpers_1.Breakpoints.b560}px) {
		--logo-size: ${props => props.theme.size.static450};
		--margin-size: ${props => parseInt(props.theme.size.static450, 10) / 4}px;
	}

	@media (min-width: ${helpers_1.Breakpoints.b960}px) {
		--logo-size: ${props => props.theme.size.static510};
		--margin-size: ${props => parseInt(props.theme.size.static510, 10) / 4}px;
	}

	@media (min-width: ${helpers_1.Breakpoints.b1920}px) {
		--logo-size: ${props => props.theme.size.static520};
		--margin-size: ${props => parseInt(props.theme.size.static520, 10) / 4}px;
	}

	/* the logo is centered up to b960 */
	/* if there is a claim, the left size needs to be calculated, so the logo remains centered */
	grid-template-columns: ${props => props.hasClaim
    ? `calc(50vw - ${parseInt(props.theme.size.static400, 10) /
        2}px - var(--margin-size))
		max-content max-content auto`
    : 'auto max-content auto'};
	grid-column-gap: var(--margin-size);

	@media (min-width: ${helpers_1.Breakpoints.b560}px) {
		grid-template-columns: ${props => props.hasClaim
    ? `calc(50vw - ${parseInt(props.theme.size.static450, 10) /
        2}px - var(--margin-size))
			max-content max-content auto`
    : `auto max-content auto`};
	}

	/* the logo is aligned on grid column 16/24 from b960 on */
	@media (min-width: ${helpers_1.Breakpoints.b960}px) {
		grid-template-columns:
			calc(${props => props.theme.size.grid016} - var(--margin-size))
			max-content ${props => (props.hasClaim ? 'max-content ' : '')}auto;
	}

	/* the moving frame grows beyond b2560, so we need to add half of that space to the left column */
	/* (b2560 * 16/24) + (50vw - b2560 / 2) => b2560 / 6 + 50vw */
	@media (min-width: ${helpers_1.Breakpoints.b2560}px) {
		grid-template-columns:
			calc(${helpers_1.Breakpoints.b2560 / 6}px + 50vw - var(--margin-size))
			max-content ${props => (props.hasClaim ? 'max-content ' : '')}auto;
	}

	::before,
	::after {
		content: '';
		height: 2px;
		background: ${props => props.theme.interaction.recipe450.default};
	}

	svg {
		fill: ${props => props.theme.interaction.recipe450.default};
		width: var(--logo-size);
		height: var(--logo-size);
	}
`;
exports.MovingFrame = (props) => {
    const { claim, logo } = props;
    return (React.createElement(StyledWrapper, { hasClaim: Boolean(claim) },
        logo || React.createElement(Logo.Logo, { variant: "default" }),
        claim));
};
