"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ConfiguratorCommercial = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "12", cy: "15.78", r: "1" }),
            React.createElement("path", { d: "M20.67 12.78v1.49c-1.54.23-3.14.41-4.76.53l.1 1.33c1.59-.11 3.15-.29 4.66-.51v5.76a.29.29 0 01-.28.28h-3a.29.29 0 01-.28-.28v-1.84H6.89v1.82a.31.31 0 01-.3.3h-3a.29.29 0 01-.28-.28v-5.73q2.3.33 4.77.51l.09-1.34c-1.68-.12-3.3-.3-4.86-.52v-3.09l.25-1.58c2 .13 4.05.19 6.09.23V8.51c-2 0-3.93-.09-5.88-.21L4 6.76a5.41 5.41 0 011.16-2.55l1.51-1.87h3V1h-3a1.25 1.25 0 00-1 .46l-1.54 1.9a6.74 6.74 0 00-1.41 3.18l-.26 1.67C1.75 8.16 1 8.11.33 8H.27L.22 9.38l2 .16L2 11.1v10.28A1.62 1.62 0 003.61 23h3a1.64 1.64 0 001.63-1.64v-.48h7.56v.5A1.62 1.62 0 0017.39 23h3A1.62 1.62 0 0022 21.38v-8.6z" }),
            React.createElement("path", { d: "M22.67 1.33v8.34h-8.34V1.33zM24 0H13v11h11z" }),
            React.createElement("path", { d: "M17.6 8.41l-2.47-2.47.94-.94 1.53 1.53L21.13 3l.94.94z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M44.52 25.52l-7 1.32h-25l-7-1.34c.2-1.66.45-3.32.78-5h14v-2H6.7c.14-.62.29-1.24.44-1.85l2.19-8.41c3.65-.22 7.32-.36 11-.43v-2c-3.49.06-7 .16-10.47.36l.38-1.47a1 1 0 01.91-.7L18 3.67c.79 0 1.58-.06 2.37-.08v-2c-.82 0-1.65 0-2.47.08L11.06 2A3 3 0 008.3 4.23l-3.09 12-.09.35H1v2.51l3.32 1A68 68 0 003 33.29v10.84a3 3 0 003 3h4a3 3 0 003-3V42.9a213 213 0 0024 0v1.23a3 3 0 003 3h4a3 3 0 003-3V33.29a67.14 67.14 0 00-.52-8.14zm-6 3.19l6.2-1.18c.13 1.45.2 2.9.24 4.36l-7.09.37zM25 28.85a1 1 0 11-1 1 1 1 0 011-1zM5.3 27.51l6.3 1.2.65 3.55-7.19-.38c.03-1.46.11-2.88.24-4.37zM45 44.13a1 1 0 01-1 1h-4a1 1 0 01-1-1v-3.36l-1.07.07a210.17 210.17 0 01-25.84 0L11 40.77v3.36a1 1 0 01-1 1H6a1 1 0 01-1-1V33.88l9.63.52-1-5.55h8.57a2.82 2.82 0 00-.19 1 3 3 0 006 0 3.05 3.05 0 00-.18-1h8.64l-1 5.55 9.53-.51z" }),
            React.createElement("path", { d: "M44.86 2.87v15h-15v-15zm2-2h-19v19h19v-19z" }),
            React.createElement("path", { d: "M35.8 15.18l-4.16-4.16 1.41-1.41 2.75 2.75L42 6.15l1.41 1.42z" }));
    }
    return null;
});
