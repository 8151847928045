"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ArrowDown = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.298 15.116c-1.885 2.105-3.6 3.812-4.634 4.782V2.003h-1.33V19.88a88.166 88.166 0 01-4.583-4.764l-.99.887c.054.06 5.384 6 6.238 6 .876 0 6.236-5.94 6.29-6z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.618 7.143A44.65 44.65 0 016.72 9.134V.43H5.39v8.69a48.593 48.593 0 01-1.871-1.977l-.99.887C5.458 11.3 5.891 11.3 6.054 11.3c.515 0 2.217-1.78 3.554-3.271z" }));
    }
    return null;
});
