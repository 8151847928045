"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CaliforniaSpecialist = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.8 17.55a3.76 3.76 0 00-.1-.66 5.76 5.76 0 00-.18-.59v-.05a4.41 4.41 0 00-.24-.54 4.28 4.28 0 00-1.54-1.62l-.85-.51a3.23 3.23 0 01-.72-.57l-3.2-3.25a2.75 2.75 0 00-1-.66l.31-6.3 1.62-.6-.42-1.26-16 5.24.41 1.26.63-.21.38 1.61h-.08a1.46 1.46 0 00-1.38 1.22l-.63 3.08a30.14 30.14 0 00-.5 3.43l-.22 2.64a1 1 0 00.75 1.12l2 .48a2.35 2.35 0 004.69 0h9.14a2.35 2.35 0 004.7 0h.16l1.5-.43a1.28 1.28 0 00.88-1.38zm-7.8-6.9l2.88 2.93-2.61.07h-2.81l-.34-3.47H15a1.4 1.4 0 011 .47zM14.86 3.2l-.29 5.69-10.31-.05-.44-2zM2.74 10.33a.21.21 0 01.1-.16h8.93l.34 3.46h-2.08l-7.92-.24zm2.44 11.34a1 1 0 111-1 1 1 0 01-1 1zm13.84 0a1 1 0 110-2.07 1 1 0 010 2.07zm2.32-2.22h-.29a2.35 2.35 0 00-4.08 0H7.24a2.34 2.34 0 00-4.12 0l-1.69-.4.21-2.43c.05-.65.14-1.31.24-2L10 15c1.15 0 2.31.06 3.46.06h2.85l4.18-.12.55.33a2.71 2.71 0 01.46.36 2.37 2.37 0 01.29.34s.09.1.13.16a3.2 3.2 0 01.56 1.6l.06.83v.62z" }));
    }
    return null;
});
