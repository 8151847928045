"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Close = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.474 3.467l-.942-.941L12 11.059 3.466 2.526l-.94.941L11.059 12l-8.533 8.532.94.942L12 12.941l8.532 8.533.942-.942L12.941 12z" }));
    }
    if (variant === "fab") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "#ffffff", role: "img" },
            React.createElement("path", { d: "M21.474 3.467l-.942-.941L12 11.059 3.466 2.526l-.94.941L11.059 12l-8.533 8.532.94.942L12 12.941l8.532 8.533.942-.942L12.941 12z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "#001e50", role: "img" },
            React.createElement("path", { d: "M10.466 2.473l-.94-.94L6 5.06 2.474 1.533l-.941.94L5.06 6 1.533 9.527l.941.94L6 6.94l3.526 3.527.94-.94L6.94 6z" }));
    }
    if (variant === "smallBlack") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "#000000", role: "img" },
            React.createElement("path", { d: "M10.466 2.473l-.94-.94L6 5.06 2.474 1.533l-.941.94L5.06 6 1.533 9.527l.941.94L6 6.94l3.526 3.527.94-.94L6.94 6z" }));
    }

    if (variant === "smallBlue") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "#00437a", role: "img" },
            React.createElement("path", { d: "M10.466 2.473l-.94-.94L6 5.06 2.474 1.533l-.941.94L5.06 6 1.533 9.527l.941.94L6 6.94l3.526 3.527.94-.94L6.94 6z" }));
    }
    return null;
});
