"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Corporate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.307 6.114h-3.954a4.177 4.177 0 00-1.965-3.377A4.682 4.682 0 0012.853 2H11.16a4.682 4.682 0 00-2.535.736 4.178 4.178 0 00-1.964 3.377H2.693A1.69 1.69 0 001 7.8v13.511A1.691 1.691 0 002.693 23h18.614A1.691 1.691 0 0023 21.311V7.8a1.69 1.69 0 00-1.693-1.686zM9.344 3.855a3.356 3.356 0 011.816-.525h1.693a3.357 3.357 0 011.818.526 2.826 2.826 0 011.345 2.257H8a2.832 2.832 0 011.344-2.258zM2.693 7.444h18.614a.361.361 0 01.363.358v3.531l-9.576 1.915-9.764-1.954V7.8a.361.361 0 01.363-.356zM21.307 21.67H2.693a.361.361 0 01-.363-.359v-8.66l7.988 1.6v1.626a1.19 1.19 0 001.19 1.189h.984a1.19 1.19 0 001.19-1.189v-1.591l7.988-1.6v8.621a.361.361 0 01-.363.363z" }));
    }
    return null;
});
