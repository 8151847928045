"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CaliforniaService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M2.3 10.6a2.2 2.2 0 01-1.565-.648l-.089-.089a2.213 2.213 0 010-3.128l2.03-2.03a4.383 4.383 0 01-.061-.723A3.848 3.848 0 016.6 0a3.86 3.86 0 013.547 2.114l.157.313L9.5 3.88l.818 1.454-.157.487a3.924 3.924 0 01-4.248 2.084L3.864 9.952A2.2 2.2 0 012.3 10.6zm4.3-9.267a2.534 2.534 0 00-2.652 2.649 2.968 2.968 0 00.1.767l.1.369L1.59 7.676a.88.88 0 000 1.248l.089.09a.907.907 0 001.246 0l2.566-2.568.366.091a2.689 2.689 0 002.994-1.095l-.158-.279H6.938L5.768 4.04l1.119-1.393H8.66l.124-.224A2.588 2.588 0 006.6 1.333zm17.2 16.216a4.725 4.725 0 00-.1-.655v-.015a4.676 4.676 0 00-.173-.588l-.02-.053a4.55 4.55 0 00-.246-.54l-.012-.024a4.229 4.229 0 00-1.536-1.626l-.851-.508a3.575 3.575 0 01-.719-.565l-3.208-3.256a2.786 2.786 0 00-1.04-.66l.319-6.3L17.9 2.2 17.481.94l-5.764 1.89v1.4l3.144-1.03-.289 5.689-6.158-.019v1.33l3.358.005.339 3.463c-.7-.009-1.392-.014-2.085-.034L2.113 13.4l.295-1.437H1.05L.808 13.14a29.461 29.461 0 00-.5 3.427l-.22 2.642a1.046 1.046 0 00.756 1.124l1.992.476a2.353 2.353 0 004.694.015l9.138-.029a2.354 2.354 0 004.7-.014h.166l1.5-.43A1.287 1.287 0 0023.912 19zM15 10.218a1.4 1.4 0 01.986.434l2.881 2.926-2.607.072c-.931.024-1.872.031-2.813.031l-.34-3.47zM5.184 21.67a1.036 1.036 0 111.036-1.036 1.037 1.037 0 01-1.036 1.036zm13.832 0a1.036 1.036 0 111.037-1.036 1.037 1.037 0 01-1.037 1.036zm2.328-2.219h-.29a2.345 2.345 0 00-4.081.013l-9.729.036a2.345 2.345 0 00-4.128.013l-1.685-.4.207-2.425c.056-.655.142-1.309.244-1.961l8.1.245a117.615 117.615 0 006.311.016l4.187-.115.546.326a2.746 2.746 0 01.458.361 3.036 3.036 0 01.3.334c.041.053.085.1.123.16a3.225 3.225 0 01.565 1.6l.06.832h.005l.045.619z" }));
    }
    return null;
});
