"use strict";
/* eslint-disable-next-line import/default */
Object.defineProperty(exports, "__esModule", { value: true });
const logger_1 = require("../helpers/logger");
const deprecations_1 = require("./deprecations");
const get_design_token_value_1 = require("./get-design-token-value");
const example_theme_definitions_1 = require("./example-theme-definitions");
/**
 * TypeGuard for type `ThemeName`
 *
 * @param prop Some arbitrary component property
 */
function isThemeName(prop) {
    return typeof prop === 'string';
}
function warnIfDeprecated(path) {
    deprecations_1.deprecations.forEach(deprecation => {
        if (path.match(deprecation.condition)) {
            logger_1.warnWithTrace(deprecation.message);
        }
    });
}
function addProxy(targetObject, getFallbackTheme = () => undefined, path) {
    return new Proxy(targetObject, {
        get: (target, key) => {
            if (typeof key !== 'string') {
                return undefined;
            }
            if (Object.hasOwnProperty.call(target, key)) {
                warnIfDeprecated(path ? `${path}.${key}` : `${key}`);
                return target[key];
            }
            const fallbackTheme = getFallbackTheme();
            return fallbackTheme && fallbackTheme[key];
        }
    });
}
function recursivelyAddProxies(targetObject, getFallbackTheme = () => undefined, path) {
    const result = {};
    Object.entries(targetObject).forEach(([key, value]) => {
        if (typeof value === 'object' && value) {
            result[key] = recursivelyAddProxies(value, () => {
                const fallbackTheme = getFallbackTheme();
                return fallbackTheme && fallbackTheme[key];
            }, path ? `${path}.${key}` : key);
        }
        else {
            result[key] = value;
        }
    });
    return addProxy(result, getFallbackTheme, path);
}
function resolveDesignTokenValues(theme, optionsOverride) {
    const resolvedTheme = {};
    Object.entries(theme).forEach(([key, value]) => {
        if (value === null || value === undefined) {
            return;
        }
        if (typeof value === 'object') {
            resolvedTheme[key] =
                resolveDesignTokenValues(value, optionsOverride) || {};
            return;
        }
        resolvedTheme[key] = get_design_token_value_1.getDesignTokenValue(value, optionsOverride);
    });
    return resolvedTheme;
}
function getTheme(currentTheme, newTheme, direction, optionsOverride) {
    const optionsThemeSet = optionsOverride && optionsOverride.themeSet;
    const themeSet = optionsThemeSet || example_theme_definitions_1.themeSet;
    const parentTheme = currentTheme && Object.keys(currentTheme).length > 0
        ? currentTheme
        : recursivelyAddProxies(resolveDesignTokenValues(themeSet.main, optionsOverride));
    if (!newTheme) {
        return direction ? Object.assign(Object.assign({}, parentTheme), { direction }) : parentTheme;
    }
    const updatedTheme = resolveDesignTokenValues(isThemeName(newTheme) ? themeSet[newTheme] : newTheme, optionsOverride);
    return recursivelyAddProxies(Object.assign(Object.assign({}, updatedTheme), { direction }), () => parentTheme);
}
exports.getTheme = getTheme;
