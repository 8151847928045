"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.BusinessCustomersPrivate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.323 16.685a.944.944 0 11-1.889 0 .944.944 0 011.889 0zm7.896-1.433c.498-.066 1.33-.172 1.33-.172.129.428.211.776.211.98v5.843c0 .836-.685 1.52-1.521 1.52h-1.882a1.52 1.52 0 01-1.521-1.52v-.464H5.922v.453c0 .842-.689 1.53-1.532 1.53H2.518c-.84 0-1.52-.68-1.52-1.52V16.06c0-.594.663-2.36 1.374-4.081a67.84 67.84 0 01-2.173-.328l.227-1.311c.825.143 1.651.264 2.479.373.627-1.465 1.169-2.65 1.169-2.65.436-.886 1.166-1.627 2.601-1.627h1.953v1.33H6.675c-.709 0-1.091.24-1.407.884-.327.715-.664 1.473-.987 2.222a63.36 63.36 0 004.347.313v1.353a67.34 67.34 0 01-4.889-.388c-.52 1.248-.958 2.376-1.204 3.128a65.69 65.69 0 004.309.45l-.092 1.327a68.486 68.486 0 01-4.425-.462v5.31c0 .105.086.19.191.19H4.39a.207.207 0 00.202-.201V20.109h13.574v1.794c0 .105.085.19.191.19h1.882a.196.196 0 00.191-.19v-5.337a66.94 66.94 0 01-4.324.467l-.098-1.326a65.637 65.637 0 004.211-.455zm1.82-3.146V7.695l-3.33.416-.764.095c-.024.004-.049.002-.073.005v.247a.977.977 0 01-.205.577.983.983 0 01-.795.423 1 1 0 01-1-1v-.247c-.024-.003-.05 0-.074-.005l-3.524-.44-.569-.07v4.41h8.736zm-10.334-7.34v1.59l.647.08 3.61.451a7.335 7.335 0 001.752.004c.022-.002.046 0 .067-.004l4.257-.532V4.766zm4.67-2.436c-.705 0-1.27.491-1.317 1.106h3.635c-.047-.615-.612-1.106-1.318-1.106zm5.993 1.106a1 1 0 011 1v8a1 1 0 01-1 1H11.375a.987.987 0 01-.969-.848c-.008-.052-.03-.098-.03-.152v-8a1 1 0 011-1h2.343c.026-.83.476-1.56 1.16-2A2.758 2.758 0 0116.374 1h1c.555 0 1.07.161 1.498.436.683.44 1.132 1.17 1.16 2z" }));
    }
    return null;
});
