//STYLED
import { createGlobalStyle } from "styled-components";

//FONTS
import VwHeadRegular from '../globals/vwhead-regular.woff2';
import VwHeadBold from '../globals/vwhead-bold.woff2';
import VwHeadRegularItalic from '../globals/vwhead-regularitalic.woff2';
import VwHeadBoldItalic from '../globals/vwhead-bolditalic.woff2';
import VwHeadExtraBold from '../globals/vwhead-extrabold.woff2';
import VwHeadLight from '../globals/vwhead-light.woff2';
import VwTextRegular from '../globals/vwtext-regular.woff2';
import VwTextRegularItalic from '../globals/vwtext-regularitalic.woff2';
import VwTextBold from '../globals/vwtext-bold.woff2';
import VwTextBoldItalic from '../globals/vwtext-bolditalic.woff2';
import VwTextLight from '../globals/vwtext-light.woff2';

const globalStyle = createGlobalStyle `

    @font-face {
		font-family: 'vw-head';
		font-weight: 400;
		src: url(${VwHeadRegular}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-head';
		font-weight: 400;
		font-style: italic;
		src: url(${VwHeadRegularItalic}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-head';
		font-weight: 700;
		src: url(${VwHeadBold}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-head';
		font-weight: 700;
		font-style: italic;
		src: url(${VwHeadBoldItalic}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-head';
		font-weight: 800;
		src: url(${VwHeadExtraBold}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-head';
		font-weight: 200;
		src: url(${VwHeadLight}) format('woff2');
		font-display: swap;
	}

	@font-face {
		font-family: 'vw-text';
		font-weight: 400;
		src: url(${VwTextRegular}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-text';
		font-weight: 400;
		font-style: italic;
		src: url(${VwTextRegularItalic}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-text';
		font-weight: 700;
		src: url(${VwTextBold}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-text';
		font-weight: 700;
		font-style: italic;
		src: url(${VwTextBoldItalic}) format('woff2');
		font-display: swap;
	}
	@font-face {
		font-family: 'vw-text';
		font-weight: 200;
		src: url(${VwTextLight}) format('woff2');
		font-display: swap;
	}
	.no-js {
		opacity: 0;
	}
`;

export default globalStyle;