"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Electric = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8 9.83h8v1.33H8z" }),
            React.createElement("path", { d: "M20.5 6.33h-3.83V1.18h-1.34v5.15H8.67V1.18H7.33v5.15H3.5v1.33h1.33v6.65a5.21 5.21 0 005 5.36h1.47V24h1.34v-4.33h1.47a5.21 5.21 0 005-5.36V7.66h1.39zm-2.67 8a3.86 3.86 0 01-3.69 4H9.86a3.86 3.86 0 01-3.69-4V7.66h11.66z" }),
            React.createElement("path", { d: "M9 12.83h6v1.33H9z" }));
    }
    return null;
});
