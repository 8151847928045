"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Mail = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.08 4H1.92a.92.92 0 00-.92.92v14.16a.92.92 0 00.92.92h20.16a.92.92 0 00.92-.92V4.92a.92.92 0 00-.92-.92zm-1.35 1.33l-7.51 7.5h-2.44l-7.51-7.5zM2.33 18.67V6.27l7.89 7.9h3.56l7.89-7.9v12.4z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.9 1.25H1.1A1.08 1.08 0 000 2.31v7.38a1.08 1.08 0 001.1 1.06h9.8A1.08 1.08 0 0012 9.69V2.31a1.08 1.08 0 00-1.1-1.06zm-.54 1.33L6.42 5.84h-.84L1.64 2.58zm-9 6.84V4.05L5.1 7.17h1.8l3.77-3.12v5.37z" }));
    }
    return null;
});
