import React from "react";

//COMPONENTS-CORE
import { ThemeProvider } from "../../../../components-core/packages/components-core/dist/theme-provider/index";
import styled from "styled-components";

//LAYOUT
import {
  Container,
  ContainerGutter,
  ContainerWrap,
  ContainerPadding,
  ContainerVerticalAlignment,
  ContainerHorizontalAlignment,
} from "../../../../components-core/packages/components-core/dist/container/index";
import { Layout } from "../../../../components-core/packages/components-core/dist/layout/index";
import { Breakpoints } from "../../../../components-core/packages/components-core/dist/helpers";
import {
  NavigationLevelList,
  NavigationLevelListItem,
} from "../../../../components-core/packages/components-core/dist/navigation-level-list/index";

//STYLE
import { Image } from "../../../../components-core/packages/components-core/dist/image/index";
import {
  Text,
  TextAppearance,
} from "../../../../components-core/packages/components-core/dist/text";
import {
  Link,
  LinkButton,
} from "../../../../components-core/packages/components-core/dist/link/index";

//ICONS CORE
import { ArrowLeft } from "../../../../components-core/packages/icons-core/dist/ArrowLeft/index";
import { Close } from "../../../../components-core/packages/icons-core/dist/Close";

const TopContainer = styled.div`
  height: 100vh;
  width: 100vw;

  margin-left: ${(props) => (props.isOpen ? "0" : "100%")};
  transition: margin-left 0.3s ease;

  z-index: 21;
  position: fixed;
`;

const WhiteDiv = styled.div`
  background: #fff;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;

const Line = styled.div`
  height: 70vh;
  width: 2px;
  background-color: #96a3a8;

  @media (max-width: 960px) {
    display: none;
  }
`;

class SideBarLevelFour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIdItem: null,
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoaded: true });
  }

  onClick() {
    document.body.style.overflowY = "auto";
    this.props.onCloseClick();
  }

  onQuitClick() {
    document.body.style.overflowY = "auto";
    this.props.onQuitClick();
  }

  renderQuitButton() {
    return (
      <LinkButton onClick={() => this.onQuitClick()}>
        <Container verticalAlign={ContainerVerticalAlignment.center}>
          <div style={{ marginTop: 20, marginLeft: 13 }}>
            <Close variant="small" />
          </div>
        </Container>
      </LinkButton>
    );
  }

  renderCloseButton() {
    return (
      <Container
        padding={{
          top: ContainerPadding.dynamic0100,
          bottom: ContainerPadding.dynamic0150,
        }}
      >
        <LinkButton onClick={() => this.onClick()}>
          <Container verticalAlign={ContainerVerticalAlignment.center}>
            <ArrowLeft />
            <Text>Geri Dön</Text>
          </Container>
        </LinkButton>
      </Container>
    );
  }

  renderTitle() {
    return (
      <Container
        padding={{
          bottom: ContainerPadding.dynamic0150,
          top: ContainerPadding.dynamic0040,
        }}
      >
        <Text appearance={TextAppearance.headline0350} bold>
          Araç Karşılaştırma
        </Text>
      </Container>
    );
  }

  renderLeftContainer() {
    return (
      <Container wrap={ContainerWrap.always}>
        {this.renderTitle()}

        <NavigationLevelList>
          {menuItems.map((item, index) => (
            <NavigationLevelListItem
              id={index}
              name={item.name}
              url={item.url}
              subItems={item.children}
              isOpen={this.state.openIdItem === index}
              onClick={() =>
                this.setState({
                  openIdItem: this.state.openIdItem === index ? null : index,
                })
              }
            />
          ))}
        </NavigationLevelList>
      </Container>
    );
  }

  renderRightContainer() {
    const leftPadding = {
      [Breakpoints.desktop]: ContainerPadding.grid001,
      [Breakpoints.tablet]: ContainerPadding.grid002,
    };

    const topPadding = {
      [Breakpoints.default]: ContainerPadding.dynamic0250,
    };

    return (
      <Container wrap={ContainerWrap.never}>
        <Line />
        <Container
          wrap={ContainerWrap.always}
          padding={{ left: leftPadding, top: topPadding }}
          gutter={ContainerGutter.dynamic0250}
        >
          <Container
            gutter={ContainerGutter.dynamic0020}
            wrap={ContainerWrap.always}
          >
            <Text appearance={TextAppearance.copy0150}>
              <Link
                href={"https://i3chat.callcenterhotel.com/dchat_ticari/"}
                target={"_blank"}
              >
                Üye İşlemleri
              </Link>
            </Text>
            <Text appearance={TextAppearance.copy0150}>
              <Link
                href={"https://canlidestek.vw.com.tr/dim_ticari/chat.html"}
                target={"_blank"}
              >
                Canlı Destek
              </Link>
            </Text>
            <Text appearance={TextAppearance.copy0150}>
              <Link
                href={"https://ticariarac.vw.com.tr/brosur-indir"}
                target={"_blank"}
              >
                Broşürler
              </Link>
            </Text>
          </Container>
        </Container>
      </Container>
    );
  }

  renderContent() {
    const appearance = {
      [Breakpoints.default]: [
        { name: "a", columns: 24 },
        { name: "b", columns: 24 },
      ],
      [Breakpoints.tablet]: [
        { name: "a", columns: 13 },
        { name: ".", columns: 4 },
        { name: "b", columns: 7 },
      ],
    };

    return (
      <Layout appearance={appearance}>
        {this.renderLeftContainer()}
        {this.renderRightContainer()}
      </Layout>
    );
  }

  render() {
    const { isLoaded } = this.state;

    return (
      <ThemeProvider theme={"main"}>
        <TopContainer isOpen={this.props.isOpen} isLoaded={isLoaded}>
          <Layout appearance={layoutAppearance}>
            {this.renderQuitButton()}
            <WhiteDiv>
              <Container
                shrinkContent={true}
                stretchContent={true}
                padding={{
                  left: ContainerPadding.grid002,
                  right: ContainerPadding.grid002,
                }}
                wrap={ContainerWrap.always}
              >
                {this.renderCloseButton()}
                {this.renderContent()}
              </Container>
            </WhiteDiv>
          </Layout>
        </TopContainer>
      </ThemeProvider>
    );
  }
}

export default SideBarLevelFour;

const layoutAppearance = {
  [Breakpoints.default]: [
    { name: "a", columns: 3 },
    { name: "b", columns: 21 },
  ],
  [Breakpoints.tablet]: [
    { name: "a", columns: 2 },
    { name: "b", columns: 22 },
  ],
  [Breakpoints.desktop]: [
    { name: "a", columns: 1 },
    { name: "b", columns: 23 },
  ],
};

const menuItems = [
  { name: "Caddy Araç Karşılaştırma", url: "/arac-karsilastirma-caddy" },
  {
    name: "Transporter Araç Karşılaştırma",
    url: "/arac-karsilastirma-transporter",
  },
  { name: "Crafter Araç Karşılaştırma", url: "/arac-karsilastirma-crafter" },
  { name: "Amarok Araç Karşılaştırma", url: "/arac-karsilastirma-amarok" },
  {
    name: "Caravelle Araç Karşılaştırma",
    url: "/arac-karsilastirma-caravelle",
  },
];
