"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.VolumeMaximum = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M13.994 3.212a1.03 1.03 0 00-.49.124l-6.33 3.426a2.057 2.057 0 01-.979.248H2.033a1.014 1.014 0 00-1.02 1.008v7.964a1.014 1.014 0 001.02 1.008h4.163a2.058 2.058 0 01.979.248l6.33 3.426a1.026 1.026 0 001.383-.4.997.997 0 00.126-.484V4.22a1.014 1.014 0 00-1.02-1.008zM2.344 8.34H5.71v7.32H2.343zM13.683 12v7.248l-5.876-3.18a3.376 3.376 0 00-.768-.3V8.232a3.385 3.385 0 00.768-.3l5.875-3.18zM17.509 16.451l-.947-.934a5.33 5.33 0 001.443-3.52 5.289 5.289 0 00-1.44-3.52l.944-.937a6.705 6.705 0 011.826 4.457 6.625 6.625 0 01-1.826 4.454zM20.307 18.638l-.948-.934a8.847 8.847 0 002.343-5.714 8.88 8.88 0 00-2.337-5.709l.942-.941a10.164 10.164 0 012.725 6.65 10.295 10.295 0 01-2.725 6.648z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.979 3.69v4.62l-1.153-.794-.058-.04-.062-.034a2.364 2.364 0 00-1.122-.283H1.33V4.84h1.254a2.362 2.362 0 001.122-.284l.062-.033.058-.04L4.98 3.69M5.8 1.618a.512.512 0 00-.245.061L3.073 3.388a1.025 1.025 0 01-.489.123H.508A.505.505 0 000 4.014v3.972a.505.505 0 00.508.502h2.076a1.026 1.026 0 01.489.124l2.483 1.709a.511.511 0 00.69-.2.498.498 0 00.063-.241V2.12a.505.505 0 00-.508-.502zM7.736 9.263L6.978 8.17A2.508 2.508 0 008.013 6a2.517 2.517 0 00-1.03-2.168l.75-1.097A3.827 3.827 0 019.343 6a3.879 3.879 0 01-1.607 3.263z" }),
            React.createElement("path", { d: "M9.732 10.852l-.944-.936c.037-.039.097-.091.177-.158a4.482 4.482 0 001.65-3.757 4.474 4.474 0 00-1.657-3.768 2.457 2.457 0 01-.165-.145l.937-.944.08.068a5.784 5.784 0 012.136 4.789 5.794 5.794 0 01-2.125 4.775z" }));
    }
    return null;
});
