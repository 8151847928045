"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const helpers_1 = require("../helpers");
const dynamic_tokens_1 = require("./dynamic-tokens");
const create_css_var_name_1 = require("./create-css-var-name");
const example_design_tokens_1 = require("./example-design-tokens");
function getMediaQueryVars(optionsOverride) {
    const optionsTokens = optionsOverride && optionsOverride.tokens;
    const dynamicTokens = dynamic_tokens_1.getDynamicTokens(optionsTokens || example_design_tokens_1.designTokens);
    return Object.keys(dynamicTokens)
        .sort(helpers_1.compareBreakpoints)
        .reduce((result, breakpoint) => {
        const styles = Object.keys(dynamicTokens[breakpoint]).reduce((cssVars, path) => `
				${cssVars}
				${create_css_var_name_1.createCSSVarName(path)}: ${dynamicTokens[breakpoint][path]};
			`, '');
        return breakpoint === '0'
            ? `${styles}
					${result}`
            : `${result}
					@media (min-width: ${breakpoint}) {
						${styles}
					}`;
    }, '');
}
exports.getMediaQueryVars = getMediaQueryVars;
