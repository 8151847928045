"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Rewind = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M1.652 10.949l10.424-5.166a1.171 1.171 0 011.7 1.051v2.683l7.534-3.734A1.171 1.171 0 0123 6.834v10.332a1.171 1.171 0 01-1.7 1.051l-7.534-3.734v2.683a1.171 1.171 0 01-1.7 1.051L1.652 13.051a1.173 1.173 0 010-2.102zm20.018 5.965V7.086L13.771 11v2zm-9.229-9.828L2.526 12l9.915 4.914z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.67 3.947l-3.053 1.7L6.973 6l.644.358 3.053 1.7zm-5.029 0L1.944 6l3.7 2.053V3.947m.529-1.7a.785.785 0 01.8.768v1.464L10.8 2.354a.826.826 0 01.4-.1.785.785 0 01.8.768v5.96a.785.785 0 01-.8.768.826.826 0 01-.4-.1L6.971 7.521v1.461a.785.785 0 01-.8.768.832.832 0 01-.4-.1L.4 6.663a.75.75 0 010-1.326l5.37-2.983a.832.832 0 01.4-.1z" }));
    }
    return null;
});
