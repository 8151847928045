"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.MagnifierPlus = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.5 21.53l-4.81-4.93a9.38 9.38 0 002-5.79 9.19 9.19 0 00-9.1-9.31 9.2 9.2 0 00-9.09 9.31 9.2 9.2 0 009.09 9.31 8.94 8.94 0 006.19-2.51l4.77 4.89zM2.84 10.81a7.85 7.85 0 017.75-7.93 7.85 7.85 0 017.74 7.93 7.85 7.85 0 01-7.74 7.94 7.86 7.86 0 01-7.75-7.94z" }),
            React.createElement("path", { d: "M11.26 7.19H9.92v2.93H7.05v1.38h2.87v2.93h1.34V11.5h2.86v-1.38h-2.86z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.8 10.69L9 7.93A5 5 0 105 10a4.93 4.93 0 003.11-1.12l2.75 2.75zM1.33 5A3.67 3.67 0 115 8.67 3.68 3.68 0 011.33 5z" }),
            React.createElement("path", { d: "M5.67 3H4.33v1.33H3v1.34h1.33V7h1.34V5.67H7V4.33H5.67z" }));
    }
    return null;
});
