"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.AccessoryParts = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M2.75 5.994l-2.75 5 2.75 5h5.5l2.75-5-2.75-5zm4.714 8.67H3.536l-2.018-3.67 2.018-3.67h3.928l2.018 3.67zM5.5 8.744a2.25 2.25 0 102.25 2.25 2.25 2.25 0 00-2.25-2.25zm0 3.17a.92.92 0 11.92-.92.921.921 0 01-.92.92zM24 2.246H12v5h3.335v14.737h5.325l.1-14.737H24zm-4.587 7.765l-.013 1.737-2.736-.87V9.137zm-.021 3.13l-.012 1.652-2.715-.864v-1.656zm-.021 3.045l-.012 1.777-2.694-.857v-1.781zm-2.706 4.467V18.5l2.684.854-.009 1.3zm2.758-12.035l-2.758-.877v-.495h2.767zm3.247-2.7h-9.34V3.576h9.34zM0 22h11v-4H0zm1.33-2.67h8.34v1.34H1.33z" }));
    }
    return null;
});
