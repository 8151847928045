"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const styled_components_1 = require("styled-components");
const get_theme_1 = require("./get-theme");
exports.ThemeProviderContext = React.createContext({});
/**
 * ThemeProvider component
 *
 * Provides a theme object to all it's child components via `styled-components`' ThemeProvider
 *
 * @param props Component properties
 */
function ThemeProvider(props) {
    const { children, direction, theme: newTheme, themeContextOptions } = props;
    const validChildren = React.Children.toArray(children).filter(React.isValidElement);
    if (!validChildren.length) {
        return null;
    }
    // Hint: Do not remove the fragment (<>...</>) below. It is absolutely
    // necessary to ensure the theme provider's children have the correct type.
    return (React.createElement(exports.ThemeProviderContext.Consumer, null, context => (React.createElement(exports.ThemeProviderContext.Provider, { value: Object.assign(Object.assign({}, context), themeContextOptions) },
        React.createElement(styled_components_1.ThemeProvider, { theme: (currentTheme) => get_theme_1.getTheme(currentTheme, newTheme, direction) },
            React.createElement(React.Fragment, null, validChildren))))));
}
exports.ThemeProvider = ThemeProvider;
