"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Selected = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M14.71 10.31l-3.73-3.07.84-1.03 2.8 2.31 7.23-7.22.94.94z" }),
            React.createElement("path", { d: "M19.58 22.89H3.68A2.62 2.62 0 011 20.31V4.14a2.62 2.62 0 012.68-2.58h13.15v1.33H3.68a1.29 1.29 0 00-1.33 1.25v16.17a1.29 1.29 0 001.33 1.25h15.9a1.29 1.29 0 001.33-1.25V7.8h1.33v12.51a2.63 2.63 0 01-2.66 2.58z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7.3 6.16L5.17 4.4l.85-1.02 1.2.99L10.7.89l.94.94z" }),
            React.createElement("path", { d: "M10 11.77H1.85a1.66 1.66 0 01-1.69-1.64V1.87A1.66 1.66 0 011.85.23H6.9v1.33H1.85a.34.34 0 00-.36.31v8.26a.34.34 0 00.36.31H10a.34.34 0 00.36-.31V6h1.33v4.17a1.66 1.66 0 01-1.69 1.6z" }));
    }
    return null;
});
