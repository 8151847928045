"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CarPickupService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M15.321 10.575c1.242 0 2.11-.868 2.11-2.11 0-1.243-.868-2.11-2.11-2.11s-2.11.867-2.11 2.11c0 1.242.868 2.11 2.11 2.11zm0-5.55c1.993 0 3.44 1.446 3.44 3.44 0 1.993-1.447 3.44-3.44 3.44s-3.44-1.447-3.44-3.44c0-1.994 1.447-3.44 3.44-3.44zm3.768 7.401c1.554-1.553 1.996-3.777 1.155-5.807-.84-2.028-2.726-3.289-4.923-3.289-2.196 0-4.082 1.261-4.922 3.29s-.398 4.254 1.155 5.806l3.767 3.769zM8.34 17.52l3.213-3.214-.94-.94a6.9 6.9 0 01-.844-1.05l-6.341 6.339a.335.335 0 000 .473l1.415 1.416c.09.09.243.137.339.144a7.011 7.011 0 00-.055-1.008l-.092-.746h1.501V17.52zM21.473 6.111c1.051 2.535.497 5.316-1.443 7.256l-4.709 4.708-2.827-2.828-3.603 3.603H7.866v1.414H6.509c.013.373-.006.759-.107 1.003a1.209 1.209 0 01-.901.722 1.594 1.594 0 01-.302.029c-.466 0-.96-.196-1.297-.533l-1.415-1.416A1.654 1.654 0 012 18.893c0-.446.173-.863.487-1.179l6.622-6.619.02.02c-.634-1.569-.649-3.337.041-5.003C10.22 3.575 12.577 2 15.321 2c2.745 0 5.102 1.575 6.152 4.111z" }));
    }
    return null;
});
