"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ContactDealer = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.72.83H11.18a1.45 1.45 0 00-1.45 1.45v3.51a4.48 4.48 0 101.33.21V2.28a.11.11 0 01.12-.11h10.54a.11.11 0 01.11.11v15.63a.12.12 0 01-.11.12H18l-.2-1a3.14 3.14 0 00-2-2.36L14 14l-.47 1.25 1.88.7a1.86 1.86 0 011.17 1.38l.76 3.87-2.34.04-.31-2.33-1.32.18.29 2.2c-2.63.06-5.27.06-7.9 0l.24-2.2-1.3-.18-.3 2.31c-.75 0-1.49 0-2.23-.08l.76-3.85a1.88 1.88 0 011.17-1.38l1.9-.7L5.51 14l-1.88.71a3.18 3.18 0 00-2 2.36l-.77 3.81a1.34 1.34 0 001.25 1.6 161.13 161.13 0 0015.24 0 1.34 1.34 0 001.25-1.6l-.3-1.52h3.42a1.45 1.45 0 001.45-1.45V2.28A1.45 1.45 0 0021.72.83zm-8.93 9.36a3.06 3.06 0 11-3.06-3.07 3.07 3.07 0 013.06 3.07z" }),
            React.createElement("path", { d: "M15.5 4.37h4.48V5.7H15.5zM15.5 7.41h4.48v1.33H15.5z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11 0H4.87A1.07 1.07 0 003.8 1v2.11a2.49 2.49 0 101.33-.06V1.34h5.54v6.89H8.5a1.78 1.78 0 00-.89-.73l-.28-.11-.47 1.25.28.11a.52.52 0 01.34.4l.29 1.45c-2.12.09-4.25.09-6.37 0l.29-1.45a.5.5 0 01.31-.4l.3-.12-.44-1.25-.3.12A1.87 1.87 0 00.38 8.89L0 10.73a1 1 0 00.19.8 1 1 0 00.73.38c1.23.09 2.45.09 3.66.09s2.42 0 3.65-.09a1 1 0 00.92-1.18l-.23-1.16h2a1 1 0 001-1.05V1A1 1 0 0011 0zM5.73 5.49a1.17 1.17 0 11-1.17-1.17 1.17 1.17 0 011.17 1.17z" }),
            React.createElement("path", { d: "M7.46 2.31h2.16v1.34H7.46zM8.17 4.52h1.46v1.34H8.17z" }));
    }
    return null;
});
