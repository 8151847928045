"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Contact = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.9 4.8h-.12a4.37 4.37 0 115.74 4.73 6 6 0 00-.37-1.27 3.06 3.06 0 10-4.05-3.33 6 6 0 00-1.2-.13zM23 16.08l-.76-3.84a3.17 3.17 0 00-2-2.36l-1.88-.71-.46 1.25 1.87.7a1.83 1.83 0 011.13 1.38l.76 3.86c-.7 0-1.77.07-2.55.09a3.58 3.58 0 01.19.66l.14.66 2.28-.08a1.4 1.4 0 001-.51 1.35 1.35 0 00.28-1.1zM5.5 10.52a4.39 4.39 0 114.39 4.4 4.4 4.4 0 01-4.39-4.4zm1.33 0a3.06 3.06 0 103.06-3.06 3.06 3.06 0 00-3.06 3.06zm7 11.1c-2.63.06-5.27.06-7.9 0l-1.34-.05c-.74 0-1.49 0-2.23-.09l.77-3.84a1.85 1.85 0 011.17-1.38l1.87-.71-.5-1.25-1.88.7a3.2 3.2 0 00-2 2.37L1 21.21a1.32 1.32 0 00.26 1.09 1.34 1.34 0 001 .52q3.81.18 7.63.18c2.54 0 5.09-.06 7.61-.18a1.35 1.35 0 001.26-1.61L18 17.37A3.19 3.19 0 0016 15l-1.87-.7-.47 1.24 1.88.71a1.87 1.87 0 011.17 1.38l.75 3.86c-.77 0-1.54.06-2.32.08z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7.5.45a2.28 2.28 0 00-1.83.92 3.47 3.47 0 011.18.68 1 1 0 01.65-.26 1 1 0 01.42 1.82 3.64 3.64 0 01.22 1.19v.17A2.3 2.3 0 007.5.45zm4.12 6a1.79 1.79 0 00-1.1-1.34L10.34 5a3.7 3.7 0 01-1.13 1l.78.37a.5.5 0 01.32.38l.27 1.38h-.69v.13l.24 1.2h.88a1 1 0 00.78-.46 1 1 0 00.19-.8zM4.49 3.84a1 1 0 11-1 1 1 1 0 011-1m0-1.34a2.31 2.31 0 102.3 2.31 2.3 2.3 0 00-2.3-2.31z" }),
            React.createElement("path", { d: "M8.62 8.5a1.8 1.8 0 00-1.11-1.34l-.18-.09A3.53 3.53 0 016.2 8l.8.39a.49.49 0 01.31.37l.28 1.39c-2 .08-4.12.08-6.17 0l.28-1.39a.5.5 0 01.3-.38L2.85 8a3.54 3.54 0 01-1.18-1l-.13.06A1.79 1.79 0 00.38 8.5L0 10.29a1 1 0 00.19.8 1 1 0 00.73.38c1.18 0 2.37.08 3.56.08s2.38 0 3.56-.08A1 1 0 009 10.29z" }));
    }
    return null;
});
