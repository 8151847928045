"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Mot = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M13.24 13.07H24L17.4 1.62 12 10.93 6.64 1.62 0 13.07h10.76l-5.4 9.31H18.6zm4.16-8.8l4.3 7.47h-8.63zM2.31 11.74l4.33-7.47 4.3 7.47zm5.36 9.31L12 13.59l4.3 7.46z" }));
    }
    return null;
});
