"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.SkipForward = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.61 4.9a.46.46 0 01.19 0l11.55 6.61a.48.48 0 01.27.44.47.47 0 01-.27.43L8.79 19.06a.39.39 0 01-.18 0 .49.49 0 01-.49-.49V5.39a.49.49 0 01.49-.49m0-1.33a1.81 1.81 0 00-1.82 1.82v13.22a1.81 1.81 0 002.62 1.63L21 13.66a1.82 1.82 0 000-3.26L9.41 3.76a1.8 1.8 0 00-.8-.19zM2.98 3.73h1.33v16.55H2.98z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.19 2.47L10.35 6 4.19 9.54zM4 1a1.09 1.09 0 00-1.14 1v8A1.09 1.09 0 004 11.05a1.07 1.07 0 00.48-.12L11.32 7a1.09 1.09 0 000-1.95l-6.9-4A1.07 1.07 0 004 1zM.07 1.07H1.4v9.87H.07z" }));
    }
    return null;
});
