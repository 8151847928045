import React from "react";
import {
  Text,
  TextAppearance,
} from "../../../components-core/packages/components-core/dist/text";

import { Link } from "../../../components-core/packages/components-core/dist/link/index";

const SubscribeText = ({ link, title, target }) => {
  return (
    <Text appearance={TextAppearance.copy0150}>
      <Link href={link} target={target ? "_blank" : "_self"}>
        {title}
      </Link>
    </Text>
  );
};

export default SubscribeText;
