"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ServiceBell = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.651 21.674c-.912-5.095-3.624-7.93-8.058-8.463a2.577 2.577 0 10-3.185 0c-4.435.533-7.146 3.368-8.06 8.463H1v1.33h22v-1.33zm-9.65-11.722a1.247 1.247 0 11-1.248 1.247A1.249 1.249 0 0112 9.952zm0 4.49c4.656 0 7.35 2.37 8.294 7.232H3.705c.944-4.863 3.638-7.232 8.295-7.232zM11.335 1.805h1.33v4h-1.33zM4.141 4.803l1.046-.82 2.47 3.146-1.046.82zM16.345 7.13l2.466-3.148 1.047.82-2.467 3.148z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.964 10.67c-.451-2.423-1.77-3.866-3.835-4.24a1.406 1.406 0 10-2.233-.006c-2.08.366-3.407 1.812-3.86 4.246H0V12h12v-1.33zM6 7.653a3.348 3.348 0 013.602 3.017H2.398A3.348 3.348 0 016 7.653zM5.325 0h1.33v2.186h-1.33zM1.037 2.812l.784-1.075 1.763 1.287L2.8 4.1zM8.396 3.025l1.764-1.288.784 1.074L9.18 4.1z" }));
    }
    return null;
});
