"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.LocalBusiness = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.58 1.19a3.41 3.41 0 00-3.5 3.53 10.8 10.8 0 001.44 4.93c.76 1.41 1.59 2.54 2.06 2.54s1.31-1.13 2.07-2.55a10.75 10.75 0 001.43-4.92 3.41 3.41 0 00-3.5-3.53zm.9 7.81a11.91 11.91 0 01-.9 1.45A11.82 11.82 0 0118.69 9a9.42 9.42 0 01-1.28-4.3 2.17 2.17 0 114.34 0A9.45 9.45 0 0120.48 9zm.2-4.38a1.1 1.1 0 11-1.1-1.09 1.1 1.1 0 011.1 1.1zM9.81 14.92a4.4 4.4 0 10-4.38-4.39 4.39 4.39 0 004.38 4.39zm0-7.46a3.07 3.07 0 11-3.05 3.07 3.06 3.06 0 013.05-3.07zm8.87 13.75a1.29 1.29 0 01-.26 1.09 1.34 1.34 0 01-1 .52q-3.78.18-7.61.18c-2.54 0-5.09-.06-7.61-.18a1.34 1.34 0 01-1-.52 1.37 1.37 0 01-.26-1.09l.77-3.84a3.17 3.17 0 012-2.36l1.87-.71.47 1.24-1.87.71A1.83 1.83 0 003 17.63l-.77 3.84c5 .26 10.1.26 15.12 0l-.76-3.86a1.85 1.85 0 00-1.17-1.38l-1.87-.71.45-1.22 1.88.71a3.17 3.17 0 012 2.36zM9.15 16h1.33v4.2H9.15z" }));
    }
    return null;
});
