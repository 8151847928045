"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const theme_provider_1 = require("../theme-provider");
exports.ContainerGutter = theme_provider_1.DesignTokenSizeEnum;
exports.ContainerPadding = theme_provider_1.DesignTokenSizeEnum;
var ContainerWrap;
(function (ContainerWrap) {
    ContainerWrap["never"] = "never";
    ContainerWrap["always"] = "always";
    ContainerWrap["auto"] = "auto";
})(ContainerWrap = exports.ContainerWrap || (exports.ContainerWrap = {}));
var ContainerHorizontalAlignment;
(function (ContainerHorizontalAlignment) {
    ContainerHorizontalAlignment["flexStart"] = "flex-start";
    ContainerHorizontalAlignment["center"] = "center";
    ContainerHorizontalAlignment["flexEnd"] = "flex-end";
    ContainerHorizontalAlignment["spaceBetween"] = "space-between";
})(ContainerHorizontalAlignment = exports.ContainerHorizontalAlignment || (exports.ContainerHorizontalAlignment = {}));
var ContainerVerticalAlignment;
(function (ContainerVerticalAlignment) {
    ContainerVerticalAlignment["flexStart"] = "flex-start";
    ContainerVerticalAlignment["center"] = "center";
    ContainerVerticalAlignment["flexEnd"] = "flex-end";
})(ContainerVerticalAlignment = exports.ContainerVerticalAlignment || (exports.ContainerVerticalAlignment = {}));
