"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ConfiguratorPrivate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "12", cy: "15.95", r: "1" }),
            React.createElement("path", { d: "M22.12 12.22h-1.45l.24.54v.09l.05.08a4.32 4.32 0 01.58 1.52c-1.5.21-3.06.39-4.67.51l.1 1.32c1.6-.12 3.16-.29 4.66-.5v5.7a.29.29 0 01-.28.28h-2a.28.28 0 01-.28-.28v-1.82H4.89v1.81a.3.3 0 01-.3.29h-2a.28.28 0 01-.28-.28v-5.67c1.54.21 3.13.38 4.77.5L7.19 15a70.09 70.09 0 01-4.78-.5A4.7 4.7 0 013 12.93V12.76l.73-1.66c2.09.25 4.19.4 6.28.46V10.2c-1.9 0-3.81-.16-5.71-.38L5.49 7.3A2.06 2.06 0 017 6.42h3.1V5.09H6.91a3.36 3.36 0 00-2.59 1.58L3 9.66Q1.69 9.5.39 9.27H.33l-.11.66-.06.66c.76.13 1.52.24 2.29.35l-.58 1.29A5.87 5.87 0 001 15.29v6.19a1.61 1.61 0 001.61 1.61h2a1.63 1.63 0 001.63-1.62V21h11.54v.49a1.61 1.61 0 001.61 1.61h2A1.62 1.62 0 0023 21.48v-6.19a5.87 5.87 0 00-.87-3.07z" }),
            React.createElement("path", { d: "M22.67 1.33v8.34h-8.34V1.33zM24 0H13v11h11z" }),
            React.createElement("path", { d: "M17.6 8.41l-2.47-2.47.94-.94 1.53 1.53L21.13 3l.94.94z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M44.9 25.35l-2 .59-7.67 2.29-.39 1.67h-8.13a3 3 0 00-5.42 0h-8.15l-.39-1.67L5 25.91l1.43-2.38a17.38 17.38 0 004.31.55h7.46v-2h-7.51a15.22 15.22 0 01-3.23-.35L11 15.84a3 3 0 012.39-1.43h.12c1.55-.08 3.11-.14 4.66-.18v-2c-1.59 0-3.18.1-4.76.19h-.13a5 5 0 00-4 2.4l-3.59 6H1v2l3 .75-1 1.67a14.26 14.26 0 00-2 7.34V44a2.66 2.66 0 002.67 2.65h4.68A2.67 2.67 0 0011 44v-1.66q6.5.4 13 .4t12.81-.39h.22V44a2.66 2.66 0 002.66 2.65h4.61A2.66 2.66 0 0047 44V32.83a14.22 14.22 0 00-2-7.36zm-8 4.46l7-2.07a12.19 12.19 0 011 3.29l-5.7 1.12-2.64-.71zm-12.9.36a1 1 0 11-1 1 1 1 0 011-1zM4 27.71l7.07 2.1.37 1.63-2.64.71L3.11 31A12.29 12.29 0 014 27.71zM45 44a.67.67 0 01-.67.65h-4.61a.66.66 0 01-.66-.65v-3.79l-2.13.13h-.21a206.658 206.658 0 01-25.58-.01L9 40.21V44a.67.67 0 01-.67.65H3.67A.67.67 0 013 44V33l5.87 1.17 4.94-1.35-.22-1h7.51a3 3 0 005.8 0h7.51l-.22 1 4.94 1.35L45 33z" }),
            React.createElement("path", { d: "M45 3v15H30V3zm2-2H28v19h19z" }),
            React.createElement("path", { d: "M35.94 15.31l-4.16-4.16 1.42-1.42 2.74 2.75 6.21-6.2 1.41 1.41z" }));
    }
    return null;
});
