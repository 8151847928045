"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Filter = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M20.25 1.86a1 1 0 00-.9-.53H4.65a1.05 1.05 0 00-.9 1.57l5.08 8.66v6.94l.25.52 5 4 1.09-.52V11.56l5.08-8.66a1 1 0 000-1.04zM13.93 11l-.1.34v9.74l-3.66-2.94v-6h2.33v-1.31H10L5.16 2.67h13.68z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 48 48", width: "48", height: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M40.45 1H7.55a2.05 2.05 0 00-2 2.05V7l.26.67L19 22.38V39l.29.71 8 8L29 47V22.38L42.24 7.67 42.5 7V3.05A2.05 2.05 0 0040.45 1zm0 2v2h-33V3zM27.26 21.33L27 22v22.59l-6-6V23h4.08v-2h-4.63L7.85 7h32.3z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.54 2.34a1.91 1.91 0 00-3.54 0H0v1.33h6a1.91 1.91 0 003.58 0H12V2.34zM4.25 7.09a1.91 1.91 0 00-1.79 1.25H0v1.33h2.46a1.91 1.91 0 003.54 0h6V8.34H6a1.91 1.91 0 00-1.75-1.25z" }));
    }
    return null;
});
