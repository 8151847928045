"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const container_1 = require("../container");
const helpers_1 = require("../helpers");
const moving_frame_1 = require("../moving-frame");
const theme_provider_1 = require("../theme-provider");
const StyledTopBar = theme_provider_1.styled.div.withConfig({ displayName: "StyledTopBar", componentId: "sc-1ibhql0" }) `
	width: 100%;
	transform: translateZ(0);
	transition: min-height
		${props => `${props.theme.animation.duration.layer} ${props.theme.animation.timingFunction.navigation}`};
	color: ${props => props.theme.interaction.recipe400.default};
	background-color: ${props => props.theme.navigation.backgroundColor};
	/* without a logo the min-height is menu icon size plus top and bottom margins of menu icon plus top and bottom margin of navigation */
	min-height: ${props => parseInt(props.theme.size.static150, 10) * 4 +
    parseInt(props.theme.size.static350, 10)}px;

	${props => !props.hideLogo &&
    theme_provider_1.css `
			/* with a logo the min-height is menu icon size plus top and bottom margins of menu icon plus top margin of navigation plus lower half of logo plus bottom logo margin*/
			min-height: ${parseInt(props.theme.size.static200, 10) * 3 +
        parseInt(props.theme.size.static350, 10) +
        parseInt(props.theme.size.static400, 10)}px;

			@media (min-width: ${helpers_1.Breakpoints.b560}px) {
				min-height: ${parseInt(props.theme.size.static150, 10) * 3 +
        parseInt(props.theme.size.static350, 10) +
        parseInt(props.theme.size.static450, 10)}px;
			}

			@media (min-width: ${helpers_1.Breakpoints.b960}px) {
				min-height: ${parseInt(props.theme.size.static150, 10) * 3 +
        parseInt(props.theme.size.static350, 10) +
        parseInt(props.theme.size.static510, 10)}px;
			}

			@media (min-width: ${helpers_1.Breakpoints.b1920}px) {
				min-height: ${parseInt(props.theme.size.static150, 10) * 3 +
        parseInt(props.theme.size.static350, 10) +
        parseInt(props.theme.size.static520, 10)}px;
			}
		`}
`;
const StyledLogoLayer = theme_provider_1.styled.div.withConfig({ displayName: "StyledLogoLayer", componentId: "sc-1vmcihv" }) `
	display: flex;
	position: absolute;
	top: 0;
	width: 100%;
	align-items: center;
	pointer-events: none;
	transition: transform
		${props => `${props.theme.animation.duration.layer} ${props.theme.animation.timingFunction.navigation}`};
	/* the free space around logo is 0.5 times logo size, */
	/* for small breakpoints the top padding is at least the size of the menu button */
	padding: ${props => Math.max(parseInt(props.theme.size.static400, 10) * 0.5, parseInt(props.theme.size.static450, 10) +
    parseInt(props.theme.size.static150, 10) -
    parseInt(props.theme.size.static400, 10) * 0.5)}px
		0 ${props => parseInt(props.theme.size.static400, 10) * 0.5}px;

	@media (min-width: ${helpers_1.Breakpoints.b560}px) {
		padding: ${props => Math.max(parseInt(props.theme.size.static450, 10) * 0.5, parseInt(props.theme.size.static450, 10) +
    parseInt(props.theme.size.static150, 10) -
    parseInt(props.theme.size.static450, 10) * 0.5)}px
			0 ${props => parseInt(props.theme.size.static450, 10) * 0.5}px;
	}

	@media (min-width: ${helpers_1.Breakpoints.b960}px) {
		padding: ${props => parseInt(props.theme.size.static510, 10) * 0.5}px 0;
	}

	@media (min-width: ${helpers_1.Breakpoints.b1920}px) {
		padding: ${props => parseInt(props.theme.size.static520, 10) * 0.5}px 0;
	}

	${props => props.hideLogo && `transform: translateY(-100%);`}
`;
exports.TopBar = props => {
    const { hideLogo, logo, children } = props;
    return (React.createElement(StyledTopBar, { hideLogo: hideLogo },
        React.createElement(container_1.Container, { padding: {
                left: container_1.ContainerPadding.grid001,
                right: container_1.ContainerPadding.grid001,
                top: container_1.ContainerPadding.static150,
                bottom: container_1.ContainerPadding.static150
            }, gutter: container_1.ContainerGutter.static300, wrap: container_1.ContainerWrap.never, verticalAlign: container_1.ContainerVerticalAlignment.center, stretchContent: true }, children),
			React.createElement(StyledLogoLayer, { hideLogo: hideLogo },
            React.createElement(moving_frame_1.MovingFrame, { logo: logo }))));
};
