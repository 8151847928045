"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.TransmissionManual = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.24 2.5a2 2 0 10-2.67 1.88v7a.5.5 0 01-.4.5H12.9v-7.5a2 2 0 10-1.33 0v7.45H5.9V4.38a2 2 0 10-1.33 0v15.24a2 2 0 101.33 0v-6.46h5.67v6.46a2 2 0 101.33 0v-6.45h3.68a12.81 12.81 0 001.81 0 1.82 1.82 0 001.51-1.81v-7a2 2 0 001.34-1.86z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.66 1.89A1.35 1.35 0 108.65 3v2.35H6.78V3a1.33 1.33 0 00.69-1.15A1.35 1.35 0 105.45 3v2.3H3.59V3a1.36 1.36 0 10-1.33 0v6a1.35 1.35 0 101.33 0V6.67h1.86V9a1.36 1.36 0 101.33 0V6.67h2.21a1.19 1.19 0 001-1.18V3a1.34 1.34 0 00.67-1.11z" }));
    }
    return null;
});
