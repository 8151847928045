"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Clock = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 22.66A10.31 10.31 0 011.33 12 10.32 10.32 0 0112 1.33 10.32 10.32 0 0122.67 12 10.31 10.31 0 0112 22.66zm0-20A9 9 0 002.66 12 9 9 0 0012 21.33 9 9 0 0021.34 12 9 9 0 0012 2.66z" }),
            React.createElement("path", { d: "M12 14.54a1.73 1.73 0 01-.84-.22l-4.31-2.44.65-1.16 4.32 2.44a.37.37 0 00.51-.14l3.43-6 1.16.65-3.43 6.06a1.72 1.72 0 01-1.49.81z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 11.94A5.75 5.75 0 01.06 6 5.75 5.75 0 016 .06 5.75 5.75 0 0111.94 6 5.75 5.75 0 016 11.94zM6 1.39A4.42 4.42 0 001.39 6 4.42 4.42 0 006 10.61 4.42 4.42 0 0010.61 6 4.42 4.42 0 006 1.39z" }),
            React.createElement("path", { d: "M6 7.72a1.32 1.32 0 01-.61-.16L3 6.18 3.63 5 6 6.36 7.85 3 9 3.69 7.13 7a1.33 1.33 0 01-.8.65 1.26 1.26 0 01-.33.07z" }));
    }
    return null;
});
