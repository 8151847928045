"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Calendar = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 13.5c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm4 0c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm4 0c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm4 0c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm-12 4c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm4 0c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm4 0c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm4 0c.581 0 1 .419 1 1s-.419 1-1 1-1-.42-1-1 .42-1 1-1zm2 4.17c.921 0 1.67-.75 1.67-1.67v-8.835H2.33V20c0 .92.75 1.67 1.67 1.67zM5 4.327H2.33v5.508h19.34V4.327h-2.674V7h-1.33V4.327H6.33V7H5zm13.996-1.33H23V20c0 1.656-1.344 3-3 3H4a3 3 0 01-3-3V2.997h4V1h1.33v1.997h11.336V1h1.33z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "12", viewBox: "0 0 12 12", width: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.335 8c.386 0 .665.278.665.665a.642.642 0 01-.665.665A.672.672 0 013 9.236a.637.637 0 01-.33-.57c0-.26.132-.46.33-.572A.672.672 0 013.335 8zM6 8c.386 0 .665.278.665.665A.642.642 0 016 9.33a.642.642 0 01-.665-.665C5.335 8.278 5.613 8 6 8zm3 .094c.198.111.33.312.33.571a.638.638 0 01-.33.571.685.685 0 01-.335.094A.642.642 0 018 8.665C8 8.278 8.278 8 8.665 8c.128 0 .236.04.335.094zm0-2c.198.111.33.312.33.571a.638.638 0 01-.33.571.685.685 0 01-.335.094A.642.642 0 018 6.665C8 6.278 8.278 6 8.665 6c.128 0 .236.04.335.094zM3.335 6c.386 0 .665.278.665.665a.642.642 0 01-.665.665A.672.672 0 013 7.236a.637.637 0 01-.33-.57c0-.26.132-.46.33-.572A.672.672 0 013.335 6zM6 6c.386 0 .665.278.665.665A.642.642 0 016 7.33a.642.642 0 01-.665-.665C5.335 6.278 5.613 6 6 6zm4.67 3V5.268H1.33V9c0 .921.75 1.67 1.67 1.67h6c.921 0 1.67-.749 1.67-1.67zM1.33 2.33v1.608h9.34V2.33H9.33V3H8v-.67H4V3H2.67v-.67zM11.665 1H12v8c0 .488-.128.943-.335 1.35A2.99 2.99 0 019 12H3a2.989 2.989 0 01-2.665-1.649A2.974 2.974 0 010 9.001V1h2.67V0H4v1h4V0h1.331v1z" }));
    }
    return null;
});
