"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Play = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.33 5.412L17.516 12 6.33 18.588zm-.133-1.433c-.202 0-.404.053-.589.159A1.2 1.2 0 005 5.194v13.613a1.202 1.202 0 001.197 1.215c.206 0 .413-.055.6-.165l11.605-6.835c.38-.224.598-.596.598-1.022s-.217-.798-.597-1.021L6.797 4.144a1.185 1.185 0 00-.6-.165z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M2.29 1.47l8 4.55-8 4.51zM2.23.09A1.27 1.27 0 001 1.37v9.26a1.27 1.27 0 001.27 1.28 1.24 1.24 0 00.56-.13l8.13-4.62a1.27 1.27 0 000-2.28L2.79.23a1.14 1.14 0 00-.56-.14z" }));
    }
    return null;
});
