"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const theme_provider_1 = require("../theme-provider");
exports.Image = theme_provider_1.styled.img.withConfig({ displayName: "Image", componentId: "sc-1r37b62" }) `
	display: block;
	width: 100%;
	${props => props.reduceBrightness && 'filter: brightness(80%)'};
`;
exports.Image.displayName = 'Image';
exports.DecorativeImage = React.forwardRef((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { alt } = props, others = __rest(props, ["alt"]);
    // `aria-hidden`is required to hide it from voiceover
    return React.createElement(exports.Image, Object.assign({ alt: "", "aria-hidden": true }, others, { ref: ref }));
});
exports.DecorativeImage.displayName = 'DecorativeImage';
const TRANSPARENT_GIF = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
exports.LazyImage = React.forwardRef((props, ref) => {
    const { src, srcPlaceholder, srcSet, sizes, className, alt, reduceBrightness } = props, others = __rest(props, ["src", "srcPlaceholder", "srcSet", "sizes", "className", "alt", "reduceBrightness"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("noscript", null,
            React.createElement(exports.Image, Object.assign({ alt: alt, className: className, sizes: sizes, src: src, srcSet: srcSet, reduceBrightness: reduceBrightness }, others))),
        React.createElement(exports.Image
        // The class `lazyload` is required for our 3rd party lazyloading lib to work
        , Object.assign({ 
            // The class `lazyload` is required for our 3rd party lazyloading lib to work
            className: `lazyload ${className || ''}`, 
            // If an alt attribute is used, a broken image symbol would be displayed
            // if src would be left empty. Therefore we render a transparent 1px gif
            // until lazyload has replaced the src/srcSet.
            src: srcPlaceholder || TRANSPARENT_GIF, "data-src": src, "data-srcset": srcSet, "data-sizes": sizes, alt: alt, reduceBrightness: reduceBrightness, ref: ref }, others))));
});
exports.LazyImage.displayName = 'LazyImage';
exports.DecorativeLazyImage = React.forwardRef((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { alt, reduceBrightness } = props, others = __rest(props, ["alt", "reduceBrightness"]);
    return (React.createElement(exports.LazyImage, Object.assign({ reduceBrightness: reduceBrightness, alt: "" }, others, { ref: ref })));
});
exports.DecorativeLazyImage.displayName = 'DecorativeLazyImage';
