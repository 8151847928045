"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Offers = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M14.1 8.59l-7.32 7.33-.94-.92 7.32-7.33zm-5.75 8.9l.94.94L14.73 13l-.94-.94zM22.8 9.56a1 1 0 00.09 1.27 1 1 0 010 1.39L11.77 23.34a2.26 2.26 0 01-3.19 0L.66 15.42a2.26 2.26 0 010-3.19L11.78 1.11a1 1 0 01.7-.29 1 1 0 01.69.29 1 1 0 00.69.28 1 1 0 00.58-.19 5.91 5.91 0 017.23.07L22.94 0H24v.82L22.63 2.2a6 6 0 01.17 7.36zm-1.11-6.42l-1.52 1.51a2.32 2.32 0 11-2-1.16 2.37 2.37 0 011 .25l1.53-1.53a4.62 4.62 0 00-5.49 0 2.29 2.29 0 01-1.38.46 2.26 2.26 0 01-1.36-.44L1.6 13.17a.93.93 0 000 1.31l7.92 7.92a.93.93 0 001.31 0l10.89-10.9a2.3 2.3 0 010-2.74 4.66 4.66 0 00-.03-5.62zm-3.52 1.68a1 1 0 101 1 1 1 0 00-1-1z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.382 29.905l14.043-14.043 1.414 1.414L13.796 31.32zM17.194 34.715l10.43-10.43 1.414 1.414-10.43 10.43z" }),
            React.createElement("path", { d: "M48 0h-1.89l-3.47 3.46a11.43 11.43 0 00-14-.16 1.87 1.87 0 01-2.44-.18 1.87 1.87 0 00-2.65 0L2.26 24.45a4.32 4.32 0 000 6.1l15.19 15.19a4.32 4.32 0 006.1 0l21.33-21.33a1.88 1.88 0 000-2.65 1.87 1.87 0 01-.17-2.44 11.45 11.45 0 00-.6-14.48L48 .94zm-4.89 18.12a3.86 3.86 0 00.27 5l-21.24 21.2a2.32 2.32 0 01-3.28 0L3.68 29.14a2.32 2.32 0 010-3.28L24.92 4.62a3.81 3.81 0 002.64 1 3.79 3.79 0 002.32-.78 9.43 9.43 0 0111.33 0l-3.73 3.78a3.82 3.82 0 00-1.66-.38A3.94 3.94 0 1039 9.91l3.66-3.66a9.48 9.48 0 01.45 11.87zm-5.37-6a1.92 1.92 0 11-1.92-1.92 1.92 1.92 0 011.92 1.96z" }));
    }
    return null;
});
