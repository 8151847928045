"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ANIMATION_FUNCTION = 'cubic-bezier(0.14, 1.12, 0.67, 0.99)';
const DEFAULT_ANIMATION_DURATION = '0.3s';
function normalizeTransitionProps(transition) {
    return typeof transition === 'string'
        ? {
            name: transition,
            duration: DEFAULT_ANIMATION_DURATION
        }
        : Object.assign(Object.assign({}, transition), { duration: transition.duration || DEFAULT_ANIMATION_DURATION });
}
function createTransitionForProperty(transition) {
    const { name, duration, delay } = normalizeTransitionProps(transition);
    const delayToAppend = delay ? ` ${delay}` : '';
    return `${name} ${duration} ${ANIMATION_FUNCTION}${delayToAppend}`;
}
function createElectricTransition(...transitions) {
    return transitions.length > 0
        ? `transition: ${transitions.map(createTransitionForProperty).join(', ')}`
        : undefined;
}
exports.createElectricTransition = createElectricTransition;
