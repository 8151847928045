"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ShoppingCart = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.988 2.705a.663.663 0 00-.646.511l-.605 2.532H2.031a.668.668 0 00-.518.247.67.67 0 00-.132.558l1.826 8.521a.664.664 0 00.65.525h12.526l-.846 3.54H4.162v1.33h11.899a.663.663 0 00.646-.511l3.805-15.924h2.519v-1.33h-3.043zM4.395 14.269L2.854 7.078h15.565l-1.718 7.191zM14.814 23a.735.735 0 01-.761-.761c0-.442.319-.761.761-.761s.761.319.761.761a.734.734 0 01-.761.761zM5.684 23a.735.735 0 01-.761-.761c0-.442.319-.761.761-.761s.761.319.761.761a.735.735 0 01-.761.761z" }),
            React.createElement("path", { d: "M6.692 8.695h1.33v3.957h-1.33zM10.04 8.695h1.33v3.957h-1.33zM13.389 8.695h1.33v3.957h-1.33z" }));
    }
    return null;
});
