"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Manual = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M18 3.33a.671.671 0 01.67.67v16a.671.671 0 01-.67.67H5.33V3.33zM18 2H4v20h14a2 2 0 002-2V4a2 2 0 00-2-2zm-5.119 13.917h2.6v1.33H8.517v-1.33h3.034V11.6h-2.04v-1.33h3.37zM12.1 6.753a.746.746 0 01.774.811.775.775 0 11-1.549 0 .746.746 0 01.775-.811z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.8 1.33a.671.671 0 01.67.67v8a.671.671 0 01-.67.67H2.53V1.33zM8.8 0H1.2v12h7.6a2 2 0 002-2V2a2 2 0 00-2-2zM5.33 5.875h1.33v3H5.33zM6 3.125a.75.75 0 11-.75.75.75.75 0 01.75-.75z" }));
    }
    return null;
});
