"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Login = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.22 6.86l-1 .95c1.32 1.24 2.34 2.22 3.09 3 .21.22.41.41.57.59H1v1.32h13.9l-.54.55c-.75.74-1.77 1.72-3.09 3l1 .95C15.59 14 17 12.57 17 12s-1.41-2-4.78-5.14z" }),
            React.createElement("path", { d: "M12 1A11 11 0 001.77 8h1.44a9.67 9.67 0 110 8H1.77A11 11 0 1012 1z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.85 10.12a4.46 4.46 0 002.15.55 4.67 4.67 0 000-9.34 4.61 4.61 0 00-2.15.55l-1-1A5.81 5.81 0 016 0a6 6 0 010 12 6 6 0 01-3.15-.91z" }),
            React.createElement("path", { d: "M0 6.66h4.85l-.55.54c-.37.35-.82.8-1.3 1.22l.95.95C6.06 7.43 7 6.44 7 6s-.94-1.43-3.08-3.37L3 3.58c.51.46 1 .87 1.33 1.22l.59.57H0z" }));
    }
    return null;
});
