"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Gas = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.75 2.83h-8.87a8.81 8.81 0 00-8.8 8.79v.76a8.73 8.73 0 002.13 5.71l-.8.81a4.89 4.89 0 01-3.47 1.43H1v1.34h.94a6.19 6.19 0 004.41-1.83l.81-.8a8.74 8.74 0 005.72 2.13h.74a8.81 8.81 0 008.8-8.79V3.5zm-.67 9.55a7.46 7.46 0 01-7.46 7.45h-.74A7.42 7.42 0 018.1 18.1l2.9-2.93h5.5v-1.34h-4.14l5.36-5.36-.94-.94-5.61 5.61V8.5H9.83v6l-2.68 2.65a7.42 7.42 0 01-1.73-4.77v-.76a7.46 7.46 0 017.46-7.45h8.2z" }));
    }
    return null;
});
