"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Logo = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 22.586c-5.786 0-10.543-4.8-10.543-10.586 0-1.2.214-2.357.6-3.471l6.172 12c.085.171.171.3.385.3.215 0 .3-.129.386-.3l2.871-6.386c.043-.086.086-.129.129-.129.086 0 .086.086.129.129l2.914 6.386c.086.171.171.3.386.3.214 0 .3-.129.385-.3l6.172-12c.385 1.071.6 2.228.6 3.471-.043 5.786-4.8 10.586-10.586 10.586zm0-13.329c-.086 0-.086-.086-.129-.128l-3.3-7.115a10.117 10.117 0 016.858 0l-3.3 7.115c-.043.042-.043.128-.129.128zm-3.471 7.714c-.086 0-.086-.085-.129-.128L3 6.47c.943-1.542 2.314-2.828 3.9-3.728l3.814 8.228c.086.172.172.215.3.215h1.972c.128 0 .214-.043.3-.215l3.771-8.228c1.586.9 2.957 2.186 3.9 3.728L15.6 16.843c-.043.086-.086.128-.129.128-.085 0-.085-.085-.128-.128L13.286 12.3c-.086-.171-.172-.214-.3-.214h-1.972c-.128 0-.214.043-.3.214l-2.057 4.543c-.043.043-.043.128-.128.128zM12 24c6.643 0 12-5.357 12-12S18.643 0 12 0 0 5.357 0 12s5.357 12 12 12z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M24 45.792c-11.952 0-21.744-9.84-21.744-21.792 0-2.688.48-5.232 1.392-7.632l12.72 25.584c.144.336.384.624.768.624s.624-.288.768-.624l5.856-13.104c.048-.144.144-.288.288-.288s.192.144.288.288l5.856 13.104c.144.336.384.624.768.624s.624-.288.768-.624l12.72-25.584A21.318 21.318 0 0145.84 24C45.744 35.952 35.952 45.792 24 45.792zm0-26.736c-.144 0-.192-.144-.288-.288l-6.816-15.36A20.473 20.473 0 0124 2.16c2.496 0 4.896.432 7.104 1.248l-6.816 15.36c-.096.192-.144.288-.288.288zm-6.96 15.792c-.144 0-.192-.144-.288-.288L5.712 12.288C7.68 9.264 10.32 6.72 13.536 4.944l7.968 17.712c.096.288.336.384.576.384h3.84c.288 0 .48-.048.624-.384l7.968-17.712a22.496 22.496 0 017.824 7.344L31.2 34.56c-.048.144-.144.288-.288.288s-.192-.144-.288-.288l-4.176-9.504c-.144-.336-.336-.384-.624-.384h-3.84c-.288 0-.48.048-.624.384l-4.032 9.504c-.048.144-.144.288-.288.288zM24 48c13.296 0 24-10.704 24-24S37.296 0 24 0 0 10.704 0 24s10.704 24 24 24z" }));
    }
    return null;
});
