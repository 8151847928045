"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Alert = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.26 14.995a.718.718 0 01-.665-.76V7.74a.671.671 0 111.33 0v6.495a.718.718 0 01-.665.76z" }),
            React.createElement("ellipse", { cx: "12.261", cy: "17.232", rx: ".73", ry: ".761" }),
            React.createElement("path", { d: "M12.276 3.38a.902.902 0 01.83.517l.018.036.02.034 8.156 14.36a.926.926 0 01-.844 1.306H4.032a.926.926 0 01-.843-1.308l8.221-14.36.02-.035.018-.036a.902.902 0 01.829-.514m0-1.33a2.23 2.23 0 00-2.021 1.254L2.01 17.706a2.256 2.256 0 002.021 3.257h16.424a2.256 2.256 0 002.024-3.25L14.3 3.31a2.23 2.23 0 00-2.024-1.26z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "48", height: "48", viewBox: "0 0 48 48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M24 32.026a1 1 0 01-1-1v-15.02a1 1 0 112 0v15.02a1 1 0 01-1 1z" }),
            React.createElement("ellipse", { cx: "24", cy: "36.535", rx: "1.409", ry: "1.47" }),
            React.createElement("path", { d: "M24.064 6.998a2.483 2.483 0 012.27 1.413l.027.054.03.052 16.395 28.867a2.53 2.53 0 01-2.293 3.6H7.507a2.53 2.53 0 01-2.29-3.607L21.742 8.51l.03-.052.027-.053a2.483 2.483 0 012.266-1.407m0-2a4.477 4.477 0 00-4.059 2.518L3.448 36.443a4.53 4.53 0 004.059 6.542h32.986a4.53 4.53 0 004.065-6.53L28.13 7.53a4.478 4.478 0 00-4.065-2.531z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M5.979 7.948a.665.665 0 01-.665-.665V1.602a.665.665 0 111.33 0v5.681a.665.665 0 01-.665.665z" }),
            React.createElement("ellipse", { cx: "5.979", cy: "10.195", rx: ".73", ry: ".761" }));
    }
    return null;
});
