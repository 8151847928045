"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Printer = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", width: "24", height: "24", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.5 11.83h2v1.33h-2z" }),
            React.createElement("path", { d: "M23.17 8.67h-1.34v10.16H19.5V15h-15v3.83H2.17V10H19.5V5l-2-2-2-2h-11v7.67H.83v11.5H4.5V23h15v-2.83h3.67zm-6.61-4.73L18 5.33h-2.83V2.55zM5.83 2.33h8v4.34h4.34v2H5.83zM18.17 21.4v.27H5.83v-5.34h12.34z" }));
    }
    return null;
});
