"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Shuffle = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.7 12.692l3.3 3.3-3.3 3.3-.941-.941 1.683-1.682h-3.675a5.239 5.239 0 01-3.485-1.324l-1.762-1.566 1-.889 1.644 1.458a3.912 3.912 0 002.6.988h3.7l-1.7-1.7zM6.868 9.652l1.644 1.458 1-.889-1.759-1.563a5.244 5.244 0 00-3.485-1.322H1v1.33h3.268a3.915 3.915 0 012.6.986zm9.9-.986h3.7l-1.7 1.7.941.941L23 8.01l-3.3-3.3-.941.939 1.685 1.686h-3.677a5.246 5.246 0 00-3.485 1.322l-6.415 5.69a3.908 3.908 0 01-2.6.988H1v1.33h3.268a5.238 5.238 0 003.485-1.324l6.414-5.69a3.917 3.917 0 012.6-.985z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.257 4.78l-3.2 3.431A4.193 4.193 0 010 9.54V8.21a2.859 2.859 0 002.084-.91l3.2-3.432a4.2 4.2 0 013.057-1.33H9.5L8.468 1.513l.941-.942L12 3.163 9.409 5.755l-.941-.942.941-.941H8.341a2.857 2.857 0 00-2.084.908zm-3.679.528l.909-.975-.43-.462A4.2 4.2 0 000 2.542v1.33a2.86 2.86 0 012.084.907zm5.89 1.92l.983.982h-1.11a2.862 2.862 0 01-2.084-.905l-.494-.53-.909.975.43.462A4.2 4.2 0 008.341 9.54h1.116l-.989.989.941.941L12 8.879 9.409 6.287z" }));
    }
    return null;
});
