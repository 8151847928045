"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const breakpoint_1 = require("../helpers/breakpoint");
const theme_provider_1 = require("../theme-provider");
var TextAppearance;
(function (TextAppearance) {
    // These textAppearances should be used in combination with the old breakpoints.
    TextAppearance["headline600"] = "headline600";
    TextAppearance["headline550"] = "headline550";
    TextAppearance["headline500"] = "headline500";
    TextAppearance["headline450"] = "headline450";
    TextAppearance["headline400"] = "headline400";
    TextAppearance["headline350"] = "headline350";
    TextAppearance["headline300"] = "headline300";
    TextAppearance["headline250"] = "headline250";
    TextAppearance["headline200"] = "headline200";
    TextAppearance["label300"] = "label300";
    TextAppearance["label250"] = "label250";
    TextAppearance["label200"] = "label200";
    TextAppearance["label150"] = "label150";
    TextAppearance["label100"] = "label100";
    TextAppearance["copy300"] = "copy300";
    TextAppearance["copy250"] = "copy250";
    TextAppearance["copy200"] = "copy200";
    TextAppearance["copy150"] = "copy150";
    TextAppearance["copy100"] = "copy100";
    // These textAppearances should be used in combination with the new breakpoints.
    TextAppearance["headline0600"] = "headline600";
    TextAppearance["headline0550"] = "headline550";
    TextAppearance["headline0500"] = "headline500";
    TextAppearance["headline0450"] = "headline450";
    TextAppearance["headline0400"] = "headline400";
    TextAppearance["headline0350"] = "headline350";
    TextAppearance["headline0300"] = "headline300";
    TextAppearance["headline0250"] = "headline250";
    TextAppearance["headline0200"] = "headline200";
    TextAppearance["label0300"] = "label300";
    TextAppearance["label0250"] = "label250";
    TextAppearance["label0200"] = "label200";
    TextAppearance["label0150"] = "label150";
    TextAppearance["label0100"] = "label100";
    TextAppearance["copy0300"] = "copy300";
    TextAppearance["copy0250"] = "copy250";
    TextAppearance["copy0200"] = "copy200";
    TextAppearance["copy0150"] = "copy150";
    TextAppearance["copy0100"] = "copy100";
})(TextAppearance = exports.TextAppearance || (exports.TextAppearance = {}));
var TextTag;
(function (TextTag) {
    TextTag["span"] = "span";
    TextTag["div"] = "div";
    TextTag["p"] = "p";
    TextTag["h1"] = "h1";
    TextTag["h2"] = "h2";
    TextTag["h3"] = "h3";
    TextTag["h4"] = "h4";
    TextTag["h5"] = "h5";
    TextTag["sub"] = "sub";
    TextTag["sup"] = "sup";
    TextTag["strong"] = "strong";
    TextTag["del"] = "del";
    TextTag["legend"] = "legend";
})(TextTag = exports.TextTag || (exports.TextTag = {}));
var TextAlignment;
(function (TextAlignment) {
    TextAlignment["center"] = "center";
    TextAlignment["left"] = "left";
    TextAlignment["right"] = "right";
    TextAlignment["start"] = "start";
    TextAlignment["end"] = "end";
})(TextAlignment = exports.TextAlignment || (exports.TextAlignment = {}));
var TextColor;
(function (TextColor) {
    TextColor["primary"] = "primary";
    TextColor["secondary"] = "secondary";
    TextColor["inherit"] = "inherit";
})(TextColor = exports.TextColor || (exports.TextColor = {}));
var WhiteSpace;
(function (WhiteSpace) {
    WhiteSpace["normal"] = "normal";
    WhiteSpace["nowrap"] = "nowrap";
    WhiteSpace["pre"] = "pre";
    WhiteSpace["preLine"] = "pre-line";
    WhiteSpace["preWrap"] = "pre-wrap";
    WhiteSpace["initial"] = "initial";
    WhiteSpace["inherit"] = "inherit";
})(WhiteSpace = exports.WhiteSpace || (exports.WhiteSpace = {}));
const BaseComponent = props => {
    const { tag = TextTag.div, children, className } = props;
    const Component = TextTag[tag];
    return React.createElement(Component, { className: className }, children);
};
BaseComponent.displayName = 'BaseComponent';
const headAppearanceList = [
    TextAppearance.headline600,
    TextAppearance.headline550,
    TextAppearance.headline500,
    TextAppearance.headline450,
    TextAppearance.headline400,
    TextAppearance.headline350,
    TextAppearance.headline300,
    TextAppearance.headline250,
    TextAppearance.headline200,
    TextAppearance.headline0600,
    TextAppearance.headline0550,
    TextAppearance.headline0500,
    TextAppearance.headline0450,
    TextAppearance.headline0400,
    TextAppearance.headline0350,
    TextAppearance.headline0300,
    TextAppearance.headline0250,
    TextAppearance.headline0200
];
function getTextDecoration(props) {
    if (typeof props.strikethrough === 'boolean') {
        return props.strikethrough ? 'line-through' : 'initial';
    }
    return;
}
const StyledTextComponent = theme_provider_1.styled(BaseComponent).withConfig({ displayName: "StyledTextComponent", componentId: "sc-1hr7i4e" }) `
	position: ${props => (props.sub || props.sup) && 'static'};
	vertical-align: ${props => {
    if (props.sup) {
        return 'super';
    }
    if (props.sub) {
        return 'sub';
    }
    return '';
}};

	/**
	 * The baselines of the VW Fonts are too low and appear broken. In certain cases,
	 * letters like "y", "g" or "q" are cut at the bottom. To fix this issue,
	 * we add a margin to the bottom that is relative to the current font-size
	 * while removing it at the same time from the top. This is to fix the baseline
	 * of the VW Fonts and to make them behave more like Helvetica or Arial.
	 * Should the font change in the future, we'll need to adjust the values accordingly
	 * or remove them completely by resetting them to the original value "margin: 0".
	 *
	 * PLEASE DO NOT CHANGE OR REMOVE THESE VALUES UNLESS YOU KNOW EXACTLY WHAT YOU ARE DOING!
	 *
	 * intended values are "margin: -0.11em 0 0.11em 0;"
	 * but to avoid occasional rounding errors in browsers we need to round these to 0.5px,
	 * i.e. round to full pixels on retina devices
	 */
	margin: ${props => {
    const { appearance, staticSize, sub, sup, theme } = props;
    if (!staticSize || sub || sup) {
        return '-0.11em 0 0.11em 0';
    }
    const fontSize = appearance && theme.textAppearances[appearance].static.fontSize;
    if (!fontSize) {
        return;
    }
    const margin = Math.round(parseInt(fontSize, 10) * 0.11 * 2) / 2;
    return `-${margin}px 0 ${margin}px 0`;
}};

	font-family: ${props => {
    const { appearance, theme } = props;
    if (!appearance) {
        return;
    }
    const textKey = theme.textAppearances[appearance];
    return textKey.fontFamily;
}};

    font-display:'swap';

	color: ${props => props.color === TextColor.inherit
    ? 'inherit'
    : props.color === TextColor.secondary
        ? props.theme.text.color.secondary
        : props.theme.text.color.primary};
	word-break: ${props => (props.wordBreak && 'break-word') || 'inherit'};

	&:lang(ko) {
		word-break: keep-all;
	}

	font-weight: ${props => props.bold
    ? props.theme.text.weight.bold
    : props.appearance && headAppearanceList.indexOf(props.appearance) > -1
        ? props.theme.text.weight.light
        : props.theme.text.weight.regular};
	font-size: ${props => {
    const { appearance, staticSize, sub, sup, theme } = props;
    if (!appearance) {
        return;
    }
    const fontKey = sub || sup ? 'reducedFontSize' : 'fontSize';
    const textKey = theme.textAppearances[appearance];
    return staticSize ? textKey.static[fontKey] : textKey[fontKey];
}};
	line-height: ${props => {
    const { appearance, staticSize, sub, sup, theme } = props;
    if (sub || sup) {
        return '0';
    }
    if (!appearance) {
        return;
    }
    const textKey = theme.textAppearances[appearance];
    return staticSize ? textKey.static.lineHeight : textKey.lineHeight;
}};
	letter-spacing: ${props => {
    const { appearance, staticSize, theme } = props;
    if (!appearance) {
        return;
    }
    const textKey = theme.textAppearances[appearance];
    const letterSpacingKey = staticSize
        ? textKey.static.letterSpacing
        : textKey.letterSpacing;
    return letterSpacingKey;
}};

	${props => {
    if (props.textAlign && breakpoint_1.isBreakpointConfig(props.textAlign)) {
        return breakpoint_1.createStylesForBreakpoints(props.textAlign, textAlign => `text-align: ${textAlign};`);
    }
    return props.textAlign && `text-align: ${props.textAlign};`;
}}

	text-decoration: ${getTextDecoration};
	white-space: ${props => props.whiteSpace};
`;
StyledTextComponent.displayName = 'StyledTextComponent';
exports.TextContext = React.createContext({});
function Text(props) {
    const { appearance, bold, children, strikethrough, sub, sup, tag, textAlign, wordBreak, whiteSpace, color, staticSize } = props;
    return (React.createElement(exports.TextContext.Consumer, null, context => {
        const textColor = color || context.textColor;
        const textAppearance = appearance || context.textAppearance || TextAppearance.copy0200;
        let textWeight = context.bold;
        if (typeof bold === 'boolean') {
            textWeight = bold;
        }
        const textTag = tag ||
            (sub && TextTag.sub) ||
            (sup && TextTag.sup) ||
            (context.textAppearance && TextTag.span);
        return (React.createElement(StyledTextComponent, { appearance: textAppearance, bold: textWeight, sub: sub, sup: sup, tag: textTag, textAlign: textAlign, wordBreak: wordBreak, whiteSpace: whiteSpace, color: textColor, staticSize: staticSize, strikethrough: strikethrough },
            React.createElement(exports.TextContext.Provider, { value: { textAppearance, bold, textColor } }, children)));
    }));
}
exports.Text = Text;
