"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.FastForward = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.348 10.949L11.924 5.783a1.171 1.171 0 00-1.7 1.051v2.683L2.7 5.783A1.171 1.171 0 001 6.834v10.332a1.171 1.171 0 001.7 1.051l7.534-3.734v2.683a1.171 1.171 0 001.7 1.051l10.424-5.166a1.173 1.173 0 00-.01-2.102zM2.33 16.914V7.086L10.229 11v2zm9.229-9.828L21.474 12l-9.915 4.914z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M1.33 3.947l3.053 1.7.644.353-.644.358L1.33 8.053zm5.029 0L10.056 6l-3.7 2.053V3.947M5.83 2.25a.785.785 0 00-.8.768v1.461L1.2 2.354a.826.826 0 00-.4-.1.785.785 0 00-.8.768v5.96a.785.785 0 00.8.768.826.826 0 00.4-.1l3.829-2.129v1.461a.785.785 0 00.8.768.832.832 0 00.4-.1L11.6 6.663a.75.75 0 000-1.326L6.23 2.354a.832.832 0 00-.4-.1z" }));
    }
    return null;
});
