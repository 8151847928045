"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ChevronRight = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.25 12a1.72 1.72 0 01-.47 1.18l-9.1 9.32-.93-.94 9.09-9.32a.34.34 0 000-.48L6.75 2.44l.93-.94 9.1 9.32a1.7 1.7 0 01.47 1.18z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9 6a1.7 1.7 0 01-.47 1.18L3.93 12 3 11.06l4.61-4.82a.36.36 0 000-.48L3 .94 3.93 0l4.6 4.82A1.72 1.72 0 019 6z" }));
    }
    return null;
});
