"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.InfoFilled = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.78 15.66V10H9.41v1.33h2v4.32h-3V17h7v-1.34zM12 6.49a.75.75 0 00-.77.73v.08a.77.77 0 001.54 0 .76.76 0 00-.69-.81zM12 1a10.64 10.64 0 0111 10.27V12a10.64 10.64 0 01-10.27 11H12A10.64 10.64 0 011 12.73V12A10.64 10.64 0 0111.27 1z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 0a6 6 0 106 6 6 6 0 00-6-6zm.67 9H5.33V5.5h1.34zM6 4.5a1 1 0 111-1 1 1 0 01-1 1z" }));
    }
    return null;
});
