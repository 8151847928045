"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.PlusService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.925 4.356a2.915 2.915 0 001.205-.669c.316-.314.335-.571.144-.762-.175-.175-.366-.147-.809.084l-.448.229a1.293 1.293 0 01-1.68-.159c-.623-.625-.611-1.443.227-2.28a3.357 3.357 0 011.21-.789l.412.863a2.747 2.747 0 00-.995.6c-.267.267-.286.513-.1.7.134.134.29.178.566.036l.45-.229c.859-.44 1.366-.517 1.889.008.694.7.578 1.562-.242 2.38a4.026 4.026 0 01-1.416.849zm-8.866 8.569l.984.987-.809.808L0 11.478l1.292-1.289a1.445 1.445 0 012.285-.043 1.46 1.46 0 01-.036 2.3zm-.322-1.934a.563.563 0 00-.921-.019l-.354.352.941.944.353-.353a.571.571 0 00-.019-.924zM3.2 8.283l3.233 3.242 2.152-2.144-.652-.653-1.339 1.335-2.581-2.588zm4.449-.8a1.8 1.8 0 002.885.091 1.8 1.8 0 00-.082-2.886L8.633 2.866l-.8.8 1.845 1.856c.428.43.657.927.194 1.388s-.958.23-1.386-.2L6.637 4.857l-.8.8 1.82 1.825m12.169 3.8h-.856l-1.86-2.171.554-.645 1.306-1.525H24a7.459 7.459 0 00-.5-1.33 7.137 7.137 0 00-5.811-3.941v1.3A5.926 5.926 0 0122 5.613h-3.647l-1.638 1.911-.8.939c-.022.027-.032.058-.052.087a1 1 0 00.052 1.215l1.638 1.91.8.938H22a6.043 6.043 0 01-5.208 2.7 7.038 7.038 0 01-.928-.066 6.488 6.488 0 01-1.5-.378 5.793 5.793 0 01-3.294-3.248 6.486 6.486 0 01-.393-1.488v-.01l-1.116 1.105v.007l-5.173 5.176a3.5 3.5 0 000 4.957l.2.194a3.5 3.5 0 004.957 0l5.205-5.206a8.292 8.292 0 002.041.257 7.244 7.244 0 006.711-4 7.42 7.42 0 00.5-1.329h-4.181zM8.6 20.623a2.175 2.175 0 01-3.076 0l-.195-.2a2.175 2.175 0 010-3.076l4.745-4.745a6.95 6.95 0 003.291 3.253zM7.049 17.9a1 1 0 101 1 1 1 0 00-1-1" }));
    }
    return null;
});
