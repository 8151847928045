"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isDynamicTokenSubtree(subtree) {
    return !Array.isArray(subtree) && typeof subtree !== 'string';
}
function getDynamicTokens(tokens, path) {
    return Object.keys(tokens).reduce((result, key) => {
        const newPath = path ? `${path}.${key}` : key;
        const currentToken = tokens[key];
        if (Array.isArray(currentToken)) {
            currentToken.forEach(dynamic => {
                result[dynamic.breakpoint] = result[dynamic.breakpoint] || {};
                result[dynamic.breakpoint] = Object.assign(Object.assign({}, result[dynamic.breakpoint]), { [newPath]: dynamic.value });
            });
            return result;
        }
        if (isDynamicTokenSubtree(currentToken)) {
            const subtreeResults = getDynamicTokens(currentToken, newPath);
            Object.keys(subtreeResults).forEach(breakpoint => {
                result[breakpoint] = Object.assign(Object.assign({}, (result[breakpoint] || {})), subtreeResults[breakpoint]);
            });
            return result;
        }
        return result;
    }, {});
}
exports.getDynamicTokens = getDynamicTokens;
