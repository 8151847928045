"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Entertainment = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M20.84 19.06V.71L8.38 4.42a2 2 0 00-1.71 2V17.8A3 3 0 005 17.28a3 3 0 103 3.06V10.25L19.51 7v9.64a3 3 0 00-1.67-.51 3 3 0 103 2.95zM5 22a1.68 1.68 0 111.67-1.67A1.67 1.67 0 015 22zM8 8.87V6.45c0-.14 0-.57.72-.75l10.79-3.2v3.1zm9.84 11.92a1.68 1.68 0 111.68-1.67 1.67 1.67 0 01-1.68 1.67z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.17 1.89a1.37 1.37 0 00-1.07 1.3v4.6a1.92 1.92 0 00-.72-.14 2 2 0 100 4.1 2 2 0 002.05-2V5.86l4.71-1.35V7.2a2.09 2.09 0 00-.72-.13 2.05 2.05 0 102.05 2.06V0zm-1.79 8.53a.72.72 0 11.72-.72.72.72 0 01-.72.72zm2-6v-1.2L9.14 1.8v1.33zm4 5.38a.72.72 0 110-1.44.72.72 0 010 1.44z" }));
    }
    return null;
});
