"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Hybrid = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.25 9h-2.14V5.5h-1.33V9h-2.56V5.5h-1.33V9h-2.14v1.33h.82v3.05a2.89 2.89 0 002.91 2.84h.64a6.86 6.86 0 11-12.2-5.86l5.25-7.45h.72L12 4.38l1.09-.76-1.51-2.09h-2.1l-5.65 8a8.2 8.2 0 0012.5 10.52 8.31 8.31 0 002.17-3.9 2.89 2.89 0 002.91-2.84v-3.03h.82zm-2.15 4.33a1.54 1.54 0 01-1.58 1.5h-2a1.54 1.54 0 01-1.58-1.5v-3.05h5.2z" }),
            React.createElement("path", { d: "M5.75 11.58a5.18 5.18 0 00.58 6.67l.94-.95a3.84 3.84 0 01-.43-5z" }));
    }
    return null;
});
