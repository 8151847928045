"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Safety = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.67 2.177a29.766 29.766 0 01-7.23-.89l-.44-.11-.44.11a29.766 29.766 0 01-7.23.89A1.33 1.33 0 003 3.507v8.509a8.506 8.506 0 003.285 6.716l4.9 3.81a1.328 1.328 0 001.634 0l4.9-3.81A8.506 8.506 0 0021 12.016V3.507a1.33 1.33 0 00-1.33-1.33zm0 9.839a7.131 7.131 0 01-2.77 5.666L12 21.493l-4.9-3.811a7.131 7.131 0 01-2.77-5.666V3.507a31.168 31.168 0 007.553-.929l.117-.03.117.03a31.168 31.168 0 007.553.929zm-8.72-.488l4.112-4.113.938.941-5.05 5.053L8 10.456l.941-.941z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M5.424 5.852L7.745 3.53l.941.941-3.262 3.262-2.056-2.055.941-.941zM11 2.044v4.534a3.638 3.638 0 01-1.387 2.867L6.607 11.79a.982.982 0 01-1.214 0L2.387 9.445A3.638 3.638 0 011 6.578V2.044a1.012 1.012 0 011-1.022A8.125 8.125 0 005.4.276L6 0l.6.276a8.125 8.125 0 003.4.746 1.012 1.012 0 011 1.022zm-1.33.3a9.4 9.4 0 01-3.625-.861L6 1.464l-.045.021a9.4 9.4 0 01-3.625.861v4.232A2.316 2.316 0 003.206 8.4L6 10.577 8.794 8.4a2.316 2.316 0 00.876-1.822z" }));
    }
    return null;
});
