"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ShareAndroid = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.83 11.1A4 4 0 0024 7a4 4 0 00-4.12-4.12A4 4 0 0015.7 7v.32L11 8.49A5.45 5.45 0 005.72 5a5.48 5.48 0 00-5.67 5.67 5.48 5.48 0 005.67 5.66 5.67 5.67 0 003.85-1.43l4.64 3.24a4.12 4.12 0 00-.16 1.06 3.55 3.55 0 003.67 3.67 3.54 3.54 0 003.66-3.67 3.54 3.54 0 00-3.66-3.66 3.57 3.57 0 00-2.92 1.39l-4.34-3a5.83 5.83 0 00.92-3.23 6.12 6.12 0 00-.08-.88L16 8.6a4 4 0 003.83 2.5zm0-6.92A2.67 2.67 0 0122.62 7a2.67 2.67 0 01-2.79 2.8A2.68 2.68 0 0117 7a2.67 2.67 0 012.83-2.82zm-2.11 12.69a2.23 2.23 0 012.33 2.33 2.23 2.23 0 01-2.33 2.34 2.24 2.24 0 01-2.34-2.34 2.23 2.23 0 012.34-2.33zM5.72 15a4.15 4.15 0 01-4.34-4.33 4.15 4.15 0 014.34-4.34 4.14 4.14 0 014.33 4.34A4.14 4.14 0 015.72 15z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.59 5.33a2.21 2.21 0 002.26-2.26A2.2 2.2 0 009.59.82a2.2 2.2 0 00-2.25 2.25v.24l-1.64.58a2.88 2.88 0 00-2.58-1.5 2.89 2.89 0 00-3 3 2.89 2.89 0 003 3 3 3 0 001.77-.57l1.72 1.11a2.3 2.3 0 000 .26 2 2 0 002 2 2 2 0 10-1.42-3.47l-1.44-1a3 3 0 00.33-1.36v-.18l1.81-.63a2.23 2.23 0 001.7.78zM3.12 7a1.56 1.56 0 01-1.63-1.65 1.56 1.56 0 011.63-1.63 1.56 1.56 0 011.63 1.63A1.56 1.56 0 013.12 7zm5.5 1.5a.71.71 0 110 1.42.71.71 0 010-1.42zm1-6.33a.9.9 0 01.93.92.93.93 0 11-1.85 0 .9.9 0 01.89-.94z" }));
    }
    return null;
});
