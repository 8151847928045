"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.UsedCarCommercial = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "11.89", cy: "15.78", r: "1" }),
            React.createElement("path", { d: "M20.56 12.78v1.49c-1.54.23-3.14.41-4.76.53l.1 1.33c1.59-.11 3.15-.29 4.66-.51v5.76a.29.29 0 01-.28.28h-3a.29.29 0 01-.28-.28v-1.84H6.78v1.82a.31.31 0 01-.3.3h-3a.29.29 0 01-.28-.28v-5.73q2.3.33 4.77.51l.09-1.34c-1.68-.12-3.3-.3-4.86-.52v-3.09l.25-1.58c2 .13 4 .19 6.09.23V8.51c-2 0-3.93-.09-5.88-.21l.24-1.54a5.41 5.41 0 011.15-2.55l1.51-1.87h3V1h-3a1.24 1.24 0 00-1 .46L4 3.36a6.74 6.74 0 00-1.39 3.18l-.26 1.67C1.64 8.16.93 8.11.22 8H.16v1.34l2 .16-.27 1.6v10.28A1.62 1.62 0 003.5 23h3a1.64 1.64 0 001.63-1.64v-.48h7.56v.5A1.62 1.62 0 0017.28 23h3a1.62 1.62 0 001.61-1.62v-8.6z" }),
            React.createElement("path", { d: "M23.89 4.65L20.24 1l-3.66 3.65.94.94 2.05-2v2.28a2.84 2.84 0 11-5.67 0 2.87 2.87 0 01.92-2.09l.46-.45-.94-.94-.44.43a4.17 4.17 0 107 3.05V3.55l2.05 2z" }));
    }
    return null;
});
