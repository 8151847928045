"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.DriverAssistance = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.13 11.53a6.38 6.38 0 01.87 3.23v6.54A1.66 1.66 0 019.39 23h-2a1.66 1.66 0 01-1.61-1.7v-.52H0v-1.33h7.11v1.85a.34.34 0 00.28.37h2a.35.35 0 00.28-.37v-6.06c-1.51.22-3.07.41-4.66.53l-.1-1.32q2.4-.19 4.68-.54A5 5 0 009 12.2l-.05-.08v-.08l-.74-1.76a67.63 67.63 0 01-8.21.54V9.47A62.89 62.89 0 007.63 9L6.49 6.27A2 2 0 005 5.33H0V4h5.1a3.32 3.32 0 012.58 1.66L9 8.83c.87-.13 1.73-.26 2.6-.42l.24 1.3c-.77.15-1.54.26-2.31.38zm9.1-8.64l-.94.94a11.56 11.56 0 010 16.34l.94.94a12.89 12.89 0 000-18.22zM19 12a8.84 8.84 0 00-2.61-6.29l-.94.94a7.56 7.56 0 010 10.7l.94.94A8.84 8.84 0 0019 12z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 6.6v4.53a.88.88 0 01-.88.87H3a.88.88 0 01-.88-.87v-.26H0V9.54h3.48v1.13h1.19V8.09c-.68.1-1.38.18-2.09.24L2.48 7c.75-.06 1.48-.15 2.19-.25V6.6a1.78 1.78 0 00-.27-.95l-.05-.08v-.19A35.71 35.71 0 010 5.66V4.32a32.78 32.78 0 003.7-.21L3 2.6a.48.48 0 00-.31-.17H0V1.1h2.78a1.82 1.82 0 011.41.9l.88 2L6 3.79v1.34h-.33A3 3 0 016 6.6zm6-.6a6.21 6.21 0 00-1.83-4.42l-.94.94a4.93 4.93 0 010 7l.94.94A6.21 6.21 0 0012 6zM8.29 3.46l-.94.94a2.27 2.27 0 010 3.2l.94.94a3.6 3.6 0 000-5.08z" }));
    }
    return null;
});
