"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ChevronLeft = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.25 21.56l-.93.94-9.1-9.32a1.71 1.71 0 010-2.36l9.1-9.32.93.94-9.09 9.32a.34.34 0 000 .48z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9 .94L4.39 5.76a.37.37 0 000 .48L9 11.06l-.93.94-4.6-4.82a1.71 1.71 0 010-2.36L8.07 0z" }));
    }
    return null;
});
