"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Upload = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.67 18.35V21H4.33v-2.65H3v2.82a1.16 1.16 0 001.17 1.16h15.66A1.16 1.16 0 0021 21.17v-2.82z" }),
            React.createElement("path", { d: "M12 2.33c.41 0 .49 0 4.47 4.21l-1 .92c-.73-.77-2-2.09-2.85-3V19h-1.28V4.48c-.76.77-1.85 1.92-2.86 3l-1-.92c4.04-4.23 4.09-4.23 4.52-4.23z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.49 9.15v1.52h-9V9.15H.18V11a1 1 0 001 1h9.68a1 1 0 001-1V9.15zM6 0c.37 0 .41 0 3 2.68L8 3.6 6.67 2.21v6.73H5.34V2.21L4 3.6l-1-.92C5.59 0 5.62 0 6 0z" }));
    }
    return null;
});
