"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Consumption = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.984 14.42c-.018-3.742-.043-8.866-.015-9.724a3.358 3.358 0 00-.8-2.773A3.8 3.8 0 0019.356 1v1.267c1.074.041 1.554.212 1.831.489a2.306 2.306 0 01.48 1.754 3.226 3.226 0 000 6.2c0 1.234.011 2.517.017 3.713.01 1.954.007 3.6.016 4.205.028 1.783-1.142 1.871-1.5 1.871-.383 0-1.487-.009-1.494-1.79l-.011-.683a3.144 3.144 0 00-.874-2.349 2.791 2.791 0 00-1.89-.79V3.056A2.039 2.039 0 0013.9 1.017H3.043A2.043 2.043 0 001 3.06V23h14.937v-6.838c.287.01 1.461.156 1.464 1.88l.011.687a3.013 3.013 0 00.82 2.271 2.57 2.57 0 001.982.819 2.478 2.478 0 001.962-.787 3.014 3.014 0 00.824-2.4c0-.61-.007-2.253-.016-4.212zm-8.379 7.25H2.332V3.057a.713.713 0 01.711-.711h10.851a.713.713 0 01.711.711zM20.66 7.612a1.9 1.9 0 011-1.675V9.29a1.906 1.906 0 01-1-1.678zM8.518 5.223A1.772 1.772 0 1010.266 7a1.76 1.76 0 00-1.748-1.777z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M3.934 2.6a.949.949 0 10.923.949.936.936 0 00-.923-.949zM12 2.46a2.354 2.354 0 00-.558-1.668 2.6 2.6 0 00-2.01-.776l-.01 1.325a1.376 1.376 0 011.025.327.848.848 0 01.2.468 1.832 1.832 0 00.018 3.454l.006 3.165a.272.272 0 01-.274.271l-2.531.015V1.556A1.557 1.557 0 006.311 0H1.558A1.558 1.558 0 000 1.556V12h7.869v-1.635l2.531-.015a1.6 1.6 0 001.6-1.6zm-5.461 8.212H1.33V1.556a.228.228 0 01.228-.227h4.754a.228.228 0 01.228.227v9.116z" }));
    }
    return null;
});
