"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.ArrowUp = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12.038 2.003c-.877 0-6.236 5.94-6.29 6l.991.888a80.828 80.828 0 014.634-4.783v17.895h1.33V4.125c1.018.97 2.7 2.663 4.583 4.766l.99-.887c-.053-.06-5.383-6.001-6.238-6.001z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.608 4.29c-2.93-3.273-3.363-3.273-3.527-3.273-.514 0-2.216 1.779-3.553 3.272l.99.887a44.965 44.965 0 011.899-1.992v8.074h1.33v-8.06a49.517 49.517 0 011.871 1.978z" }));
    }
    return null;
});
