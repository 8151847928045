"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Brochure = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8 1h14v18h-6v4H2V5h6v-.078zm8 4v12.67h4.67V2.33h-9.555zm-6.67-.078V5h3.897L9.33 2.87zm-6 1.408v15.34h11.34V6.33H8zm1.67 4V9h8v1.33zm0 3V12h8v1.33zm0 3V15h6v1.33z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "48", viewBox: "0 0 48 48", width: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M16 2h28v36H32v8H4V10h12v-.155zm16 8v26h10V4H20.739zm-14-.155V10h9.748L18 4.807zM6 12v32h24V12H16zm4 19v-2h16.021v2zm0 4v-2h16.021v2zm0 4v-2h12v2zm1.47-15.637l4.479-8.391 3.37 5.615 3-2.001 2.956 4.928c.179.3.508.486.858.486H27v2h-.867a3.016 3.016 0 01-2.573-1.456l-1.878-3.13-3 2-2.631-4.386-2.852 5.336a4.34 4.34 0 01-.56.737 2.955 2.955 0 01-2.144.899H10v-2h.495a.95.95 0 00.693-.273c.109-.116.207-.244.282-.364zM24.5 18a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" }));
    }
    return null;
});
