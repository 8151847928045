"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.StarFilled = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.4 10.17l-.39-1.19-7.8-.12-2.58-7.43h-1.26L8.86 8.81l-7.87.17-.39 1.19 6.24 4.68-2.27 7.53 1.01.74 6.38-4.5 6.46 4.5 1.01-.74-2.3-7.45z" }));
    }
    if (variant === "large") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 48 48", width: "48", height: "48", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M45.94 19.82l-1.1-3.36-13.38-.3a1.4 1.4 0 01-1.3-.95L25.67 2h-3.34l-4.5 13.23a1.41 1.41 0 01-1.3 1l-13.76.28-1 3.13 11 8.59a1.39 1.39 0 01.48 1.5l-3.82 13 3 2.15 11.21-8a.72.72 0 01.84 0l11.2 8 3-2.15-3.82-13a1.41 1.41 0 01.49-1.51z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.93 5.04l-.39-1.2-3.68-.07L6.65.3H5.4L4.19 3.77l-3.68.07-.39 1.2 2.93 2.22-1.06 3.51 1.01.74 3.02-2.1 3.02 2.1 1.02-.74-1.07-3.51z" }));
    }
    return null;
});
