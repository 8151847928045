"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Fax = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.33 7v14.5a.33.33 0 01-.33.33H6.67V7.67H8V12h13V5l-2-2-2-2H8v5.33H6.67V6A1.67 1.67 0 005 4.33H2A1.67 1.67 0 00.33 6v15.5A1.67 1.67 0 002 23.17h3a1.75 1.75 0 00.33 0H22a1.67 1.67 0 001.67-1.67V7zm-4.27-3.06l1.39 1.39h-2.78V2.55zM9.33 2.33h6v4.34h4.34v4H9.33zM5 21.83H2a.33.33 0 01-.33-.33V6A.33.33 0 012 5.67h3a.33.33 0 01.33.33v15.5a.33.33 0 01-.33.33z" }),
            React.createElement("path", { d: "M10 14.83h2v1.33h-2zM13.5 14.83h2v1.33h-2zM17 14.83h2v1.33h-2zM10 17.83h2v1.33h-2zM13.5 17.83h2v1.33h-2zM17 17.83h2v1.33h-2z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 3.31L9 0H3.5v3H0v9h12V7h-1.33v3.67H4.83v-5H12zM3.5 7v3.67H1.33V4.33H3.5zm7.17-2.66H4.83v-3h3.58l2.26 2.49z" }),
            React.createElement("path", { d: "M6 7h3.34v1.33H6z" }));
    }
    return null;
});
