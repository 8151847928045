"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.EnterFullscreen = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.2 8.5h-1.4V2.2h-6.3V.9h6.9c.5 0 .8.3.8.8zM8.5 23.2H1.6c-.5 0-.8-.3-.8-.8v-6.9h1.4v6.3h6.3zM2.2 8.5H.9V1.6c0-.5.3-.8.8-.8h6.8v1.3H2.2zM22.4 23.2h-6.9v-1.3h6.3v-6.4h1.3v6.9c.1.5-.2.8-.7.8z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M11.7 4h-1.4V1.7H8V.4h2.9c.5 0 .8.3.8.8zM4 11.7H1.1c-.5 0-.8-.3-.8-.8V8h1.4v2.3H4zM1.7 4H.4V1.1c0-.5.3-.8.8-.8H4v1.3H1.7zM10.9 11.7H8v-1.3h2.3V8h1.3v2.9c.1.5-.2.8-.7.8z" }));
    }
    return null;
});
