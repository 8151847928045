"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.LoadingVolume = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M21.94 5.79a1.32 1.32 0 00-.71-1.17L13.4.6A3.48 3.48 0 0012 .3a3.52 3.52 0 00-1.4.3l-7.83 4a1.32 1.32 0 00-.71 1.17v7.3a1.52 1.52 0 00.83 1.36l7.34 3.81a4.82 4.82 0 001.76.41 4.76 4.76 0 001.76-.41l7.35-3.81a1.52 1.52 0 00.83-1.36v-2.65zm-9.29-4.3a.26.26 0 01.29 0l7.83 4a.3.3 0 01.17.28V6a1.51 1.51 0 00-1.23 0l-2.85 1.52-4.21-2.18a.35.35 0 01-.13-.28V1.77c0-.2.09-.26.13-.28zm-1 8.36L8.23 8.08l3.58-1.85a1.33 1.33 0 00.19-.14 1.33 1.33 0 00.19.14l3.58 1.85-3.41 1.77a.87.87 0 01-.37.07.91.91 0 01-.34-.07zM3.23 5.51l7.83-4a.29.29 0 01.31 0 .3.3 0 01.11.27v3.28a.36.36 0 01-.13.28L7.14 7.52 4.3 6a1.53 1.53 0 00-1.24 0v-.21a.3.3 0 01.17-.28zm8.24 11.39a.51.51 0 01-.25.45.52.52 0 01-.52 0l-7.34-3.79a.52.52 0 01-.29-.47V7.4a.53.53 0 01.25-.4.52.52 0 01.52 0l7.35 3.81a.53.53 0 01.28.47zm9.47-3.81a.52.52 0 01-.29.47l-7.35 3.81a.52.52 0 01-.52 0 .53.53 0 01-.25-.45v-5.71a.52.52 0 01.29-.47l7.35-3.81a.53.53 0 01.24-.06.54.54 0 01.27.08.52.52 0 01.26.45z" }),
            React.createElement("path", { d: "M23.58 17c-.16-.29-.19-.35-3-1.1l-.34 1.29 1.07.29L12 22.21l-9.39-4.67c.44-.12.93-.26 1.39-.37l-.34-1.29C.62 16.67.58 16.74.42 17s-.2.36.77 3.35L2.45 20c-.13-.4-.26-.82-.38-1.22L12 23.7l9.94-5-.33 1 1.27.41c.89-2.77.86-2.83.7-3.11z" }));
    }
    return null;
});
