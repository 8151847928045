"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.CertifiedRepair = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M13.93 12.6a5.81 5.81 0 01-3.29 3.25 6.59 6.59 0 01-1.5.37 7.14 7.14 0 01-.93.07A6 6 0 013 13.59h3.64l.81-.94 1.63-1.91a1 1 0 00.06-1.21.47.47 0 00-.06-.09l-.8-.94-1.64-1.91H3a6.06 6.06 0 015.21-2.7 6.43 6.43 0 012.88.67 5.93 5.93 0 01.54-1.2 7.78 7.78 0 00-3.42-.77 7.24 7.24 0 00-6.71 4A6.86 6.86 0 001 7.92h5l1.34 1.53.55.64L6 12.26H1a6.63 6.63 0 00.5 1.33 7.23 7.23 0 006.71 4 8.44 8.44 0 002-.25l5.2 5.2a3.5 3.5 0 005 0l.2-.19a3.51 3.51 0 000-5l-4.53-4.53a6.47 6.47 0 01-2-.71c-.08.19-.08.34-.15.49zm6.37 7.27a2.16 2.16 0 01-.63 1.54l-.2.19a2.17 2.17 0 01-3.08 0l-4.76-4.76a7 7 0 003.29-3.25l4.75 4.74a2.16 2.16 0 01.63 1.54z" }),
            React.createElement("path", { d: "M18 20.88a1 1 0 10-1-1 1 1 0 001 1zM17.1 9.29l-3.05-2.51.85-1.03 2.11 1.74 3.01-3 .94.94z" }),
            React.createElement("path", { d: "M17.46 12.08A5.54 5.54 0 1123 6.54a5.54 5.54 0 01-5.54 5.54zm0-9.75a4.21 4.21 0 104.21 4.21 4.21 4.21 0 00-4.21-4.21z" }));
    }
    return null;
});
