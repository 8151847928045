"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.VolumeMute = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M13.994 3.212a1.03 1.03 0 00-.49.124l-6.33 3.426a2.057 2.057 0 01-.979.248H2.033a1.014 1.014 0 00-1.02 1.008v7.964a1.014 1.014 0 001.02 1.008h4.163a2.058 2.058 0 01.979.248l6.33 3.426a1.026 1.026 0 001.383-.4.997.997 0 00.126-.484V4.22a1.014 1.014 0 00-1.02-1.008zM2.344 8.34H5.71v7.32H2.343zM13.683 12v7.248l-5.876-3.18a3.376 3.376 0 00-.768-.3V8.232a3.385 3.385 0 00.768-.3l5.875-3.18zM23.012 9.952l-.942-.939-2.046 2.047-2.046-2.047-.942.939L19.084 12l-2.048 2.048.942.939 2.046-2.047 2.046 2.047.942-.939L20.964 12z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.979 3.69V8.31l-1.153-.793-.058-.04-.062-.033a2.364 2.364 0 00-1.122-.284H1.33V4.84h1.254a2.364 2.364 0 001.122-.283l.062-.034.058-.04L4.98 3.69M5.8 1.618a.513.513 0 00-.245.061L3.073 3.388a1.025 1.025 0 01-.489.123H.508A.505.505 0 000 4.014v3.972a.505.505 0 00.508.503h2.076a1.025 1.025 0 01.489.123l2.483 1.709a.511.511 0 00.69-.2.498.498 0 00.063-.241V2.12a.505.505 0 00-.508-.502zM11.976 4.863l-.94-.939L9.9 5.06 8.764 3.924l-.941.939L8.96 6 7.823 7.137l.941.939L9.9 6.94l1.136 1.136.94-.939L10.84 6z" }));
    }
    return null;
});
