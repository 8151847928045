"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.VolumeMedium = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M13.994 3.212a1.03 1.03 0 00-.49.124l-6.33 3.426a2.057 2.057 0 01-.979.248H2.033a1.014 1.014 0 00-1.02 1.008v7.964a1.014 1.014 0 001.02 1.008h4.163a2.058 2.058 0 01.979.248l6.33 3.426a1.026 1.026 0 001.383-.4.997.997 0 00.126-.484V4.22a1.014 1.014 0 00-1.02-1.008zM2.344 8.34H5.71v7.32H2.343zM13.683 12v7.248l-5.876-3.18a3.376 3.376 0 00-.768-.3V8.232a3.385 3.385 0 00.768-.3l5.875-3.18zM17.509 16.451l-.947-.934a5.33 5.33 0 001.443-3.52 5.289 5.289 0 00-1.44-3.52l.944-.937a6.705 6.705 0 011.826 4.457 6.625 6.625 0 01-1.826 4.454z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "12", height: "12", viewBox: "0 0 12 12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.979 3.69v4.62l-1.153-.794-.058-.04-.062-.034a2.364 2.364 0 00-1.122-.284H1.33V4.841h1.254a2.362 2.362 0 001.122-.284l.062-.033.058-.04L4.98 3.69M5.8 1.618a.513.513 0 00-.245.061L3.073 3.388a1.025 1.025 0 01-.489.123H.508A.505.505 0 000 4.014v3.972a.505.505 0 00.508.502h2.076a1.026 1.026 0 01.489.124l2.483 1.709a.511.511 0 00.69-.2.498.498 0 00.063-.241V2.12a.505.505 0 00-.508-.503zM7.736 9.263L6.978 8.17A2.508 2.508 0 008.013 6a2.504 2.504 0 00-1.028-2.166l.747-1.101A3.817 3.817 0 019.342 6a3.88 3.88 0 01-1.607 3.263z" }));
    }
    return null;
});
