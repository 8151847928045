import React from "react";
import PropTypes from "prop-types";

//CSS
import GlobalStyle from "../globals/GlobalStyle";

//CORE COMPONENTS
import {
  styled,
  ThemeProvider,
} from "../../components-core/packages/components-core/dist/theme-provider/index";
import { IntegratorRoot } from "../../components-core/packages/components-core/dist/integrator-root/index";

//CUSTOM COMPONENTS
import Footer from "../../src/components/footer/Footer";
import Hero from "../../src/components/hero/Hero";

const TopContainer = styled.div`
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
`;

const Layout = ({ children, theme }) => {
  return (
    <IntegratorRoot>
      <GlobalStyle />
      <ThemeProvider theme={"main"}>
        <TopContainer>
          <Hero theme={theme} />
          {children}
          <Footer />
        </TopContainer>
      </ThemeProvider>
    </IntegratorRoot>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
