"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Pin = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 1c4 0 7 3 7 7.1 0 3.1-1.1 6.5-2.9 9.8-1.5 2.8-3.2 5.1-4.1 5.1s-2.6-2.2-4.1-5.1C6.1 14.6 5 11.2 5 8.1 5 4 8 1 12 1zm0 1.3C8.7 2.3 6.3 4.7 6.3 8c0 2.7.9 5.9 2.7 9.2 1.4 2.7 2.5 3.9 3 4.3.5-.4 1.5-1.6 3-4.3 1.8-3.3 2.7-6.5 2.7-9.2 0-3.3-2.4-5.7-5.7-5.7zM12 5c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "12", viewBox: "0 0 12 12", width: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6 .5C8 .5 9.5 2 9.5 4c0 1.6-.5 3.3-1.4 5-.8 1.4-1.6 2.5-2.1 2.5S4.7 10.4 3.9 9C3 7.3 2.5 5.6 2.5 4 2.5 2 4 .5 6 .5zm0 1.3c-1.3 0-2.2.9-2.2 2.2s.4 2.7 1.3 4.3c.4.7.7 1.2.9 1.5.2-.3.5-.8.9-1.5.8-1.6 1.3-3 1.3-4.3S7.3 1.8 6 1.8zm0 1.1c.6 0 1.1.5 1.1 1.1S6.6 5 6 5s-1.1-.5-1.1-1.1.5-1 1.1-1z" }));
    }
    return null;
});
