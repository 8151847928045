"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Back = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M15.27 10H4.18c.82-.87 2.52-2.54 6-5.76l-.9-1c-7.12 6.62-7.12 7-7.12 7.39s0 .77 7.12 7.4l.9-1c-3.15-2.86-5-4.73-6-5.74h11.09c4.36 0 5.27 2.88 5.27 5.29v3.64h1.33v-3.61c0-4.21-2.41-6.61-6.6-6.61z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7.83 4.58H2.31C2.86 4 3.74 3.19 5.16 1.86l-.9-1C.18 4.69.18 4.87.18 5.24s0 .59 4.08 4.36l.9-1a76.092 76.092 0 01-2.87-2.69h5.54a2.36 2.36 0 012.66 2.68v2h1.33v-2a3.68 3.68 0 00-3.99-4.01z" }));
    }
    return null;
});
