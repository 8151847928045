"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.PriceTypeSwitch = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "24", viewBox: "0 0 24 24", width: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M2.598 6.605h13.106c3.1 0 3.927.871 3.927 3.314h1.33c0-3.162-1.406-4.644-5.257-4.644H2.598zM21.402 17.244H8.296c-3.1 0-3.927-.872-3.927-3.314h-1.33c0 3.162 1.406 4.644 5.257 4.644h13.106z" }),
            React.createElement("path", { d: "M3.69 4.996c.808-.81 2.022-1.982 3.643-3.512L6.42.516C2.407 4.306 1.335 5.382 1.335 5.9c0 .518 1.072 1.597 5.085 5.39l.914-.967A176.794 176.794 0 013.69 6.806c-.384-.386-.674-.69-.868-.906.194-.216.484-.518.868-.904zM19.978 16.996c-.807-.81-2.022-1.982-3.643-3.512l.913-.968c4.014 3.79 5.086 4.866 5.086 5.384 0 .518-1.073 1.597-5.085 5.39l-.914-.967a176.795 176.795 0 003.643-3.517c.384-.386.674-.69.867-.906a25.407 25.407 0 00-.867-.904z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, height: "12", viewBox: "0 0 12 12", width: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9.284 9.565H4.102c-1.945 0-2.731-.86-2.731-2.522H2.7c0 .953.218 1.192 1.401 1.192h5.12a176.75 176.75 0 00-1.429-1.456l.944-.938c2.591 2.609 2.697 2.72 2.697 3.11 0 .4-.088.488-2.707 3.014l-.923-.958c.45-.434.944-.915 1.48-1.442zM2.717 2.435h5.18c1.777 0 2.732.95 2.732 2.522H9.3c0-.836-.358-1.192-1.401-1.192H2.774c.376.388.854.874 1.433 1.456l-.944.938C.672 3.55.566 3.439.566 3.049c0-.4.088-.488 2.707-3.014l.923.958c-.603.581-1.096 1.062-1.48 1.442z" }));
    }
    return null;
});
