"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.AwardWinnerPremium = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M19.69 5.06L20.12 1H3.88l.43 4.06a3.68 3.68 0 00.3 7.35h.53a5.12 5.12 0 004.33 4A1.91 1.91 0 009 17.54a1.84 1.84 0 00.39 1.09h-.11a4.39 4.39 0 00-4.34 4.43V24h14.12v-.94a4.39 4.39 0 00-4.34-4.43h-.15a1.84 1.84 0 00.43-1.09 1.91 1.91 0 00-.43-1.16 5.12 5.12 0 004.33-4h.53a3.68 3.68 0 00.3-7.35zm-2 17.6H6.28a3 3 0 013-2.69h5.44a3 3 0 013 2.69zM2.31 8.72a2.33 2.33 0 012.14-2.33l.49 4.68h-.33a2.32 2.32 0 01-2.3-2.35zm11.34 8.82c0 .38-.65.92-1.65.92s-1.65-.54-1.65-.92.65-.93 1.65-.93 1.65.55 1.65.93zm.24-2.44h-3.78a3.84 3.84 0 01-3.8-3.48l-1-9.28h13.35l-1 9.28a3.84 3.84 0 01-3.77 3.48zm5.5-4h-.33l.49-4.68a2.34 2.34 0 01-.16 4.68z" }),
            React.createElement("path", { d: "M12.88 5.2h-.86l-1.45.54v1.07h.11l.99-.36h.02v4.36h-1.31v1h3.74v-1h-1.24z" }));
    }
    return null;
});
