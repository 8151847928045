"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Payload = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M20.17 8.91a2.12 2.12 0 00-2.11-1.78h-4.15a3.43 3.43 0 001.67-3A3.44 3.44 0 0012 .53a3.44 3.44 0 00-3.58 3.55 3.44 3.44 0 001.69 3.05H5.94a2.14 2.14 0 00-2.12 1.79L1.51 22.57a.78.78 0 00.78.9h19.42a.78.78 0 00.78-.9zM12 1.86a2.12 2.12 0 012.25 2.22A2.12 2.12 0 0112 6.3a2.14 2.14 0 01-2.25-2.22A2.12 2.12 0 0112 1.86zM2.93 22.14l2.21-13a.8.8 0 01.8-.67h12.12a.79.79 0 01.8.67l2.21 13z" }),
            React.createElement("path", { d: "M15 11.93v1.31h-2.22v6.37h-1.57v-6.37H9.05v-1.31z" }));
    }
    return null;
});
