"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Settings = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M13 23.65h-2A2 2 0 019 22l-.19-1.15c-.27-.1-.54-.2-.8-.32l-1 .68A2 2 0 014.48 21l-1.37-1.41A2 2 0 012.89 17l.67-.93a8.76 8.76 0 01-.35-.84L2 15a2 2 0 01-1.65-2v-1.92a2 2 0 011.65-2l1.12-.19c.11-.29.22-.57.35-.85l-.69-1A2 2 0 013 4.47l1.41-1.36A2.07 2.07 0 017 2.89l1 .67a8.93 8.93 0 01.85-.35L9 2a2 2 0 012-1.65h1.93a2 2 0 012 1.69l.19 1.12c.3.11.61.24.9.38l.98-.68a2 2 0 012.6.22l1.37 1.37a2 2 0 01.22 2.6l-.68 1a8.76 8.76 0 01.35.84L22 9a2 2 0 011.68 2v2A2 2 0 0122 15l-1.15.19a6.91 6.91 0 01-.35.85l.68.95a2 2 0 01-.22 2.6l-1.37 1.37a2 2 0 01-2.6.22l-.99-.72a7.81 7.81 0 01-.84.35L15 22a2 2 0 01-2 1.65zm-5-4.72l.36.19a7.54 7.54 0 001.34.55l.39.13.32 1.94a.7.7 0 00.69.58H13a.69.69 0 00.68-.58L14 19.8l.39-.13a8 8 0 001.38-.57l.37-.19 1.6 1.15a.7.7 0 00.89-.08L20 18.61a.7.7 0 00.08-.89l-1.15-1.61.19-.36a8.05 8.05 0 00.57-1.38l.12-.39 1.95-.33a.69.69 0 00.58-.68V11a.68.68 0 00-.57-.68L19.8 10l-.12-.39a7.28 7.28 0 00-.57-1.38l-.19-.37 1.15-1.6a.7.7 0 00-.07-.87L18.62 4a.69.69 0 00-.88-.08l-1.62 1.17-.36-.19a7.58 7.58 0 00-1.43-.59l-.4-.12-.32-1.93a.7.7 0 00-.69-.58H11a.68.68 0 00-.68.58l-.34 2-.39.12a8.66 8.66 0 00-1.37.58l-.37.2L6.24 4a.7.7 0 00-.88 0L4 5.41a.7.7 0 00-.07.9l1.13 1.62-.19.36a7.53 7.53 0 00-.56 1.38l-.12.4-1.93.33a.68.68 0 00-.58.68V13a.7.7 0 00.58.69l2 .33.12.39a8.66 8.66 0 00.58 1.37l.2.37L4 17.76a.68.68 0 00.08.88L5.42 20a.69.69 0 00.88.08z" }),
            React.createElement("path", { d: "M12 17.06A4.9 4.9 0 016.94 12 4.9 4.9 0 0112 6.94 4.9 4.9 0 0117.06 12 4.9 4.9 0 0112 17.06zm0-8.79A3.56 3.56 0 008.27 12 3.56 3.56 0 0012 15.73 3.56 3.56 0 0015.73 12 3.56 3.56 0 0012 8.27z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.45 11.79h-.9a1.29 1.29 0 01-1.28-1.09l-.06-.33-.29.21a1.33 1.33 0 01-1.67-.14l-.65-.65a1.29 1.29 0 01-.14-1.67l.2-.27-.35-.06a1.29 1.29 0 01-1.1-1.32v-.9A1.31 1.31 0 011.3 4.29h.32L1.41 4a1.29 1.29 0 01.14-1.67l.64-.64a1.33 1.33 0 011.68-.14l.26.2.06-.34A1.29 1.29 0 015.53.21h.9A1.29 1.29 0 017.71 1.3l.06.32h.06l.28-.2a1.33 1.33 0 011.67.14l.64.64a1.3 1.3 0 01.14 1.67l-.2.27.34.06a1.29 1.29 0 011.08 1.29v.89a1.29 1.29 0 01-1.08 1.35l-.33.06.2.28a1.29 1.29 0 01-.14 1.67l-.64.64a1.32 1.32 0 01-1.67.14l-.27-.2-.06.33a1.29 1.29 0 01-1.34 1.14zm-2.38-3l.37.21a3.46 3.46 0 00.56.24l.39.12.19 1.12h.87l.16-1.1.39-.14A3 3 0 007.58 9L8 8.81l.92.66.6-.64L8.81 8 9 7.58A3.56 3.56 0 009.24 7l.12-.39 1.12-.19v-.85l-1.1-.18L9.24 5A3 3 0 009 4.42l-.19-.37.66-.92-.63-.6-.84.66L7.59 3A4.6 4.6 0 007 2.75l-.39-.11-.21-1.12h-.87l-.16 1.13-.37.12a3 3 0 00-.6.23l-.4.21-.93-.67-.6.64.67.89-.14.37a4.39 4.39 0 00-.25.56l-.12.39-1.11.21v.87l1.11.16.14.37a3 3 0 00.23.6l.2.37-.66.92.63.6z" }),
            React.createElement("path", { d: "M6 8.38A2.32 2.32 0 013.62 6 2.32 2.32 0 016 3.62 2.32 2.32 0 018.38 6 2.32 2.32 0 016 8.38zM6 5a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1z" }));
    }
    return null;
});
