"use strict";
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
__export(require("./input"));
__export(require("./animation"));
__export(require("./browser"));
var breakpoint_1 = require("./breakpoint");
exports.Breakpoints = breakpoint_1.Breakpoints;
exports.compareBreakpoints = breakpoint_1.compareBreakpoints;
const basis = 24;
function assertCountGridColumn(count) {
    if (process.env.NODE_ENV !== 'production') {
        if (Math.floor(count) !== count) {
            throw new Error('count has to be an integer.');
        }
        if (count < 0 || count > basis) {
            throw new Error(`count has to be between 0 and ${basis}.`);
        }
    }
}
function getGridColumn(count) {
    assertCountGridColumn(count);
    // Round down to two decimal places
    // two decimal places because Edge has issues with too many decimal places
    // round down, so (positive) rounding errors do not add up
    return `${Math.floor((100 / basis) * count * 100) / 100}vw`;
}
exports.getGridColumn = getGridColumn;
function getColumnsWidthForBreakpoint(columns, breakpoint) {
    assertCountGridColumn(columns);
    // Round down to two decimal places
    // two decimal places because Edge has issues with too many decimal places
    // round down, so (positive) rounding errors do not add up
    return `${Math.floor((breakpoint / basis) * columns * 100) / 100}px`;
}
exports.getColumnsWidthForBreakpoint = getColumnsWidthForBreakpoint;
const hexColorRegexp = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
/**
 * Converts a hex color to a full rgba() string
 *
 * @param color 3 or 6 digit hex code allowed
 */
function hexColorToRgba(color, alphaOrOptions) {
    const options = Object.assign({}, (typeof alphaOrOptions === 'number'
        ? {
            alpha: alphaOrOptions,
            isNumber: true
        }
        : Object.assign(Object.assign({}, alphaOrOptions), { isNumber: false })));
    if (typeof options.alpha !== 'number' ||
        options.alpha < 0 ||
        options.alpha > 1) {
        options.alphaFallback = 0;
        options.alpha = 1;
    }
    else {
        options.alphaFallback = options.isNumber ? 0 : options.alpha;
    }
    if (typeof options.rgbFallback !== 'object') {
        options.rgbFallback = { r: 0, g: 0, b: 0 };
    }
    if (typeof options.rgbaFallback !== 'string') {
        options.rgbaFallback = `rgba(${options.rgbFallback.r},${options.rgbFallback.g},${options.rgbFallback.b},${options.alphaFallback})`;
    }
    const hexCodeMatches = color.match(hexColorRegexp);
    if (!hexCodeMatches) {
        return options.rgbaFallback;
    }
    const hexCodeStrippedInput = hexCodeMatches[1].length === 6
        ? hexCodeMatches[1]
        : hexCodeMatches[1]
            .split('')
            .map(character => character.repeat(2))
            .join('');
    const rgbValue = hexCodeStrippedInput
        // split string into character-pairs
        .split(/(?=(?:..)*$)/)
        // convert character-pairs from HEX to INT
        .map(pair => parseInt(pair, 16))
        .join(',');
    return `rgba(${rgbValue},${options.alpha})`;
}
exports.hexColorToRgba = hexColorToRgba;
function createA11yOutlineStyles(props) {
    return `
			outline: 1px solid ${props.theme.interaction.recipe100.active};
			outline-offset: ${props.theme.size.static100};
		`;
}
exports.createA11yOutlineStyles = createA11yOutlineStyles;
