"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.OnCallService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7 17.88a1 1 0 11-1 1 1 1 0 011-1zm-2.65-1.49a3.51 3.51 0 000 5l.2.19v.05a3.5 3.5 0 005-.05l5.2-5.2a8.43 8.43 0 002 .25 7.23 7.23 0 006.71-4 6.86 6.86 0 00.5-1.33H19l-1.89-2.21.55-.64L19 6.92h5a6.86 6.86 0 00-.5-1.33 7.23 7.23 0 00-6.71-4v1.3A6 6 0 0122 5.59h-3.64L16.72 7.5l-.8.94-.06.09a1 1 0 00.06 1.21l1.63 1.91.81.94H22a6 6 0 01-5.21 2.7 7.28 7.28 0 01-.93-.07 6.46 6.46 0 01-1.5-.37 5.75 5.75 0 01-3.18-3H8.92l-4.53 4.5zm.35 2.48a2.16 2.16 0 01.63-1.54l4.75-4.74a7.05 7.05 0 003.29 3.25L8.61 20.6a2.17 2.17 0 01-3.07 0l-.2-.19a2.16 2.16 0 01-.64-1.54zm6.1-17.54a1 1 0 00-.74.34L9 2.72a1.64 1.64 0 00.19 1.45l.51.89-.7.72L6.23 8.5l-.81.81-.92-.68a4.73 4.73 0 00-.69-.42 1.29 1.29 0 00-.24 0 1.07 1.07 0 00-.72.28L1.68 9.56a1 1 0 00-.31.74 1 1 0 00.31.75l.85.85a2 2 0 00.78.5h.11a1.58 1.58 0 00.38 0 2.31 2.31 0 001.49-.73L9.1 8.05l3-2.94c.57-.57.77-.88.77-1.21a2 2 0 00-.57-1.41l-.87-.86a1 1 0 00-.4-.22 1 1 0 00-.29 0M10.8 0a2.36 2.36 0 011.62.64l.89.88a3.27 3.27 0 01.95 2.35A3.07 3.07 0 0113.09 6L10 9l-3.82 3.75a3.57 3.57 0 01-2.42 1.11 2.64 2.64 0 01-.74-.1l-.2-.07a3.26 3.26 0 01-1.26-.8L.69 12A2.41 2.41 0 010 10.33a2.36 2.36 0 01.71-1.68l.06-.06L2 7.48a2.4 2.4 0 011.62-.63 2.35 2.35 0 01.64.09 4.87 4.87 0 011.08.62L8 4.84a2.75 2.75 0 010-3l1-1A2.33 2.33 0 0110.71 0z" }));
    }
    if (variant === "big") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "40", height: "40", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M7 17.88a1 1 0 11-1 1 1 1 0 011-1zm-2.65-1.49a3.51 3.51 0 000 5l.2.19v.05a3.5 3.5 0 005-.05l5.2-5.2a8.43 8.43 0 002 .25 7.23 7.23 0 006.71-4 6.86 6.86 0 00.5-1.33H19l-1.89-2.21.55-.64L19 6.92h5a6.86 6.86 0 00-.5-1.33 7.23 7.23 0 00-6.71-4v1.3A6 6 0 0122 5.59h-3.64L16.72 7.5l-.8.94-.06.09a1 1 0 00.06 1.21l1.63 1.91.81.94H22a6 6 0 01-5.21 2.7 7.28 7.28 0 01-.93-.07 6.46 6.46 0 01-1.5-.37 5.75 5.75 0 01-3.18-3H8.92l-4.53 4.5zm.35 2.48a2.16 2.16 0 01.63-1.54l4.75-4.74a7.05 7.05 0 003.29 3.25L8.61 20.6a2.17 2.17 0 01-3.07 0l-.2-.19a2.16 2.16 0 01-.64-1.54zm6.1-17.54a1 1 0 00-.74.34L9 2.72a1.64 1.64 0 00.19 1.45l.51.89-.7.72L6.23 8.5l-.81.81-.92-.68a4.73 4.73 0 00-.69-.42 1.29 1.29 0 00-.24 0 1.07 1.07 0 00-.72.28L1.68 9.56a1 1 0 00-.31.74 1 1 0 00.31.75l.85.85a2 2 0 00.78.5h.11a1.58 1.58 0 00.38 0 2.31 2.31 0 001.49-.73L9.1 8.05l3-2.94c.57-.57.77-.88.77-1.21a2 2 0 00-.57-1.41l-.87-.86a1 1 0 00-.4-.22 1 1 0 00-.29 0M10.8 0a2.36 2.36 0 011.62.64l.89.88a3.27 3.27 0 01.95 2.35A3.07 3.07 0 0113.09 6L10 9l-3.82 3.75a3.57 3.57 0 01-2.42 1.11 2.64 2.64 0 01-.74-.1l-.2-.07a3.26 3.26 0 01-1.26-.8L.69 12A2.41 2.41 0 010 10.33a2.36 2.36 0 01.71-1.68l.06-.06L2 7.48a2.4 2.4 0 011.62-.63 2.35 2.35 0 01.64.09 4.87 4.87 0 011.08.62L8 4.84a2.75 2.75 0 010-3l1-1A2.33 2.33 0 0110.71 0z" }));
    }
    return null;
});
