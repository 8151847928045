"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Unselected = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M20 3.33a.68.68 0 01.68.68V20a.68.68 0 01-.68.68H4a.68.68 0 01-.67-.68V4A.68.68 0 014 3.33zM20 2H4a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V4a2 2 0 00-2-2z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M9 2.33a.67.67 0 01.67.67v6a.67.67 0 01-.67.67H3A.67.67 0 012.33 9V3A.67.67 0 013 2.33zM9 1H3a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2V3a2 2 0 00-2-2z" }));
    }
    return null;
});
