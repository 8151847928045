"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Wheelbase = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.979 19.392H5.87v-1.521H4.54v4.123h1.33v-1.272h12.109V22h1.33v-4.124h-1.33zM23.682 9.59a4.515 4.515 0 00-2.296-3.588 6.263 6.263 0 01-1.287-.819l-2.961-2.832a3.102 3.102 0 00-2.237-.906L3.678 1.402h-.009a2.01 2.01 0 00-1.946 1.593c-.04.191-.958 4.706-1.05 5.73L.461 10.91a1.686 1.686 0 001.372 1.84l.459.116a2.986 2.986 0 005.95.348l7.46-.02a2.982 2.982 0 005.946-.25l.829-.224a1.784 1.784 0 001.304-1.872zm-7.463-6.278l2.584 2.472-2.696.07c-.896.022-1.8.03-2.703.03l-.306-3.116 1.8.007a1.768 1.768 0 011.32.537zm-12.55-.58h.003l8.09.03.305 3.108c-.672-.008-1.344-.013-2.012-.033L2.56 5.611c.18-.928.358-1.821.464-2.342a.673.673 0 01.645-.537zm1.617 11.781a1.662 1.662 0 111.664-1.662 1.665 1.665 0 01-1.664 1.662zm13.377 0a1.662 1.662 0 111.665-1.662 1.664 1.664 0 01-1.665 1.662zm3.468-3.076l-.736.2a2.982 2.982 0 00-5.545.227l-7.744.02a2.977 2.977 0 00-5.515-.314l-.443-.112c-.268-.063-.361-.122-.359-.475l.208-2.135c.028-.313.313-1.913.313-1.913 8.823.228 11.35.34 18.244.134a3.627 3.627 0 011.801 2.627l.101 1.26a.454.454 0 01-.325.481z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M8.4 8.24v.92H3.41v-.92H2.08v3.17h1.33v-.92H8.4v.92h1.33V8.24zM11.79 4.27a2.44 2.44 0 00-1.27-2A3.19 3.19 0 0110 2L8.6.64A1.81 1.81 0 007.31.11L2 .09a1.3 1.3 0 00-1.26 1C.71 1.35.3 3.37.26 3.86l-.1 1.05a1.14 1.14 0 00.89 1.21 1.75 1.75 0 003.4.24h3a1.76 1.76 0 003.41-.2h.16a1.19 1.19 0 00.87-1.25zm-1.29.53a1.74 1.74 0 00-3 .22H4.34a1.74 1.74 0 00-2.82-.42s.09-.86.12-1.05q4.23.19 8.46.06a1.17 1.17 0 01.37.76zM7.31 1.44a.45.45 0 01.37.16l1 .89-6.83-.09c0-.3.13-.75.17-1zM2.78 6.26a.43.43 0 01-.43-.43.43.43 0 01.86 0 .43.43 0 01-.43.43zm6.3 0a.43.43 0 01-.43-.43.43.43 0 01.86 0 .43.43 0 01-.43.43z" }));
    }
    return null;
});
