"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.NewCarUtilityVehicle = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23.771 17.55a4.448 4.448 0 00-2.092-3.5l-.852-.508a3.569 3.569 0 01-.718-.565L16.9 9.72a2.723 2.723 0 00-1.928-.831h-3.308v5.876H1.8v-1.314l8.79.035.005-1.33-10.127-.041v2.861a41.508 41.508 0 00-.188 1.592L.055 19.21a1.044 1.044 0 00.757 1.123l1.988.477a2.354 2.354 0 004.7.015l9.136-.029a2.354 2.354 0 004.7-.015h.164l1.5-.43A1.287 1.287 0 0023.88 19zm-7.816-6.9l2.823 2.867-5.762-.026.011-3.275h1.944a1.392 1.392 0 01.984.437zm-14.35 6.031c.014-.163.037-.371.062-.586h10.01l-.011 3.387-4.453.018a2.344 2.344 0 00-4.128.013l-1.684-.4zm3.547 4.989a1.023 1.023 0 01-1-.834c-.007-.038-.014-.077-.023-.114s-.017-.056-.017-.087a1.036 1.036 0 112.071 0 .424.424 0 01-.021.106 1.027 1.027 0 01-1.01.929zm13.832 0a1.022 1.022 0 01-1-.878l-.007-.032a1.063 1.063 0 01-.025-.125 1.036 1.036 0 012.071 0 .984.984 0 01-.03.151 1.024 1.024 0 01-1.009.884zm2.328-2.219h-.291a2.345 2.345 0 00-4.08.013L13 19.478l.016-4.654 7.425.033.561.334a2.865 2.865 0 01.943.944 44.01 44.01 0 00-2.638-.148l-.031 1.33c1.074.025 2.128.092 3.145.191.006.049.024.093.027.142l.105 1.446z" }));
    }
    return null;
});
