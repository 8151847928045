"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Locate = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M23 11.45h-2a9 9 0 00-8.3-8.31v-2h-1.37v2A9 9 0 003 11.45H1v1.33h2a9 9 0 008.3 8.3v2h1.34v-2a9 9 0 008.3-8.3h2zm-10.33 8.3v-2.64h-1.34v2.64a7.69 7.69 0 01-7-7H7v-1.3H4.36a7.69 7.69 0 017-7v2.66h1.34V4.47a7.69 7.69 0 017 7H17v1.33h2.64a7.69 7.69 0 01-6.97 6.95z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M12 5.34h-1.32a4.71 4.71 0 00-4-4V0H5.33v1.32a4.71 4.71 0 00-4 4H0v1.35h1.32a4.71 4.71 0 004 4V12h1.35v-1.32a4.71 4.71 0 004-4H12zm-5.33 4V8H5.33v1.35a3.42 3.42 0 01-2.68-2.68H4V5.34H2.65a3.42 3.42 0 012.68-2.69V4h1.34V2.65a3.42 3.42 0 012.68 2.69H8v1.33h1.35a3.42 3.42 0 01-2.68 2.68z" }));
    }
    return null;
});
