"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Mobile = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "12", cy: "18.99", r: "1.1" }),
            React.createElement("path", { d: "M16.3 2.35a.88.88 0 01.87.88v17.56a.87.87 0 01-.87.87H7.7a.87.87 0 01-.87-.87V3.23a.88.88 0 01.87-.88zm0-1.35H7.7a2.21 2.21 0 00-2.2 2.23v17.56A2.2 2.2 0 007.7 23h8.6a2.2 2.2 0 002.2-2.2V3.23A2.21 2.21 0 0016.3 1z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("circle", { cx: "6", cy: "8.8", r: ".79" }),
            React.createElement("path", { d: "M8.35 10.67H3.61a.29.29 0 01-.28-.29V1.64a.28.28 0 01.28-.28h4.77a.28.28 0 01.28.29v8.77a.28.28 0 01-.31.25zM8.63 0H3.37A1.36 1.36 0 002 1.36v9.28A1.36 1.36 0 003.37 12h5.26A1.37 1.37 0 0010 10.64V1.37A1.37 1.37 0 008.63 0z" }));
    }
    return null;
});
