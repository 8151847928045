"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Sound = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M17.46 3.83a5.37 5.37 0 00-4.1-2.62l-.78-.13V16a3.89 3.89 0 101.33 2.91V2.7a3.69 3.69 0 012.38 1.75c.38.72.57 2.4-.68 6.37l1.27.4c1.12-3.59 1.32-6.01.58-7.39zM10 21.47a2.58 2.58 0 112.58-2.58A2.59 2.59 0 0110 21.47z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M6.72.23L5.94.09v7.18A2.46 2.46 0 004.89 7a2.38 2.38 0 102.38 2.4V1.76c1.32.49.9 2.11.54 3.5l1.27.4C9.56 3.9 10.34.84 6.72.23zM4.89 10.45a1.05 1.05 0 111.05-1 1 1 0 01-1.05 1z" }));
    }
    return null;
});
