"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.SkipBackward = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M20.19 5.39v13.22a.49.49 0 01-.48.49.41.41 0 01-.19 0S8.14 12.62 7.93 12.48c-.42-.27-.41-.66 0-.93L19.51 5a.47.47 0 01.68.39zM18.9 3.76L7.3 10.4a1.82 1.82 0 000 3.26l11.6 6.58a1.82 1.82 0 002.62-1.63V5.39a1.82 1.82 0 00-2.62-1.63zM2.48 3.73h1.33v16.55H2.48z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10.6 2.47v7.07L4.44 6zM10.84 1a1.07 1.07 0 00-.48.12L3.47 5a1.09 1.09 0 000 2l6.89 3.94a1.07 1.07 0 00.48.12A1.09 1.09 0 0011.93 10V2a1.09 1.09 0 00-1.09-1zM.07 1.18H1.4v9.65H.07z" }));
    }
    return null;
});
