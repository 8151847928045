"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.NightService = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M4.4 1.5C2.6 3.9 3 7.4 5.5 9.2c1 .7 2.2 1.1 3.4 1.1.5 0 .9-.1 1.4-.2-1.8 2.4-5.3 3-7.7 1.1s-3-5.3-1.1-7.7c.6-.9 1.7-1.7 2.9-2z" }),
            React.createElement("path", { d: "M18.7 12.1L16.9 10l1.9-2.2h5c-.1-.5-.3-.9-.5-1.3-1.3-2.5-3.9-4.1-6.7-4-1 0-1.9.2-2.8.5.3.4.5.7.6 1.2.7-.3 1.4-.4 2.2-.4 2.1-.1 4.1 1 5.2 2.7h-3.7l-2.4 2.8c-.3.4-.3.9 0 1.3l2.4 2.8h3.6c-1.1 1.7-3.1 2.8-5.2 2.7-2.6.2-5-1.4-5.8-3.9-.6.3-1.3.4-2.1.4l-4.5 4.5c-1.4 1.4-1.4 3.6 0 5l.2.2c1.4 1.4 3.6 1.4 4.9.1l.1-.1 5.2-5.2c.7.2 1.3.2 2 .2 2.8.1 5.4-1.5 6.7-4 .2-.4.4-.9.5-1.3zM8.3 21.5c-.8.9-2.2.9-3.1 0l-.2-.2c-.9-.8-.9-2.2 0-3.1l4.7-4.7c.7 1.4 1.9 2.6 3.3 3.3z" }),
            React.createElement("path", { d: "M6.8 18.7c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.5-1-1-1zM10.9.6c-.1-.1-.1 0-.1 0l-.4.9H9.3s-.1 0-.1.1l.8.8v.1l-.3.9v.1h.1l1-.5h.1l.9.5h.1v-.1l-.3-.9v-.1l.8-.8v-.1h-1.1M13.3 7.5h1s.1 0 .1.1l-.7.7v.1l.3.9s0 .1-.1.1l-1-.4h-.1l-.9.5h-.1v-.1l.2-1v-.1l-.7-.7v-.1h1.1l.4-.9s0-.1.1-.1c0 0 .1 0 .1.1zM9.8 4.9H8.4s-.1 0-.1-.1l-.6-1.4c0-.1-.1-.1-.1-.1s-.1 0-.1.1l-.6 1.4-.1.1H5.3c-.1 0-.1 0-.1.1v.1l1.1 1.1v.1l-.4 1.4v.1H6l1.4-.7h.1l1.5.8h.2v-.1l-.5-1.5v-.1l1.2-1.1c.1-.1.1-.1-.1-.2.1 0 .1 0 0 0z" }));
    }
    return null;
});
