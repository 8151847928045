"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.WindscreenWash = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M22.782 10.49c-.997-1.453-3.855-4.537-10.117-4.75V4.013a.96.96 0 01.959-.96.947.947 0 01.96.944h1.33A2.288 2.288 0 0012 2.402 2.288 2.288 0 008.085 4h1.33a.95.95 0 01.96-.946.96.96 0 01.96.96V5.74c-6.28.205-9.128 3.299-10.12 4.754a1.027 1.027 0 00.13 1.307L11.27 21.7a1.032 1.032 0 001.457 0l9.927-9.9a1.027 1.027 0 00.127-1.31zm-10.79 5.333h.002a8.226 8.226 0 013.697.902l-3.565 3.556a.179.179 0 01-.253 0l-3.567-3.557a8.162 8.162 0 013.685-.901zm4.772-.026a9.576 9.576 0 00-4.1-1.265v-2.525h-1.33v2.525a9.504 9.504 0 00-4.099 1.264l-4.774-4.76c.956-1.295 3.492-3.778 8.873-3.968v2.944h1.33V7.069c5.369.196 7.912 2.674 8.874 3.967z" }));
    }
    return null;
});
