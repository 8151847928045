"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
exports.Radio = React.forwardRef(function (props, forwardRef) {
    var variant = props.variant || "default";
    if (variant === "default") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 24 24", width: "24", height: "24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M20 8H5.39a3.25 3.25 0 01.11-1 1.84 1.84 0 011.39-1.3c1.61-.36 13.85-2.18 14-2.19l-.2-1.32C20.17 2.26 8.25 4 6.6 4.4a3.19 3.19 0 00-2.38 2.27A4.8 4.8 0 004.06 8H4a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V10a2 2 0 00-2-2zm.67 12a.67.67 0 01-.67.66H4a.67.67 0 01-.68-.66V10A.67.67 0 014 9.33h16a.67.67 0 01.67.67z" }),
            React.createElement("path", { d: "M5.87 14.66h12.27v1.33H5.87z" }));
    }
    if (variant === "small") {
        return React.createElement("svg", { className: props.className, title: props.title, "aria-hidden": props.ariaHidden, ref: forwardRef, viewBox: "0 0 12 12", width: "12", height: "12", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", role: "img" },
            React.createElement("path", { d: "M10 3.72H2.72a1.62 1.62 0 010-.4.68.68 0 01.53-.51C4 2.65 8.54 2 10.85 1.62L10.66.3c-.28 0-6.75 1-7.66 1.21A2 2 0 001.49 3a2.8 2.8 0 00-.09.88 1.68 1.68 0 00-1 1.55V10A1.66 1.66 0 002 11.7h8a1.66 1.66 0 001.65-1.7V5.38A1.66 1.66 0 0010 3.72zm.32 6.28a.34.34 0 01-.33.34H2a.34.34 0 01-.32-.34V5.38A.33.33 0 012 5.05h8a.33.33 0 01.33.33z" }),
            React.createElement("path", { d: "M2.67 7.04h6.65v1.33H2.67z" }));
    }
    return null;
});
